import OrderList from '../views/order/OrderList.vue'
import OrderDetail from '../views/order/OrderDetail.vue'
import OrderPrint from '../views/order/OrderPrint'
import OrderEdit from '@/views/order/OrderEdit'

export default [
  {
    path: '/orders',
    name: 'OrderList',
    component: OrderList
  },
  {
    path: '/orders/:orderId',
    name: 'OrderDetail',
    component: OrderDetail
  },
  {
    path: '/orders/:orderId/edit',
    name: 'OrderEdit',
    component: OrderEdit
  },
  {
    path: '/orders/:orderId/print',
    name: 'OrderPrint',
    component: OrderPrint,
    meta: {
      layout: 'LayoutEmpty'
    }
  }
]
