const state = () => ({
  invoices: [],
  pagination: {},
  invoice: {}
})

const getters = {
  getInvoice (state) {
    return state.invoice
  },
  getInvoices (state) {
    return state.invoices
  },
  getInvoicePagination (state) {
    return state.pagination
  }
}

const actions = {
  setInvoice (context, payload) {
    context.commit('SET_INVOICE', payload)
  },
  setInvoices (context, payload) {
    context.commit('SET_INVOICES', payload)
  },
  appendInvoices (context, payload) {
    context.commit('APPEND_INVOICES', payload)
  }
}

const mutations = {
  SET_INVOICE (state, payload) {
    state.invoice = payload
  },
  SET_INVOICES (state, payload) {
    const { data, ...pagination } = payload
    state.invoices = data || []
    state.pagination = pagination || {}
  },
  APPEND_INVOICES (state, payload) {
    const { data, ...pagination } = payload
    state.invoices = data ? [...state.invoices, ...data] : []
    state.pagination = pagination || {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
