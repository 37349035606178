<template>
  <div class="reportList ml-3 mr-8">
    <div class="container-fluid">
      <!-- Report Header -->
      <div class="reportList__header p-3 pt-md-6">
        <!-- Header: Title -->
        <h1 class="reportList__title">
          Reports
        </h1>
      </div>
      <ReportItem
        v-for="report in reports"
        :key="report.type"
        :report="report"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportItem from './ReportItem'
import { formatter } from '../../utils'

export default {
  components: {
    ReportItem
  },
  data () {
    return {
      reports: [
        {
          title: 'Quote Report',
          type: 'quotes',
          description: 'Use this Report to understand what is being Quoted but hasn\'t converted to an Order'
        },
        {
          title: 'Order Report',
          type: 'orders',
          description: 'Use this Report to compute sales commissions and support accounting needs, etc.'
        },
        {
          title: 'Product Sales Report',
          type: 'products',
          description: 'Use this Report to review what products are selling, and to assist with inventory reporting.'
        },
        {
          title: 'Customer Report',
          type: 'customers',
          description: 'Use this Report to receive all customer details, and it may be used for a mailing list etc.'
        },
        {
          title: 'Partner Report',
          type: 'partners',
          description: 'Use this report to determine how many Primary Customers the partner is related to or use to develop a partner mailing list.'
        }
      ],
      formatter
    }
  },
  computed: {
    ...mapGetters({
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
