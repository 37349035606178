const state = () => ({
  alert: {
    show: false,
    title: '',
    message: '',
    type: 'danger' // success | warning | danger | primary
  }
})

const getters = {
  getAlert (state) {
    return state.alert
  }
}

const actions = {
  setAlert (context, payload) {
    context.commit('SET_ALERT', payload)
  }
}

const mutations = {
  SET_ALERT (state, payload) {
    state.alert = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
