import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
// import Home from '../views/Home.vue'
import Calendar from '../views/Calendar.vue'
import Auth from './auth'
import Customer from './customers'
import Quotes from './quotes'
import Order from './orders'
import Invoice from './invoices'
import Sample from './samples'
import Installer from './installers'
import Supplier from './suppliers'
import Flooring from './flooring'
import LearningCenter from '../views/LearningCenter'
import PageError from '../components/partials/PageError.vue'
import Product from './products'
import Partner from './partners'
import Report from './reports'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    redirect: '/customers'
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // Auth
  ...Auth,
  // Customer
  ...Customer,
  // Quote
  ...Quotes,
  // Order
  ...Order,
  ...Product,
  // Invoices
  ...Invoice,
  // Samples
  ...Sample,
  // Installers
  ...Installer,
  // Suppliers
  ...Supplier,
  // Learning Center
  ...Flooring,
  // Partner,
  ...Partner,
  // Report,
  ...Report,
  // Learning Center
  {
    path: '/learning-center',
    name: 'LearningCenter',
    component: LearningCenter
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '*',
    name: 'Page Error',
    component: PageError
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let authenticated

  if (window.localStorage) {
    authenticated = window.localStorage.getItem('accessToken')
  }

  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some(record => record.meta.public)

  if (!isPublic && !authenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  if (authenticated && onlyLoggedOut) {
    return next('/')
  }
  next()
})

export default router
