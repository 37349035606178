<template>
  <div class="customer">
    <HeaderMenu />
    <div class="row no-gutters">
      <div class="col-lg-12" :key="key">
        <form
          class="row no-gutters p-3 pt-lg-5 pl-lg-5"
          autocomplete="on"
        >
          <div class="col-lg-8">
            <!-- 1.Primary Customer -->
            <h2 class="mb-5">
              Role Permissions
            </h2>
            <div class="row no-gutters mt-5 mb-5" v-for="role in roles" :key="role">
              <div class="col-md-12">
                <label for="tags-basic">
                  {{ role && role.toUpperCase()  }} <span class="ml-3 edit-icon" @click="editPermissionsModal(role)" v-if="role !== 'admin' "><IconEdit /></span>
                </label>
                <b-form-tags v-model="form[role]" no-outer-focus class="mb-2 tags-list">
                  <template v-slot="{ tags }">
                    <ul
                      id="my-custom-tags-list"
                      class="list-unstyled d-inline-flex flex-wrap mb-0"
                      aria-live="polite"
                      aria-atomic="false"
                      aria-relevant="additions removals"
                    >
                      <div
                        v-for="tag in tags"
                        :key="tag"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        tag="li"
                        class="mt-1 mr-1 tag-btn selected"
                        body-class="py-1 pr-2 text-nowrap"
                      >
                        {{ tag }}
                      </div>
                    </ul>
                  </template>
                </b-form-tags>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="col-lg-5">
      </div>
    </div>

    <b-modal
      size="md"
      centered
      id="update-permissions"
      :hide-footer="true"
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h2>Edit "{{ editRole }}" permissions</h2>
        <div @click="close()">
          <IconCloseBig />
        </div>
      </template>
      <!-- Name And Last Name -->
        <div class="mt-2 tag-list">
          <div
            v-for="tag in allPermissions"
            :key="tag"
            :class="{'tag-btn': true, 'pointer': true, 'selected': editPermissions && tag && editPermissions.includes(tag)}"
            @click="toggleTag(tag)"
          >
            {{ tag }}
          </div>
        </div>
        <div class="mt-2">
          <ButtonPrimary class="w-auto" @click="savePermissions()">
            Save
          </ButtonPrimary>
          <ButtonSecondary class="ml-4 w-auto" @click="cancelPermissionsModal()">
            Cancel
          </ButtonSecondary>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import HeaderMenu from '../../components/flooring/HeaderMenu'
import { mapGetters } from 'vuex'
import IconCloseBig from '@/components/icons/menu/IconCloseBig'
import IconEdit from '@/components/icons/edit/IconEdit'
import ButtonPrimary from '../../components/buttons/ButtonPrimary'
import ButtonSecondary from '../../components/buttons/ButtonSecondary'

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
    IconEdit,
    HeaderMenu,
    IconCloseBig
  },
  directives: { mask },
  data () {
    return {
      form: {},
      submitStatus: false,
      editRole: null,
      editPermissions: [],
      key: 0
    }
  },
  computed: {
    ...mapGetters({
      roles: 'getRoles',
      permissions: 'getPermissions',
      allPermissions: 'getAllPermissions'
    })
  },
  created () {
    this.$store.dispatch('fetchPermissions')
  },
  methods: {
    getPermissions (role) {
      return this.permission.filter(item => item.role === role)
    },
    editPermissionsModal (role) {
      this.editRole = role
      this.editPermissions = this.form[role] || []
      this.$root.$emit('bv::show::modal', 'update-permissions', '#focusThisOnClose')
    },
    cancelPermissionsModal () {
      this.editRole = null
      this.editPermissions = []
      this.$root.$emit('bv::hide::modal', 'update-permissions', '#focusThisOnClose')
    },
    savePermissions () {
      this.form[this.editRole] = this.editPermissions
      this.$store.dispatch('savePermissions', { role: this.editRole, permissions: this.editPermissions })
      this.cancelPermissionsModal()
      this.key++
    },
    toggleTag (permission) {
      if (this.editPermissions.includes(permission)) {
        this.editPermissions = this.editPermissions.filter(item => item !== permission)
      } else {
        this.editPermissions.push(permission)
      }
    }
  },
  watch: {
    permissions (data) {
      if (data) {
        data.forEach(item => {
          if (!this.form[item.role]) {
            this.form[item.role] = []
          }
          this.form[item.role].push(item.permission)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.tags-list {
  border: none;
  padding: 0 0 0px;
}

.tag-empty {
  width: fit-content;
  padding: 0 0 0 10px;
  border: 1px solid $green;
  border-radius: 20px;
  font-weight: normal;

  & .btn {
    height: min-content;
  }
}
.tag-selected {
  width: fit-content;
  padding: 0 0 0 10px;
  border: 1px solid $green;
  border-radius: 20px;
  font-weight: normal;
  color: $white;
  background: $green;

  & .btn {
    height: min-content;
    /deep/ svg {
      cursor: pointer;

      * {
        fill: $yellow;
      }
    }
  }
}
.edit-icon {
  cursor: pointer;
  color: $green;

  /deep/ svg {
    cursor: pointer;

    * {
      fill: $green;
    }
  }
}
.tag-list {
  display: flex;
  flex-wrap: wrap;
}
.tag-btn {
  width: fit-content;
  padding: 5px 10px;
  margin: 0 10px 10px 0;
  border: 1px solid $green;
  border-radius: 20px;
  font-weight: normal;

  &.selected {
    background: $green;
    color: $white;
  }
}
.pointer {
  cursor: pointer;
}
</style>
