<template>
  <div class="invoiceList">
    <div class="container-fluid">
      <!-- Invoice List: Header -->
      <div class="invoiceList__header py-5 pt-md-6 pr-md-6 pl-md-5 row">
        <div class="col-md-7 d-flex flex-column mb-4">
          <h1 class="text-dark">
            Invoices
          </h1>
        </div>
        <div class="col-md-5">
          <div class="search-block">
            <b-form-input
              v-model="search"
              @keyup.enter="handleSearch"
              type="text"
              placeholder="Search Invoices"
            />
            <span @click="handleSearch">
              <IconSearch />
            </span>
          </div>
        </div>
      </div>
      <!-- Invoice List: Table -->
      <div class="row mt-2 mb-5 mr-1 mr-md-6 overflow-auto">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                Invoice Number
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  Customer
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'customer.firstName'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'customer.firstName'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                Address
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  Manufacturer
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'quote.manufacturer'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'quote.manufacturer'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  Installation Date
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'order.installedAt'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'order.installedAt'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  Pick-up Status
                  <span class="d-flex flex-column">
                    <IconCaretUp />
                    <IconCaretDown />
                  </span>
                </div>
              </th>
              <th scope="col">
                Due
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invoices" :key="index">
              <td scope="row">
                <router-link
                  :to="{
                    name: 'InvoiceDetail',
                    params: { invoiceId: item.id }
                  }"
                  class="text-dark"
                >
                  {{ item.order && item.order.orderNumber }}
                </router-link>
              </td>
              <td scope="row">
                <router-link
                  :to="{
                    name: 'CustomerDetail',
                    params: { customerId: item.quote.customer && item.quote.customer.id }
                  }"
                  class="text-dark"
                >
                  {{ item.quote.customer && getCustomerFullName(item.quote.customer) }}
                </router-link>
              </td>
              <td>
                <p>{{ item.quote.customer && getCustomerAddress(item.quote.customer) }}</p>
              </td>
              <td>
                <p>{{ item.quote.manufacturer }}</p>
              </td>
              <td>
                <p>{{ item.order && item.order.installedAt && formatDate(item.order.installedAt) }}</p>
              </td>
              <td>
                <p>{{ getStatusTitle(item.status) }}</p>
              </td>
              <td>
                <p>{{ item.order.due }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="row text-center"
        v-if="invoices && pagination.totalCount > invoices.length"
      >
        <div class="col-12 pb-4">
          <div @click="loadMore()">
            <ButtonSecondary class="ml-3">Load More</ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import IconSearch from '@/components/icons/edit/IconSearch'
import IconCaretUp from '@/components/icons/arrow/IconCaretUp'
import IconCaretDown from '@/components/icons/arrow/IconCaretDown'
import ButtonSecondary from '@/components/buttons/ButtonSecondary.vue'
import { mapGetters } from 'vuex'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import dayjs from 'dayjs'
import { INVOICE_STATUSES } from '../../constants/invoice'
import { getHeaders } from '../../utils'

export default {
  components: { IconSearch, IconCaretUp, IconCaretDown, ButtonSecondary },
  data () {
    return {
      page: 1,
      pageSize: 10,
      search: '',
      searchedInvoice: null,
      searchStatus: null,
      name: 'quote.quoteNumber',
      direction: 'ASC',
      totalCount: 0,
      statuses: INVOICE_STATUSES
    }
  },
  computed: {
    ...mapGetters({
      invoices: 'getInvoices',
      pagination: 'getInvoicePagination'
    })
  },
  created () {
    EventBus.$on('search', payload => {
      this.page = 1
      this.search = payload
      this.getInvoices()
    })

    this.getInvoices()
  },
  methods: {
    getInvoices (append = false) {
      const params = {
        name: this.name,
        dir: this.direction,
        page: this.page,
        limit: this.pageSize,
        q: this.search
      }
      const queryParams = new URLSearchParams(params)
      const url = `${BACKEND_API_URL}/invoices${
        queryParams ? '?' + queryParams : ''
      }`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch(
            append ? 'appendInvoices' : 'setInvoices',
            res.data
          )
          this.totalCount = res.totalCount
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    getCustomerFullName (customer) {
      let fullName = ''

      if (customer) {
        fullName = `${customer.firstName} ${customer.lastName}`
      }

      return fullName
    },
    getCustomerAddress (customer) {
      let address = ''

      if (customer && customer.billingAddress) {
        address = `${customer.billingAddress.street} ${customer.billingAddress.city}, ${customer.billingAddress.stateOrProvince}, ${customer.billingAddress.postal}`
      }

      return address
    },
    getCustomerPhone (customer) {
      let phone = ''

      if (customer) {
        phone = customer.mobilePhone || customer.homePhone || ''
      }

      return phone
    },
    getManufacturerTitle (name) {
      const manufacturer = this.manufacturers.find(item => item.name === name)

      return manufacturer ? manufacturer.title : ''
    },
    formatDate (date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    handleSearch () {
      this.page = 1
      this.getInvoices()
    },
    loadMore () {
      this.page++
      this.getInvoices(true)
    },
    getStatusName (name) {
      const status = this.statuses.find(item => name ? item.name === name : item.name === 'empty')
      return status ? status.name : 'empty'
    },
    getStatusTitle (name) {
      const status = this.statuses.find(item => name ? item.name === name : item.name === 'empty')
      return status ? status.title : ''
    }
  },
  watch: {
    name: function (val) {
      this.getInvoices()
    },
    direction: function (val) {
      this.getInvoices()
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiceList {
  &__search {
    /deep/ .inputText__input {
      margin-top: 0;
    }

    svg {
      font-size: 24px;

      /deep/ * {
        fill: $orange;
      }
    }
  }

  .table {
    thead {
      background-color: $green;
      color: $white;
      max-height: 50px !important;
    }

    td[scope='row'] {
      font-size: 13px;
      line-height: 13px;
      a {
        color: $green !important;
        font-weight: bold;
        font-size: 13px;
        font-family: Raleway;
      }
    }

    th[scope='col'] {
      padding: 0 24px;
      min-height: 50px !important;
      height: 50px;
    }

    tr td {
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px !important;
      min-height: 50px !important;
      height: 50px;
    }
  }

  .sort-arrow {
    line-height: 10px;
  }

  .search-block {
    position: relative;

    @include mq {
      padding-left: 30px;
    }

    input {
      border-radius: 10px;
      padding: 13px 8px 13px 16px;
      border: 1px solid #00818f;
      height: 50px;
    }

    span {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 15px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
      /deep/ svg * {
        fill: $red;
      }
    }
  }
  a {
    color: $green !important;
    font-weight: bold;
    font-size: 13px;
    font-family: Raleway;
  }
}
</style>
