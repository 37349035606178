<template>
  <div>
    <b-img v-if="store" :src="store.logo" fluid alt="logo"></b-img>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => {
    return {
    }
  },
  computed: {
    ...mapGetters({
      store: 'getStore'
    })
  }
}
</script>
