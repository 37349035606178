<template>
  <div>
    <table width="100%" class="print">
      <!-- Print Top -->
      <tr>
        <td>
          <table width="100%">
            <tr>
              <td style="padding: 20px">
                <table>
                  <tr>
                    <td style="padding: 10px; vertical-align: top;">
                      <LogoFlooringAndHome width="92" height="62" />
                    </td>
                    <td style="padding: 0px 10px; vertical-align: top;">
                      <p>
                        143 Bermondsey Rd,<br />
                        Toronto, Ontario <br />
                        M4A 1X3 <br />
                        E: info@flooringandhome.com <br />
                        P: 416.755.6555
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
              <td width="40%" class="print__customer">
                <table width="100%">
                  <tr>
                    <td >
                      <!-- 1.Customer Title -->
                      <h3>
                        Invoice prepared for
                      </h3>
                      <table width="100%">
                        <tr class="print__info">
                          <!-- 2.Customer Info -->
                          <td width="50%" valign="top">
                            <h4>
                              {{ customer && customer.firstName }} {{ customer && customer.lastName }}
                            </h4>
                            <table width="100%">
                              <tr class="">
                                <td>
                                  <div class="font-weight-bold pr-1">H:</div>
                                </td>
                                <td>
                                  <div class="mr-4">
                                    {{ customer && customer.homePhone }}
                                  </div>
                                </td>
                              </tr>
                              <tr class="">
                                <td>
                                  <div class="font-weight-bold pr-1">M:</div>
                                </td>
                                <td>
                                  <div>
                                    {{ customer && customer.mobilePhone }}
                                  </div>
                                </td>
                              </tr>
                              <tr class="">
                                <td>
                                  <div class="font-weight-bold pr-1">E:</div>
                                </td>
                                <td>
                                  <div>
                                    {{ customer && customer.email }}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <!-- 3.Customer Shipping Address -->
                          <td class="print__address" width="50%" valign="top">
                            <h4>Shipping address</h4>
                            <div v-if="customer && customer.shippingAddress">
                              {{ customer.shippingAddress.street }}
                            </div>
                            <div v-if="customer && customer.shippingAddress">
                              {{ customer.shippingAddress.city }}, {{ customer.shippingAddress.stateOrProvince }}
                            </div>
                            <div v-if="customer && customer.shippingAddress">
                              {{ customer.shippingAddress.postal }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- 4.Customer Profile -->
                      <table class="print__profile mt-4">
                        <h4>
                          Account manager
                        </h4>
                        <table width="100%" v-if="customer && customer.accountManager">
                        <tr class="">
                          <td style="width: 45px;" v-if="avatar">
                            <div v-if="avatar"
                                 :style="{width: '40px', height: '40px', background: 'url('+ avatar +')', backgroundSize: 'cover', borderRadius: '20px'}" />
                          </td>
                          <td>
                            {{ customer.accountManager.firstName }} {{ customer.accountManager.lastName }}
                          </td>
                        </tr>
                      </table>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table width="100%" class="print">
      <tr>
        <td class="p-20">
          <table class="print__top " width="100%">
            <!-- Print Table -->
            <tr class="print__table ">
              <td width="50%">
                  <h4 class="print--green font-weight-bold">
                    Invoice #: {{ invoice && invoice.order && invoice.order.orderNumber }}
                  </h4>
              </td>
              <td align="right">
                <div class="print--gray">Installation date: {{ invoice && invoice.order && invoice.order.installedAt && formatDate(invoice.order.installedAt, 'MMMM DD, YYYY')}}</div>
              </td>
            </tr>
          </table>
          <!-- Print Table -->
          <table class="print__table print__top" width="100%" style="border-collapse: collapse;">
            <!-- 1.1 Header -->
            <tr class="print__borderBottom pb-12">
              <td width="15%">
                <h4 class="print--blue">
                  Product
                </h4>
              </td>
              <td width="40%">
                <h4 class="print--blue">
                  Description
                </h4>
              </td>
              <td width="15%">
                <h4 class="print--blue">
                  Cost
                </h4>
              </td>
              <td width="15%">
                <h4 class="print--blue">
                  SF
                </h4>
              </td>
              <td width="15%" align="right">
                <h4 class="print--blue">
                  Sub Total
                </h4>
              </td>
            </tr>
            <!-- 1.2 Body | Rows -->
            <!-- Notes -->
            <tr class="print__borderBottom pt-4 pb-2" v-for="(item, index) in productList" :key="index">
              <td>
                <p class="print--gray">
                  {{ item.manufacturer.title }}
                </p>
              </td>
              <td>
                <p class="print--gray">
                  {{ item.type.flooring }},
                  {{ item.type.species }},
                  {{ item.type.lookGrade }},
                  {{ item.type.platform }},
                  {{ item.type.collection }},
                  {{ item.type.gloss }},
                  {{ item.type.width }},
                  {{ item.type.finish }}
                </p>
              </td>
              <td>
                <p class="print--gray">
                  ${{ format(item.price) }}
                </p>
              </td>
              <td>
                <p class="print--gray">
                  {{ format(item.totalSquare) }}
                </p>
              </td>
              <td align="right">
                <p class="print--gray">
                  ${{ format(item.totalPrice) }}
                </p>
              </td>
            </tr>

          </table>
          <table class="print__table print__top" width="100%" style="border-collapse: collapse;">
            <!-- 1.1 Header -->
            <tr class="print__borderBottom pb-12">
              <td width="15%">
                <h4 class="print--blue">
                  Qty
                </h4>
              </td>
              <td width="40%">
                <h4 class="print--blue">
                  Description
                </h4>
              </td>
              <td width="30%">
                <h4 class="print--blue">
                  Cost
                </h4>
              </td>
              <td width="15%" align="right">
                <h4 class="print--blue">
                  Sub Total
                </h4>
              </td>
            </tr>
            <tr
              class="print__borderBottom pt-4 pb-2"
              v-for="(item) in charges"
              :key="item.id"
            >
              <td>
                <p class="print--gray">
                  {{ item.quantity }}
                </p>
              </td>
              <td>
                <p class="print--gray">
                  {{ item.title }}
                </p>
              </td>
              <td>
                <p class="print--gray">
                  $ {{ parseFloat(item.unitCost).toFixed(2) }}
                </p>
              </td>
              <td align="right">
                <p class="print--gray">
                  $ {{ (item.quantity * parseFloat(item.unitCost)).toFixed(2) }}
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="p-20">
          <table class="print__table print__top" width="100%" style="border-collapse: collapse;">
            <tr class="print__borderBottom pt-3 pb-3">
              <td colspan="5">
                <h4 class="print--green pb-12">Notes:</h4>
                <p class="print--black">
                  {{ invoice && invoice.quote && invoice.quote.notes }}
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="p-20">
          <table class="print__top" width="100%" style="border-collapse: collapse;">
            <!-- Print Total Cost -->
            <tr class="print__borderBottom pt-3 pb-3">
              <td class="" align="right">
                <table class="print__top" width="40%">
                  <tr class="print__cost">
                    <td class="">
                      Subtotal
                    </td>
                    <td class="" align="right">
                      {{ invoice && invoice.quote && formatter.format(invoice.quote.total) }}
                    </td>
                  </tr>
                  <tr class="print__cost">
                    <td class="">
                      Tax HST/GST {{ invoice && invoice.quote && parseFloat(`${invoice.quote.tax || 0}`) }}%
                    </td>
                    <td class="" align="right">
                      {{ invoice && invoice.quote && formatter.format(parseFloat(invoice.quote.tax) * parseFloat(invoice.quote.subtotal) / 100) }}
                    </td>
                  </tr>
                  <tr class="print__cost">
                    <td class="print--blue">
                      <h2 class="print--blue">Total customer charges:</h2>
                    </td>
                    <td class="print--blue" align="right">
                      <h2 class="print--blue">{{ invoice && invoice.quote && formatter.format(invoice.quote.total) }}</h2>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="p-20">
          <table class="print__top" width="100%">
            <!-- Print Total Cost -->
            <tr class="print__cost" v-for="(item, index) in invoice.order && invoice.order.payments" :key="index">
              <td class="">
                Payment - {{item.type}} on {{formatDate(item.createdAt, 'MMMM DD, YYYY')}}
              </td>
              <td class="" align="right">
                {{ formatter.format(item.amount) }}
              </td>
            </tr>
            <tr class="print__cost">
              <td style="padding-top: 20px">
                Balance Due
              </td>
              <td style="padding-top: 20px" align="right">
                {{ invoice && invoice.order && invoice.quote && formatter.format((invoice.quote.total - getTotal(invoice.order.payments || [], 'amount')) || 0) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td style="padding: 20px">
          <table class="print__top" width="100%">
            <!-- Print Info And Signature -->
            <tr class="print__bottom mt-auto">
              <td class="" width="50%">
                <div>
                  <p class="semi-bold print--green">
                    Thank you for shopping at {{ STORE_TITLE }}
                  </p>
                  <p>
                    HST/GST #: {{ invoice && invoice.order && invoice.order.orderNumber }} <br /><br />
                    {{ settings.policyText }}
                  </p>
                </div>
              </td>
              <td width="50%" valign="top" style="padding-top: 5px">
                <table class="print__table print__top" width="100%" style="border-collapse: collapse; padding-top: 20px;">
                  <tr class="print__signature print__bottom mt-auto">
                    <td width="100%" style="padding-top: 10px">Signature</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import LogoFlooringAndHome from '../../components/logos/LogoFlooringAndHome.vue'
import { BACKEND_API_URL, STORE_TITLE } from '../../constants/default'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { formatDate, formatter, getHeaders } from '../../utils'
import { INVOICE_STATUSES } from '../../constants/invoice'

export default {
  components: { LogoFlooringAndHome },
  data () {
    return {
      STORE_TITLE,
      formatter,
      formatDate,
      paymentVisible: false,
      customerNotes: '',
      internalNotes: '',
      statuses: INVOICE_STATUSES.map(item => ({ code: item.name, label: item.title })),
      status: ''
    }
  },
  computed: {
    ...mapGetters({
      invoice: 'getInvoice',
      charges: 'getQuoteCharges',
      settings: 'getSettings',
      customer: 'getQuoteCustomer'
    }),
    avatar () {
      let value = ''
      if (this.customer?.accountManager?.avatar) {
        value = "'" + this.customer.accountManager.avatar + "'"
      }
      return value
    },
    productList () {
      return this.invoice?.quote?.products ? this.invoice?.quote?.products?.map(item => ({
        ...item,
        price: this.settings.price,
        totalSquare: this.getTotal(item.measurements, 'square'),
        totalPrice: this.getTotal(item.measurements, 'square') * this.settings.price
      })) : []
    },
    textAreaChartCount () {
      if (this.customerNotes === 0) {
        return 0
      }
      return this.customerNotes.length
    },
    textAreaChartCountSecond () {
      if (this.internalNotes === 0) {
        return 0
      }
      return this.internalNotes.length
    }
  },
  created () {
    this.getInvoice()
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    getTotal (items = [], field) {
      let total = 0

      Array.isArray(items) && items.forEach(item => {
        total += item[field] ? parseFloat(item[field]) : 0
      })

      return total
    },
    format (value) {
      return Math.round(value * 100) / 100
    },
    getInvoice () {
      const url = `${BACKEND_API_URL}/invoices/${this.$route.params.invoiceId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch('setInvoice', res.data)
          this.status = res.data && res.data.status
          this.$store.dispatch('setQuoteCustomer', res.data?.quote?.customer)
          this.$store.dispatch('setQuoteCharges', res.data?.quote?.charges)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    }
  }
}
</script>

<style lang="scss">
.print {
  height: 100vh;

  &__table,
  &__cost,
  &__bottom {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__borderBottom {
    border-bottom: 1px solid $secondaryBlue;
  }

  &--green {
    color: $green;
  }

  &--blue {
    color: $blue;
  }

  &--gray {
    color: $gray;
  }

  &--black {
    color: $black;
  }

  &__logo {
    padding-top: 25px;

    padding-left: 20px;

    span {
      margin-right: 21px;
    }

    p {
      font-size: 9px;
      line-height: 13px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $gray;
    }
  }

  &__customer {
    padding-top: 25px;
    padding-left: 46px;
    padding-bottom: 14px;

    background-color: $grayBg;

    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Green */
      color: #00818f;
      margin-bottom: 5px;
    }

    h4 {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      // font-size: 9px;
      // line-height: 13px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-size: 9px;
      line-height: 13px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Secondary/Accessible Grey */
      color: #707070;
    }
  }

  &__profile {
    h4 {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Green */
      color: #00818f;
      margin-bottom: 11px;
    }

    img {
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }

    p {
      font-weight: normal;
      line-height: 9px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }
  }

  &__table {
    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__cost {
    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 12px;
      text-align: right;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }

    h2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Blue 1 */
      color: #004d8b;
    }
  }

  &__bottom {
    padding-bottom: 38px;

    p.semi-bold {
      font-weight: 600;
    }

    p {
      font-family: Raleway;
      // font-size: 7px;
      // line-height: 10px;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }

  &__signature {
    border-top: 1px solid #1d1c1c;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 12px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }
  }
}
</style>
