<template>
  <div class="partnerList">
    <div class="container-fluid">
      <div class="partnerList__header d-flex align-items-center justify-content-between p-3 py-md-6">
        <h1 class="partnerList__title">
          Partners
        </h1>
        <router-link to="/new-partner" class="add-icon mr-8">
          Add New <span>+</span>
        </router-link>
      </div>
      <div class="row mt-2 mb-5 mr-1 mr-md-6">
        <table class="partnerList__table table">
          <thead>
            <tr>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Partner</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click="handleSortField('partnerCompanyName', 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click="handleSortField('partnerCompanyName', 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Contact</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click="handleSortField('contacts.firstName', 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click="handleSortField('contacts.firstName', 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Title/Type</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click="handleSortField('contacts.title', 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click="handleSortField('contacts.title', 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Notes</th>
            </tr>
          </thead>
          <PartnerTableRow
            v-for="partner in partnerData"
            :key="partner.key"
            :partner="partner"
            :getPartners="getPartners"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaretUp from '@/components/icons/arrow/IconCaretUp'
import IconCaretDown from '@/components/icons/arrow/IconCaretDown'
import PartnerTableRow from '@/components/partnerTable/PartnerTableRow'
import EventBus from '@/eventBus'
import { mapGetters } from 'vuex'

export default {
  components: {
    IconCaretUp,
    IconCaretDown,
    PartnerTableRow
  },
  data () {
    return {
      direction: 'ASC',
      name: 'partnerCompanyName',
      partnerData: []
    }
  },
  computed: {
    ...mapGetters({
      partners: 'getPartners',
      keyword: 'getKeyword'
    })
  },
  created () {
    EventBus.$on('search', payload => {
      this.getPartners()
    })

    this.getPartners()
  },
  methods: {
    handleSortField (field, dir) {
      this.name = field
      this.direction = dir
    },
    getPartners () {
      const params = {
        keyword: this.keyword,
        name: this.name,
        dir: this.direction
      }

      this.$store.dispatch('fetchPartners', { params })
    }
  },
  watch: {
    keyword: function () {
      this.getPartners()
    },
    name: function () {
      this.getPartners()
    },
    direction: function () {
      this.getPartners()
    },
    partners: function (newValue) {
      const newData = []
      newValue.forEach(ele => {
        newData.push({ ...ele })
        // eslint-disable-next-line no-unused-expressions
        ele?.contacts?.forEach((eleContract, index) => {
          newData.push({ ...eleContract, ...ele, idConctract: eleContract.id, key: ele.id + '-' + index })
        })
      })
      this.partnerData = newData
    }
  }
}
</script>

<style lang="scss" scoped>
.partnerList {
  &__header {
    padding-right: 5rem !important;

    .add-icon {
      text-decoration: none;
      display: flex;
      align-items: center;

      span {
        color: #C6500E;
        font-size: 24px;
        margin-left: 4px;
      }
    }
  }

  .sort-arrow {
    line-height: 10px;
  }

  &__table {
    thead {
      background-color: $green;
      color: $white;
      max-height: 50px !important;
    }

    td[scope='row'] {
      font-size: 13px;
      line-height: 13px;
      a {
        color: $green !important;
        font-weight: bold;
        font-size: 13px;
        font-family: Raleway;
      }
    }

    th[scope='col'] {
      padding: 0 24px;
      min-height: 50px !important;
      height: 50px;
    }

    tr td {
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px !important;
      min-height: 50px !important;
      height: 50px;
    }
  }
}
</style>
