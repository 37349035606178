<template>
  <div class="quoteInternal p-3 px-md-5 py-md-6">
    <!-- 1.Quoto Internal -->
    <div class="container-fluid px-0 mb-4">
      <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto">
        <!-- 1.1 Header -->
        <div
          hidden
          class="quoteInternal__tableHeader col-5 col-sm-3 col-md-12 border-bottom pb-md-4 row no-gutters"
        >
          <div class="col-md-2 pr-md-4">
            <h4 class="text-info">
              Product
            </h4>
          </div>
          <div class="col-md-3 px-md-4">
            <h4 class="text-info">
              Description
            </h4>
          </div>
          <div class="col-md-1 px-md-4">
            <h4 class="text-info">
              SKU
            </h4>
          </div>
          <div class="col-md-2 pl-md-4">
            <h4 class="text-info text-md-center">
              Cost
            </h4>
          </div>
          <div class="col-md-2 pl-md-4">
            <h4 class="text-info text-md-right">
              SF
            </h4>
          </div>
          <div class="col-md-2 pl-md-4">
            <h4 class="text-info text-md-right">
              Sub Total
            </h4>
          </div>
        </div>
        <!-- 1.2 Body | Rows -->
        <div
          hidden
          class="quoteInternal__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2"
          v-for="(item, index) in productList"
          :key="index"
        >
          <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
            <p class="text-secondary">
              {{ item.manufacturer.title }}
            </p>
          </div>
          <div class="col-md-4 px-md-4">
            <p class="text-secondary pb-3 pb-md-0 px-3 px-md-0">
              {{ item.type.flooring }},
              {{ item.type.species }},
              {{ item.type.lookGrade }},
              {{ item.type.platform }},
              {{ item.type.collection }},
              {{ item.type.gloss }},
              {{ item.type.width }},
              {{ item.type.finish }}
            </p>
          </div>
          <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0" :key="updateKey">
            <p class="text-secondary text-md-center">
              ${{ format(settings.price) }}
            </p>
          </div>
          <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
            <p class="text-secondary text-md-right">
              {{ format(item.totalSquare) }}
            </p>
          </div>
          <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
            <p class="text-secondary text-md-right">
              ${{ format(item.totalPrice) }}
            </p>
          </div>
        </div>
      </div>

      <!-- Charges -->
      <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto mt-4">
        <div
          class="quoteInternal__tableHeader col-5 col-sm-3 col-md-12 border-bottom pb-md-4 row no-gutters"
        >

          <div class="col-md-2 px-md-4" style="padding-left: 0 !important">
            <h4 class="text-info">Brand</h4>
          </div>
          <div class="col-md-2 px-md-4" style="padding-left: 0 !important">
            <h4 class="text-info">Type</h4>
          </div>
          <div class="col-md-3 px-md-4">
            <h4 class="text-info">
              Description
            </h4>
          </div>
          <div class="col-md-2 px-md-4">
            <h4 class="text-info">
              SKU
            </h4>
          </div>
          <div class="col-md-1">
            <h4 class="text-info text-info">
              Price $
            </h4>
          </div>
          <div class="col-md-1 px-md-4" style="padding-left: 0 !important">
            <h4 class="text-info  text-md-center">
              Unit
            </h4>
          </div>
          <div class="col-md-1">
            <h4 class="text-info text-md-right text-info ">
              Total
            </h4>
          </div>
        </div>
        <div
          class="quoteInternal__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2"
          v-for="(item) in charges"
          :key="item.id"
        >
          <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
            <p class="text-secondary">
              {{ item.brand }}
            </p>
          </div>
          <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
            <p class="text-secondary">
              {{ item.type }}
            </p>
          </div>
          <div class="col-md-3 px-md-4">
            <p class="text-secondary">
              {{ item.description }}
            </p>
          </div>
          <div class="col-md-2 px-md-4">
            <p class="text-secondary">
              {{ item.sku }}
            </p>
          </div>
          <div class="col-md-1">
            <p class="text-secondary">
              {{ formatter.format(parseFloat(item.price)) }}
            </p>
          </div>
          <div class="col-md-1 pr-md-4 pb-3 pb-md-0 px-3 px-md-0 text-md-center">
            <p class="text-secondary">
              {{ item.quantity }}
            </p>
          </div>
          <div class="col-md-1 px-3 px-md-0">
            <p class="text-secondary text-md-right">
              {{ formatter.format(item.quantity * parseFloat(item.price)) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 2.Sub Total -->
    <div class="d-flex justify-content-between mb-6">
      <h4 class="text-info">
        Sub Total
      </h4>
      <h4 class="text-info">
        {{ formatter.format(quote.subtotal || 0) }}
      </h4>
    </div>
    <!-- Tax -->
    <div class="d-flex justify-content-between mb-6"  v-for="quoteTax in quoteTaxes" :key="quoteTax.name">

      <h4 class="text-info">
        {{ quoteTax.name }} ({{  quoteTax.rate }}%)
      </h4>
      <h4 class="text-info">
        {{ formatter.format(quoteTax.amount) }}
      </h4>

    </div>
    <!-- Total Cost -->
    <div class="d-flex justify-content-between mb-6">
      <h4 class="text-info">
        Total
      </h4>
      <h4 class="text-info">
        {{ formatter.format(quote.total || 0) }}
      </h4>
    </div>
    <!-- 3.Customer Notes -->
    <div class="mb-4">
      <InputTextArea
        placeholder=""
        v-model="customerNotes"
        label="Customer notes (This will appear in quotes and invoices)"
        rows="4"
      />
      <p class="text-secondary small text-md-right">
        {{ textAreaChartCount }} / 1000
      </p>
    </div>

    <!-- Internal Notes -->
    <div class="mb-4">
      <InputTextArea
        placeholder=""
        v-model="internalNotes"
        label="Internal notes"
        rows="4"
      />
      <p class="text-secondary small text-right">
        {{ textAreaChartCountSecond }} / 1000
      </p>
    </div>

    <!-- 4.Save Charge -->
    <div class="mb-4 d-flex justify-content-between">
      <div>
        <b-button variant="outline-danger"  class="w-200" size="lg" @click="handleDeleteBtnClick">Delete Quote</b-button>
      </div>
      <div>
        <b-button variant="outline-primary"  class="w-200 mr-2" size="lg" @click="convertToOrder">Convert to order</b-button>
        <b-button variant="outline-primary"  class="w-200 mr-2" size="lg" @click="duplicateQuote">Duplicate</b-button>
        <b-button variant="outline-primary" class="w-200 mr-2"  size="lg" @click="editQuote">Edit</b-button>
        <b-button variant="primary" class="w-200 mr-2" size="lg" @click="saveQuote">Save</b-button>
      </div>
    </div>
    <!-- 5.Email And Print -->
    <div class="quoteInternal__icon d-flex mb-6">
<!--      <span class="d-flex align-items-center mr-6">-->
<!--        <div class="link">Email</div>-->
<!--        <IconMail class="ml-1" />-->
<!--      </span>-->
      <span class="d-flex align-items-center mr-6" @click="printPdf">
        <p class="link">Print PDF</p>
        <IconFilePdf class="ml-2" />
      </span>
      <!-- <span class="d-flex align-items-center mr-6" @click="downloadPdf">
        <p class="link">Download PDF</p>
        <IconFilePdf class="ml-2" />
      </span> -->
    </div>
    <!-- <div ref="print" class="">
      <QuotePrint />
    </div> -->
  </div>
</template>

<script>
// import ButtonPrimary from '@/components/buttons/ButtonPrimary'
// import ButtonSecondary from '@/components/buttons/ButtonSecondary'
import InputTextArea from '@/components/inputs/InputTextArea'
// import IconMail from '@/components/icons/basic/IconMail'
import { mapGetters } from 'vuex'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders, getTotal, formatter } from '../../utils'
import IconFilePdf from '@/components/icons/file/IconFilePdf'
// import QuotePrint from './QuotePrint'

export default {
  name: 'QuoteSelectManufacturer',
  components: {
    // ButtonPrimary,
    // ButtonSecondary,
    InputTextArea,
    // IconMail,
    // QuotePrint,
    IconFilePdf
  },
  data () {
    return {
      formatter,
      getTotal,
      customerNotes: '',
      internalNotes: '',
      updateKey: 0
    }
  },
  computed: {
    ...mapGetters({
      quoteNumber: 'getQuoteNumber',
      customer: 'getQuoteCustomer',
      manufacturer: 'getQuoteManufacturer',
      type: 'getQuoteType',
      measurements: 'getQuoteMeasurements',
      products: 'getQuoteProducts',
      charges: 'getQuoteCharges',
      quote: 'getQuote',
      settings: 'getSettings',
      quoteTaxes: 'getQuoteTaxes',
      quotePartners: 'getQuotePartners'
    }),
    productList () {
      return this.products ? this.products.map(item => ({
        ...item,
        price: this.settings.price,
        totalSquare: this.getTotal(item.measurements, 'square'),
        totalPrice: this.getTotal(item.measurements, 'square') * this.settings.price
      })) : []
    },
    textAreaChartCount () {
      if (this.customerNotes === 0) {
        return 0
      }
      return (this.customerNotes && this.customerNotes.length) || 0
    },
    textAreaChartCountSecond () {
      if (!this.internalNotes) {
        return 0
      }
      return this.internalNotes ? this.internalNotes.length : 0
    }
  },
  created () {
    this.$store.commit('RESET_QUOTE')
    this.getQuote()
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    format (value) {
      return (Math.round(value * 100) / 100).toFixed(2)
    },
    saveQuote () {
      if (this.quoteNumber) {
        const url = `${BACKEND_API_URL}/quotes/${this.quoteNumber}`
        const payload = {
          notes: this.customerNotes,
          internalNotes: this.internalNotes,
          partners: this.quote.partners
        }

        axios.put(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            this.$bvToast.toast('Quote saved!', {
              // title: 'Quote saved!',
              autoHideDelay: 2000,
              appendToast: true,
              solid: true,
              toaster: 'b-toaster-bottom-left',
              variant: 'success'
            })
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    editQuote () {
      const url = `${BACKEND_API_URL}/quotes/${this.quote.id}`

      axios.get(url, {
        headers: getHeaders()
      })
        .then(res => {
          this.$store.commit('SET_QUOTE', res.data)
          this.$router.push({ name: 'QuoteDetailEdit' })
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    duplicateQuote () {
      const url = `${BACKEND_API_URL}/quotes/${this.quote.id}/duplicate`
      axios.post(url, null, {
        headers: getHeaders()
      })
        .then(res => {
          this.$router.push({ name: 'QuoteDetail', params: { quoteId: res.data.id } })
          this.$store.commit('SET_QUOTE', res.data)
          console.log('res quote', res)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    convertToOrder () {
      const url = `${BACKEND_API_URL}/orders`
      const payload = {
        quoteNumber: this.quoteNumber
      }
      axios.post(url, payload, {
        headers: getHeaders()
      })
        .then(res => {
          if (res.data.id) {
            this.$router.push({ name: 'OrderDetail', params: { orderId: res.data && res.data.id } })
          }
          //  else {
          //   this.$router.push({ name: 'OrderDetail', params: { orderId: res.data && res.data.id } })
          // }
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    handleDeleteBtnClick () {
      if (confirm('Are you sure you want to delete this quote?')) {
        const url = `${BACKEND_API_URL}/quotes/${this.quote.id}`
        axios.delete(url, {
          headers: getHeaders()
        })
          .then(res => {
            this.$router.push({ name: 'QuoteList' })
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    printPdf () {
      const routeData = this.$router.resolve({ name: 'QuotePrint', query: { quoteId: this.quote.id } })
      window.open(routeData.href, '_blank')

      // this.$router.push({ name: 'QuotePrint', params: { quoteId: this.quote.id } })
    },
    downloadPdf () {
      // var elm = this.$refs.print
      // var value = elm.innerHTML
      const url = `${BACKEND_API_URL}/quotes/${this.quote.id}/pdf`
      const FileDownload = require('js-file-download')

      const payload = {
        content: null,
        quoteId: this.$store.state.quote.quote.id
      }

      axios.post(url, payload, {
        headers: getHeaders(),
        responseType: 'blob'
      })
        .then(response => {
          FileDownload(response.data, `Quote-${this.quote.quoteNumber}.pdf`)
          console.log('res quote', response)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    getQuote () {
      const url = `${BACKEND_API_URL}/quotes/${this.$route.params.quoteId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch('setQuoteNumber', res.data.quoteNumber)
          this.$store.dispatch('setQuoteCustomer', res.data.customer)
          this.$store.dispatch('setQuoteManufacturer', res.data.manufacturer)
          this.$store.dispatch('setQuoteProducts', res.data.products)
          this.$store.dispatch('setQuoteCharges', res.data.charges)
          this.$store.dispatch('setQuotePartners', res.data.partners)
          this.$store.dispatch('setQuote', res.data)
          this.customerNotes = res.data.notes
          this.internalNotes = res.data.internalNotes
          this.updateKey++
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    }
  },
  watch: {
    'settings.price': function (newVal, oldVal) {
      this.quote.subtotal = parseFloat(this.quote.subtotal) + parseFloat(this.quote.tax) * parseFloat(this.quote.subtotal) / 100
      this.quote.total = parseFloat(this.quote.subtotal) + parseFloat(this.quote.tax) * parseFloat(this.quote.subtotal) / 100
    }
  }
}
</script>

<style lang="scss" scoped>
  .quoteInternal {
    &__tableHeader {
      filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.17));
      position: sticky;
      background-color: $white;
      top: 0;
      left: 0;
      z-index: 10;

      border-bottom: none;
      border-right: 1px solid $secondaryBlue;

      @include mq {
        filter: none;
        border-right: none;
        border-bottom: 1px solid $secondaryBlue;
      }
    }

    &__tableRow {
      p {
        @media screen and (max-width: 667px) {
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
    }

    &--link {
      cursor: pointer;
    }

    &__icon {
      /deep/ svg * {
        fill: $orange;
      }
    }
  }
</style>
