<template>
  <div class="container-fluid quoteSelectCustomer pl-3 pl-md-5">
    <h2 class="quoteSelectCustomer__title pt-6 pb-4 text-secondary">
      Select a manufacturer
    </h2>
    <div class="row boxes d-flex flex-wrap justify-content-start mb-4">
      <div v-for="(item, index) in manufacturers" :key="index" @click="item.active && selectManufacturer(item)"
           :class="{active: item.active, disabled: !item.active, 'selected-item': selected.id === item.id}">
        <img :src="require(`../../static/logos/${item.image}`)" alt="" />
      </div>
      <div class="link-wrapper">
        <router-link
          :to="{
                    name: 'QuoteCharge',
                  }"
        >
          Other Customer Charges and Products
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { MANUFACTURERS } from '../../constants/quote.js'
import { mapGetters } from 'vuex'

export default {
  name: 'QuoteSelectManufacturer',
  data () {
    return {
      manufacturers: MANUFACTURERS.filter(item => item.active),
      selected: -1
    }
  },
  computed: {
    ...mapGetters({
      quoteNumber: 'getQuoteNumber'
    })
  },
  created () {
    if (!this.quoteNumber) {
      this.$router.push({ name: 'QuoteSelectCustomer' })
    }
  },
  methods: {
    selectManufacturer (item) {
      this.selected = item
      this.onSave()
    },
    onSave () {
      if (this.selected) {
        this.$store.dispatch('setQuoteManufacturer', this.selected)
        this.$router.push({ name: 'QuoteSelectType' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteSelectCustomer {
  &__input {
    width: 400px;
  }
  .active {
    cursor: pointer;
  }
  .disabled {
    cursor: not-allowed;
    img {
      opacity: 0.5;
    }
  }
  .selected-item {
    background: $green;

  }
  .link-wrapper {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;

    a {
      color: $blue;
      text-decoration: none;
      font-size: 15px;
      font-weight: normal;
    }
  }
}
</style>
