<template>
    <div class="product">
      <HeaderMenu />
      <h2> Select Manufacturers you represent*. The product catalog will be inserted into the Quote application. </h2>
      <div class="manufacturer-checkbox">
        <input>
      </div>
      <!-- <li class="manufacturer-checkbox" v-for='{title, active} in manufacturers' v-bind:key="title" >
        <input class="manufacturer-checkbox" type="checkbox"
           :name="title"
           :id="title"
           :checked="active"
           v-model="manufacturers.active"
        >
      </li> -->
    </div>
</template>

<script>
import HeaderMenu from '../../components/flooring/HeaderMenu'
import { MANUFACTURERS } from '../../constants/quote.js'

export default {
  components: {
    HeaderMenu
  },
  data () {
    return {
      manufacturers: MANUFACTURERS
    }
  }
}
</script>
