import dayjs from 'dayjs'

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})
export function formatDate (date, format) {
  return dayjs(date).format(format)
}

export function getTotal (items = [], field) {
  let total = 0

  items.forEach(item => {
    total += item[field] ? parseFloat(item[field]) : 0
  })

  return total
}

export function getHeaders () {
  const token = window.localStorage.getItem('accessToken')
  return {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: `Bearer ${token}`,
    'store-id': window.sessionStorage.getItem('storeId')
  }
}

export const can = (permission, permissions = []) => {
  return permissions.includes('*') || permissions.includes(permission)
}

export const emailFormatter = (value) => {
  if (!value) return value
  const parts = value.split('@')
  if (parts.length !== 2) return value
  parts[1] = parts[1].toLowerCase()
  return parts.join('@')
}
