<template>
  <div class="orderList">
    <div class="container-fluid">
      <!-- Edit Product Modal -->
      <b-modal id="edit-product-modal" @ok="handleUpdateProductOk" title="Edit Product" ok-title="Save">
        <b-form inline>
          <label for="product-brand" class="w-25">Brand</label>
          <b-form-input id="product-brand" v-model="editProduct.brand" type="text" class="w-75 mb-3"></b-form-input>
          <label for="product-type" class="w-25">Type</label>
          <b-form-input id="product-type" v-model="editProduct.type" type="text" class="w-75 mb-3"></b-form-input>
          <label for="product-description" class="w-25">Description</label>
          <b-form-textarea id="product-description" v-model="editProduct.description" rows="3" class="w-75 mb-3"></b-form-textarea>
          <label for="product-sku" class="w-25">SKU</label>
          <b-form-input id="product-sku" v-model="editProduct.sku" type="text" class="w-75 mb-3"></b-form-input>
          <label for="product-notes" class="w-25">Notes</label>
          <b-form-textarea id="product-notes" v-model="editProduct.notes" rows="3" class="w-75 mb-3"></b-form-textarea>
          <label for="product-price" class="w-25">Price</label>
          <b-form-input id="product-price" v-model="editProduct.price" type="number" step="0.01" min="0" class="w-75 mb-3"></b-form-input>
        </b-form>
      </b-modal>
      <!-- Create Product Modal -->
      <b-modal id="create-product-modal" @ok="handleCreateProductOk" title="Create Product" ok-title="Create">
        <b-form inline>
          <label for="product-brand" class="w-25">Brand</label>
          <b-form-input id="product-brand" v-model="newProduct.brand" type="text" class="w-75 mb-3"></b-form-input>
          <label for="product-type" class="w-25">Type</label>
          <b-form-input id="product-type" v-model="newProduct.type" type="text" class="w-75 mb-3"></b-form-input>
          <label for="product-description" class="w-25">Description</label>
          <b-form-textarea id="product-description" v-model="newProduct.description" rows="3" class="w-75 mb-3"></b-form-textarea>
          <label for="product-sku" class="w-25">SKU</label>
          <b-form-input id="product-sku" v-model="newProduct.sku" type="text" class="w-75 mb-3"></b-form-input>
          <label for="product-notes" class="w-25">Notes</label>
          <b-form-textarea id="product-notes" v-model="newProduct.notes" rows="3" class="w-75 mb-3"></b-form-textarea>
          <label for="product-price" class="w-25">Price</label>
          <b-form-input id="product-price" v-model="newProduct.price" type="number" step="0.01" min="0" class="w-75 mb-3"></b-form-input>
        </b-form>
      </b-modal>
      <!-- Order Header -->
      <div
        class="orderList__header py-3 pt-md-6 pr-md-6 pl-md-5 row mb-4 align-items-center"
      >
        <!-- Header: Title -->
        <div class="col-6 col-md-4 order-1">
          <b-link to="products" class="text-dark">
            <h1 class="">
              Products: Items
            </h1>
          </b-link>
        </div>
        <!-- Header: Search -->
        <div v-if="this.$route.name !== 'ProductList'" class="col-md-5 order-3 order-md-2">
          <div class="search-block">
            <b-form-input
              v-model="search"
              @keyup="handleSearch"
              type="text"
              placeholder="Search items"
            />
            <span @click="handleSearch">
              <IconSearch />
            </span>
          </div>
        </div>
        <!-- Header: Date -->
        <div
          class="orderList__dateToggle col-6 col-md-3 order-2 order-md-3 d-none justify-content-end"
        >
          <div
            class="d-flex align-items-center"
            v-b-toggle="'collapseOrderDate'"
          >
            <div class="breadcrumbLink">May 8, 2021 - May 14, 2021</div>
            <span class="ml-4 d-flex">
              <IconChevronDown />
            </span>
          </div>
        </div>
        <!-- Header: Date Collapse -->
        <b-collapse
          id="collapseOrderDate"
          class="orderList__dateCollapse col-12 order-4 mt-6"
        >
          <div class="row">
            <!-- Date -->
            <div
              class="orderList__calendar col-md-8 d-flex flex-wrap justify-content-center"
            >
              <span class="left">
                <IconChevronBigLeft />
              </span>
              <span class="right">
                <IconChevronBigRight />
              </span>
              <b-calendar locale="en-US" class="mr-4"></b-calendar>
              <b-calendar locale="en-US" class="ml-4"></b-calendar>
            </div>
            <!-- Choose Date Range -->
            <div class="orderList__dateRange ml-auto col-md-4">
              <div class="mb-4">
                <h4 class="text-secondary mb-2">
                  Date Range
                </h4>
                <v-select
                  :options="[
                    'Custom',
                    'Today',
                    'Yesterday',
                    'Last Week',
                    'Last Month',
                    'Last 7 Days',
                    'Last 30 Days',
                    'Month to Date (MTD)',
                    'Year to Date (YTD)'
                  ]"
                  placeholder="Custom"
                ></v-select>
              </div>
              <div class="row mb-3">
                <div class="col-6 pr-3">
                  <b-form-datepicker
                    size="sm"
                    placeholder="May 8, 2021"
                    dropright
                  ></b-form-datepicker>
                </div>
                <div class="col-6 pl-3">
                  <b-form-datepicker
                    size="sm"
                    placeholder="May 14, 2021"
                    dropright
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="row mb-4 px-3">
                <ButtonPrimary>
                  Apply
                </ButtonPrimary>
                <ButtonSecondary class="border-0">
                  Cancel
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </b-collapse>
        <div class="col-12 order-5" v-if="allowEdit">
          <b-button v-b-modal.create-product-modal variant="primary">Create an item</b-button>
          <!-- <b-button variant="default" @click="refreshProductTableData">Refresh</b-button> -->
        </div>
      </div>

      <!-- Order Body - Table -->
      <div class="row mt-2 mb-5 overflow-auto">
        <div class="col">
          <div id="product-table" class="w-100"></div>
        </div>
      </div>
      <div
        class="row text-center"
        v-if="orders && pagination.totalCount > orders.length"
      >
        <!-- <div class="col-12 pb-4">
          <div @click="loadMore()">
            <ButtonSecondary class="ml-3">Load More</ButtonSecondary>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import IconChevronDown from '@/components/icons/arrow/IconChevronDown'
import IconChevronBigLeft from '../../components/icons/arrow/IconChevronBigLeft.vue'
import IconChevronBigRight from '../../components/icons/arrow/IconChevronBigRight.vue'
import IconEdit from '../../components/icons/edit/IconEdit.vue'
import IconTrashFull from '../../components/icons/basic/IconTrashFull.vue'
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
import ButtonSecondary from '@/components/buttons/ButtonSecondary.vue'
import { mapGetters } from 'vuex'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import dayjs from 'dayjs'
import { ORDER_STATUSES } from '../../constants/order'
import { getHeaders } from '../../utils'
import Tabulator from 'tabulator-tables'
import Vue from 'vue'
import 'tabulator-tables/dist/css/tabulator.min.css'
import IconSearch from '@/components/icons/edit/IconSearch'

export default {
  components: {
    IconChevronDown,
    IconChevronBigLeft,
    IconChevronBigRight,
    ButtonPrimary,
    ButtonSecondary,
    IconSearch
  },
  props: {
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      page: 1,
      pageSize: 10,
      searchedOrder: null,
      searchStatus: null,
      name: 'quote.quoteNumber',
      direction: 'ASC',
      totalCount: 0,
      search: '',
      statuses: ORDER_STATUSES,
      productTable: null,
      mockApi: 'http://localhost:3000',
      newProduct: {
        sku: '',
        brand: '',
        type: '',
        price: null,
        description: ''
      },
      editProduct: {
        id: null,
        sku: '',
        brand: '',
        type: '',
        price: null,
        description: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      orders: 'getOrders',
      pagination: 'getOrderPagination',
      keyword: 'getKeyword'
    })
  },
  created () {
    EventBus.$on('search', payload => {
      this.page = 1
      this.getOrders()
      this.search = payload
    })

    this.getOrders()
  },
  mounted () {
    // Build Tabulator
    const vue = this
    var table = new Tabulator('#product-table', {
      // height: '311px',
      layout: 'fitColumns',
      placeholder: 'No Data Set',
      selectable: this.allowEdit ? 'highlight' : true,
      height: '55vh',
      columns: [
        { title: 'ID', field: 'id', visible: false },
        { title: 'Brand', field: 'brand', sorter: 'string', width: 190 },
        { title: 'Type', field: 'type', sorter: 'string', width: 190 },
        { title: 'Description', field: 'description', formatter: 'textarea', width: 300 },
        { title: 'SKU', field: 'sku', sorter: 'string', width: 80 },
        { title: 'Notes', field: 'notes', formatter: 'textarea' },
        { title: 'Price', field: 'price', sorter: 'number', width: 90 },
        { title: 'SearchField', field: 'search_field', visible: false },
        {
          formatter: () => {
            var ComponentClass = Vue.extend(IconEdit)
            var instance = new ComponentClass()
            instance.$mount()
            return instance.$el
          },
          width: 20,
          cellClick: (e, cell) => {
            this.$bvModal.show('edit-product-modal')
            this.editProduct = {
              ...cell.getRow().getData()
            }
            // alert('Printing row data for: ' + cell.getRow().getData().sku)
          },
          headerSort: false,
          visible: vue.allowEdit
        },
        {
          formatter: () => {
            var ComponentClass = Vue.extend(IconTrashFull)
            var instance = new ComponentClass()
            instance.$mount()
            return instance.$el
          },
          width: 20,
          cellClick: (e, cell) => {
            if (confirm('Confirm deletion?')) {
              this.deleteProduct(cell.getRow().getData().id)
            }
          },
          headerSort: false,
          visible: vue.allowEdit
        }
      ],
      pagination: 'local',
      paginationSize: 20,
      paginationButtonCount: 10,
      ajaxResponse: function (url, params, response) {
        response.data.forEach(ele => {
          ele.search_field = Object.values(ele).join(' ').toLowerCase()
        })
        return response.data
      },
      rowDblClick: (e, row) => {
        this.$bvModal.show('edit-product-modal')
        this.editProduct = {
          ...row.getData()
        }
      }
      // rowClick:function(e, row){
      //   this.selectRow(row)
      // },
    })
    this.productTable = table
    this.refreshProductTableData()

    // trigger AJAX load on "Load Data via AJAX" button click
    // document.getElementById('ajax-trigger').addEventListener('click', function () {
    // table.setData('https://6169e4fc16e7120017fa0e60.mockapi.io/products')
    // })
  },
  methods: {
    getOrders (append = false) {
      const params = {
        name: this.name,
        dir: this.direction,
        page: this.page,
        limit: this.pageSize,
        q: this.$route.name === 'ProductList' ? this.keyword : this.search
      }
      const queryParams = new URLSearchParams(params)
      const url = `${BACKEND_API_URL}/orders${
        queryParams ? '?' + queryParams : ''
      }`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch(
            append ? 'appendOrders' : 'setOrders',
            res.data
          )
          this.totalCount = res.totalCount
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    getCustomerFullName (customer) {
      let fullName = ''

      if (customer) {
        fullName = `${customer.firstName} ${customer.lastName}`
      }

      return fullName
    },
    getCustomerPhone (customer) {
      let phone = ''

      if (customer) {
        phone = customer.mobilePhone || customer.homePhone || ''
      }

      return phone
    },
    getManufacturerTitle (name) {
      const manufacturer = this.manufacturers.find(item => item.name === name)

      return manufacturer ? manufacturer.title : ''
    },
    formatDate (date) {
      return dayjs(date).format('MM/DD/YYYY')
    },

    customFilter (data, keywords) {
      const heystack = Object.values(data).join(' ').toLowerCase()
      return heystack.includes(keywords.toLowerCase())
    },
    handleSearch () {
      const searchKeyWord = this.$route.name === 'ProductList' ? this.keyword : this.search
      if (searchKeyWord) {
        this.productTable.setFilter('search_field', 'keywords', searchKeyWord, { matchAll: true })
      } else {
        this.productTable.clearFilter()
      }
    },
    loadMore () {
      this.page++
      this.getOrders(true)
    },
    getStatusName (name) {
      const status = this.statuses.find(item => name ? item.name === name : item.name === 'empty')
      return status ? status.name : 'empty'
    },
    getStatusTitle (name) {
      const status = this.statuses.find(item => name ? item.name === name : item.name === 'empty')
      return status.name === 'empty' ? 'No Payment' : status.title
    },
    refreshProductTableData () {
      console.log('Refreshing table')
      this.productTable.setData(BACKEND_API_URL + '/products', {}, {
        headers: {
          ...getHeaders()
        }
      }).then(() => {
        this.productTable.redraw(true)
        this.$store.commit('SET_PRODUCT_TABLE', this.productTable)
      })
    },
    addProductRow () {
      this.productTable.addRow({}, true)
    },
    createProduct () {
      axios.post(BACKEND_API_URL + '/products', this.newProduct, {
        headers: getHeaders(),
        responseType: 'json'
      })
        .then(response => {
          this.refreshProductTableData()
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    updateProduct (product) {
      const url = BACKEND_API_URL + '/products/' + product.id
      const data = {
        ...product
      }
      delete data.id
      delete data.search_field
      axios.patch(url, data, {
        headers: getHeaders(),
        responseType: 'json'
      })
        .then(response => {
          this.refreshProductTableData()
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    deleteProduct (id) {
      axios.delete(BACKEND_API_URL + '/products/' + id)
        .then(response => {
          this.refreshProductTableData()
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
          alert(e.response.data.message)
        })
    },
    handleCreateProductOk (bvModalEvent) {
      this.createProduct()
    },
    handleUpdateProductOk (bvModalEvent) {
      this.updateProduct(this.editProduct)
    }
  },
  watch: {
    name: function (val) {
      this.getOrders()
    },
    direction: function (val) {
      this.getOrders()
    },
    keyword: function () {
      this.handleSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.orderList {
  &__searchIcon {
    font-size: 24px;

    /deep/ * {
      fill: $orange;
    }
  }

  &__dateToggle {
    span {
      font-size: 24px;
      cursor: pointer;

      /deep/ svg * {
        fill: $orange;
      }
    }
  }

  &__dateCollapse {
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;

      /deep/ svg * {
        fill: $orange;
      }

      &.left {
        left: 10px;
      }
      &.right {
        right: 10px;
      }
    }
  }

  &__calendar {
    /deep/ .b-calendar {
      &-grid {
        background-color: $grayBg;
        border-radius: 20px;
        padding: 0 19px 29px;
      }

      &-header {
        display: none;
      }

      &-nav {
        display: none !important;
      }
    }
  }

  &__dateRange {
    /deep/ .b-form-datepicker {
      height: 50px;
      border: 1px solid $green;
      border-radius: 10px;

      button {
        visibility: hidden;
        width: 1px;
      }

      label {
        white-space: nowrap;
        overflow: hidden;
        line-height: 42px;
      }
    }
  }

  .table {
    thead {
      background-color: $green;
      color: $white;
      max-height: 50px !important;
    }

    td[scope='row'] {
      font-size: 13px;
      line-height: 13px;
      a {
        color: $green !important;
        font-weight: bold;
        font-size: 13px;
        font-family: Raleway;
      }
    }

    th[scope='col'] {
      padding: 0 24px;
      min-height: 50px !important;
      height: 50px;
    }

    tr td {
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px !important;
      min-height: 50px !important;
      height: 50px;
    }
  }

  .sort-arrow {
    line-height: 10px;
  }

  .search-block {
    position: relative;

    @include mq {
      padding-left: 30px;
    }
    // margin-top: 36px;

    input {
      border-radius: 10px;
      padding: 13px 8px 13px 16px;
      border: 1px solid #00818f;
      height: 50px;
    }

    span {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 15px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
      /deep/ svg * {
        fill: $red;
      }
    }
  }
  a {
    color: $green !important;
    font-weight: bold;
    font-size: 13px;
    font-family: Raleway;
  }
}

</style>
