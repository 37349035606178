<template>
  <div class="boxOrderPartnerTag py-6">
    <div class="px-4">
      <h2>Partner</h2>
      <div class="search-section pb-2 row justify-content-between align-items-center">
        <b-form-input
          class="boxPartnerTag__search col-md-8"
          v-model="search"
          @keyup.enter="handlePartnerSearch"
          type="text"
          placeholder="Add Partner"
        />
        <div class="col-md-4">
          <span
            class="icon"
            @click="handleAddPartnerModal"
          >
            +
          </span>
        </div>
      </div>
      <div>
        <div
          v-for="partner in order.partners"
          :key="partner.id"
          class="row align-items-center"
        >
          <div class="col-md-8">{{ partner.partnerCompanyName }}</div>
          <div class="col-md-4">
            <span class="icon" @click="removePartner(partner.id)">-</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalShow"
      size="md"
      :hide-footer="true"
      centered
    >
      <div class="p-3 text-secondary">
        <div class="modal__content row mb-4">
          <div class="col-12">
            <h3 class="text-info">Add Partner</h3>
            <div
              v-for="partner in searchedPartners"
              :key="partner.id"
              class="row align-items-center"
            >
              <div class="col-md-6 d-flex align-items-center justify-content-between">
                <span>{{ partner.partnerCompanyName }}</span>
                <b-icon
                  v-if="!isPartnerAdded(partner.id)"
                  icon="plus"
                  class="icon"
                  @click="addPartnerToOrder(partner.id)"
                >
                  +
                </b-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

export default {
  name: 'BoxOrderPartnerTag',
  components: {},
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      partners: 'getPartners',
      keyword: 'getKeyword',
      order: 'getOrder'
    })
  },
  created () {
    this.getOrder()
  },
  data () {
    return {
      search: '',
      searchedPartners: null,
      modalShow: false
    }
  },
  methods: {
    handleAddPartnerModal () {
      this.$store.dispatch('setKeyword', this.search)
      this.modalShow = !this.modalShow
    },
    handlePartnerSearch () {
      this.$store.dispatch('setKeyword', this.search)
      this.modalShow = true
    },
    isPartnerAdded (id) {
      return this.order.partners.find(ele => ele.id === id)
    },
    removePartner (id) {
      this.order.partners = this.order.partners.filter(ele => ele.id !== id)
      this.$emit('partnerChange', this.order.partners)
    },
    addPartnerToOrder (id) {
      const partner = this.searchedPartners.find(ele => ele.id === id)
      this.order.partners.push(partner)
      this.$emit('partnerChange', this.order.partners)
    },
    getPartners () {
      const params = {
        keyword: this.keyword
      }
      this.$store.dispatch('fetchPartners', { params, searchField: 'companyName' })
    },
    getOrder () {
      const url = `${BACKEND_API_URL}/orders/${this.$route.params.orderId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch('setOrder', res.data)
        })
        .catch(e => {})
    }
  },
  mounted () {
    this.$store.dispatch('fetchPartners', {})
  },
  watch: {
    partners: function () {
      this.searchedPartners = this.partners
    },
    keyword: function () {
      this.getPartners()
    }
  }
}
</script>

<style lang="scss" scoped>
.boxOrderPartnerTag {
  .search-section {
    border-bottom: 1px solid;
  }

  .icon {
    color: $orange;
    font-size: 32px;
    margin-left: 4px;
    cursor: pointer;
  }

  &__search {
    background-color: $grayBg;
    border-radius: 10px;
    padding: 6px;
  }
}

.icon {
  color: $orange;
  font-size: 32px;
  margin-left: 4px;
  cursor: pointer;
}
</style>
