import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const state = () => ({
  pagination: {},
  customers: [],
  customersTotal: 0,
  customer: Object
})

const getters = {
  getCustomers (state) {
    return state.customers
  },
  getCustomer (state) {
    return state.customer
  },
  getCustomerPagination (state) {
    return state.pagination
  }
}

const actions = {
  setCustomers (context, payload) {
    // debugger
    context.commit('SET_CUSTOMERS', payload)
  },
  appendCustomers (context, payload) {
    context.commit('APPEND_CUSTOMERS', payload)
  },
  addCustomer (context, payload) {
    context.commit('ADD_CUSTOMER', payload)
  },
  fetchCustomers ({ commit }, payload) {
    const queryParams = new URLSearchParams(payload.params)
    const url = `${BACKEND_API_URL}/customers${
      queryParams ? '?' + queryParams : ''
    }`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data) {
          commit(payload.append ? 'APPEND_CUSTOMERS' : 'SET_CUSTOMERS', res.data)
          commit('SET_CUSTOMERS_TOTAL', res.totalCount)
        }
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  },
  fetchCustomer ({ commit }, payload) {
    const url = `${BACKEND_API_URL}/customers/${payload}`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data) {
          commit('SET_CUSTOMER', res.data)
        }
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  }
}

const mutations = {
  SET_CUSTOMERS (state, payload) {
    // debugger
    const { data, ...pagination } = payload
    state.customers = data || []
    state.pagination = pagination || {}
  },
  SET_CUSTOMERS_TOTAL (state, payload) {
    state.customersTotal = payload || 0
  },
  APPEND_CUSTOMERS (state, payload) {
    const { data, ...pagination } = payload
    state.customers = data ? [...state.customers, ...data] : []
    state.pagination = pagination || {}
  },
  ADD_CUSTOMER (state, payload) {
    state.customers.push(payload)
  },
  SET_CUSTOMER (state, payload) {
    state.customer = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
