import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const state = () => ({
  pagination: {},
  users: [],
  user: Object,
  error: ''
})

const getters = {
  getUsers (state) {
    return state.users
  },
  getUserPagination (state) {
    return state.pagination
  },
  getLoggedInUser (state) {
    return state.user
  }
}

const actions = {
  setUser (context, payload) {
    context.commit('SET_USER', payload)
  },
  setUsers (context, payload) {
    context.commit('SET_USERS', payload)
  },
  appendUsers (context, payload) {
    context.commit('APPEND_USERS', payload)
  },
  addUser (context, payload) {
    context.commit('ADD_USER', payload)
  },
  fetchUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      const url = `${BACKEND_API_URL}/users`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          console.log('data', res.data)
          if (res.data) {
            commit('SET_USERS', res.data)
          }
          resolve(res.data)
        })
        .catch(e => {
          commit('SET_USER_ERROR', e && e.message)
          reject(e)
        })
    })
  },
  updateUser ({ commit, dispatch }, payload) {
    const { id, ...data } = payload
    const url = `${BACKEND_API_URL}/users/${id}`

    return axios.put(url, data, {
      headers: getHeaders()
    })
  },
  fetchUser (context, payload) {
    const url = `${BACKEND_API_URL}/users/${payload.id}`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        // console.log('data', res.data)
        if (res.data) {
          context.commit('SET_USER', res.data)
        }
      })
      .catch(e => {
        context.commit('SET_USER_ERROR', e && e.message)
      })
  },
  uploadProfilePhoto (context, payload) {
    const url = `${BACKEND_API_URL}/auth/photo`

    const data = new FormData()
    data.append('name', 'avatar')
    data.append('file', payload)

    const config = {
      headers: getHeaders()
    }
    return axios.post(
      url,
      data,
      config
    )
  }
}

const mutations = {
  SET_USER (state, payload) {
    state.user = payload || {}
  },
  SET_USERS (state, payload) {
    // const { data, ...pagination } = payload
    state.users = payload || []
    // state.pagination = pagination || {}
  },
  APPEND_USERS (state, payload) {
    const { data, ...pagination } = payload
    state.users = data ? [...state.users, ...data] : []
    state.pagination = pagination || {}
  },
  ADD_USER (state, payload) {
    state.users.push(payload)
  },
  SET_USER_ERROR (state, payload) {
    state.error = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
