import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Invitation from '../views/auth/Invitation.vue'
import SignUp from '../views/auth/SignUp.vue'

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'LayoutAuth',
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      layout: 'LayoutAuth',
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      layout: 'LayoutAuth',
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'LayoutAuth',
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/invitation',
    name: 'Invitation',
    component: Invitation,
    meta: {
      layout: 'LayoutAuth',
      public: true,
      onlyLoggedOut: true
    }
  }
]
