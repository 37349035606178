<template>
  <component :is="layout" v-show="Object.keys($store.state.auth.profile).length === 0 || $store.state.settings.storeId != null">
    <slot />
  </component>
</template>

<script>
import LayoutDefault from './LayoutDefault'
import LayoutEmpty from './LayoutEmpty'
import LayoutAuth from './LayoutAuth'

export default {
  components: {
    LayoutDefault,
    LayoutEmpty,
    LayoutAuth
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'LayoutDefault'
    }
  }
}
</script>
