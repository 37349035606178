<template>
  <div class="boxCustomerDetail bg-light px-4 py-6 pt-md-6 px-md-6 pb-md-7">
    <!-- 1.Title -->
    <div
      class="boxCustomerDetail__title d-flex flex-wrap justify-content-between"
    >
      <h2>
        Customer
      </h2>
      <div class="edit d-flex align-items-center">
        <router-link
          :to="{
              name: 'CustomerEdit',
              params: { customerId: customer.id }
            }"
          class=""
        >
          <p class="bold link">Edit Customer</p>
        </router-link>
        <router-link
          :to="{
              name: 'CustomerEdit',
              params: { customerId: customer.id }
            }"
          class="ml-4"
        >
          <IconEdit />
        </router-link>
      </div>
    </div>
    <!-- 2.Customer Info -->
    <div class="boxCustomerDetail__info">
      <router-link
        :to="{
              name: 'CustomerDetail',
              params: { customerId: customer.id }
            }"
        class="align-self-end ml-"
      >
        <h4 class="link" v-if="customer.company && customer.company.useAsName">{{ customer.company.name }}</h4>
        <h4 class="link">{{ customer.firstName }} {{ customer.lastName }}</h4>
      </router-link>
      <div class="d-flex flex-wrap">
        <div class="d-flex align-items-center" v-if="customer.homePhone">
          <p class="bold">H:</p>
          <p class="mr-4">
            {{ customer.homePhone }}
          </p>
        </div>
        <div class="d-flex align-items-center" v-if="customer.mobilePhone">
          <p class="bold">M:</p>
          <p>
            {{ customer.mobilePhone }}
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center" v-if="customer.email">
        <p class="bold">E:</p>
        <p>
          {{ customer.email }}
        </p>
      </div>
    </div>
    <!-- 3.Customer Shipping Address -->
    <div class="boxCustomerDetail__address">
      <h4>Shipping address</h4>
      <p>
        {{ customer.shippingAddress && customer.shippingAddress.street }}
      </p>
      <p>
        {{ customer.shippingAddress && customer.shippingAddress.city }}
        {{ customer.shippingAddress && customer.shippingAddress.city && customer.shippingAddress.stateOrProvince && ', ' }}
        {{ customer.shippingAddress && customer.shippingAddress.stateOrProvince }}
      </p>
      <p>
        {{ customer.shippingAddress && customer.shippingAddress.postal }}
      </p>
    </div>
    <!-- 4.Customer Profile -->
    <div class="boxCustomerDetail__profile">
      <h4>
        Account manager
      </h4>
      <div class="d-flex">
        <b-avatar size="40px" :src="customer.accountManager && customer.accountManager.avatar"></b-avatar>
        <p class="align-self-center pl-4">
          {{ customer.accountManager && customer.accountManager.firstName }} {{ customer.accountManager && customer.accountManager.lastName }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconEdit from '@/components/icons/edit/IconEdit'
import { mapGetters } from 'vuex'
export default {
  name: 'BoxCustomerDetail',
  components: { IconEdit },
  computed: {
    ...mapGetters({
      customer: 'getQuoteCustomer'
    })
  }
}
</script>

<style lang="scss" scoped>
.boxCustomerDetail {
  // background: $grayBg;
  // padding: 32px 32px 45px;

  h2,
  h4 {
    color: $black;
  }

  h4,
  p {
    padding-bottom: 8px;
  }

  p {
    color: $gray;
  }

  &__title {
    border-bottom: 1px solid $blue;
    margin-bottom: 16px;

    h2 {
      color: $black;
      margin-bottom: 8px;
    }

    .edit {
      /deep/ svg * {
        fill: $orange;
      }
    }
  }

  &__info {
    margin-bottom: 32px;

    p:not(.bold) {
      text-indent: 4px;
    }
  }

  &__address {
    margin-bottom: 32px;
  }

  &__profile {
    p {
      color: $black;
    }
  }

  .link {
    cursor: pointer;
    color: $green;
    margin-right: 10px;
  }

  a:hover {
    text-decoration: none;
  }
}
</style>
