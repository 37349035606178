<template>
  <div class="w-100 border-bottom admin-menu">
    <h1 class="px-3 py-3 px-md-5 py-md-6">
      {{ STORE_TITLE }}
    </h1>
    <b-nav class="ml-3 mb-2 admin-menu">
      <b-nav-item
        :active="$route.name === item.name"
        :to="{name: item.name}"
        v-for="(item, index) in menu"
        :key="index"
      >
        {{ item.title }}
        <IconChevronDown
          v-if="$route.name === item.name"
          class="chevron-down"
        />
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STORE_TITLE } from '../../constants/default'
import IconChevronDown from '@/components/icons/arrow/IconChevronDownRed'
import { can } from '../../utils'

const menu = [
  { name: 'UserProfile', title: 'Overview', permission: 'manage-profile' },
  // { name: 'UserSettings', title: 'Settings', permission: 'manage-settings' },
  { name: 'CompanySettings', title: 'Store Settings', permission: 'manage-company-settings' },
  { name: 'Users', title: 'Users', permission: 'manage-users' }
  // { name: 'ProductSettings', title: 'Product Settings', permission: 'manage-product-settings' },
  // { name: 'RolePermissions', title: 'Role Permissions', permission: 'manage-permissions' }
]

export default {
  components: {
    IconChevronDown
  },
  data () {
    return {
      STORE_TITLE
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),
    menu () {
      const permissions = this.profile?.permissions
      return permissions ? menu.filter(item => can(item.permission, permissions)) : []
    }
  }
}
</script>

<style lang="scss">
  .admin-menu {
    .nav a {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold !important;
      font-size: 15px !important;
      line-height: 15px !important;

      &.active {
        color: $green;
      }
    }
  }
  .chevron-down {
    font-size: 24px;
    transition: transform 0.5s;
    fill: #C6500E;
  }

  /deep/ svg {
    font-size: 18px;

    * {
      fill: #C6500E;
      display: inline-flex;
      align-items: center;
    }
  }
</style>
