<template>
  <div class="card">
    <div class="d-flex justify-content-center card__logo">
      <img src="@/static/logos/logoStacked.svg" class="mr-3" alt="" />
    </div>
    <div class="card__form">
      <form @submit.prevent="handleFormRequest" v-if="emailSent == false">
        <h3 class="w-100 text-center card__title">Reset Password</h3>

        <div class="form-group">
          <InputText
            v-model="email"
            label="Email address"
            required="true"
            :status="$v.email.$error"
          />
        </div>

        <ButtonPrimary type="submit" class="btn btn-dark btn-lg btn-block" v-bind:submitInProgress="submitInProgress">Reset Password</ButtonPrimary>

      </form>
      <div class="form-group" v-else>
        <div class="w-100 text-center">
          <div class="success">
            An email has been sent to you with instructions on how to reset your password. You may close this window now.
          </div>
        </div>
      </div>

      <div class="card__footer" v-if="emailSent === false">
        <div>
          New to Build CRM? <router-link to="/signup">Create an account</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import InputText from '@/components/inputs/InputText'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { email, required } from 'vuelidate/lib/validators'
import { getHeaders } from '../../utils'

export default {
  components: {
    ButtonPrimary,
    InputText
  },
  data () {
    return {
      email: '',
      emailSent: false,
      submitInProgress: false
    }
  },
  validations: {
    email: {
      email,
      required
    }
  },
  methods: {
    handleFormRequest () {
      const url = `${BACKEND_API_URL}/auth/forgot-password`
      const payload = {
        email: this.email
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'

        this.submitInProgress = true
        axios.post(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            this.emailSent = true
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
          .finally(() => {
            this.submitInProgress = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
  .card {
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 630px;
    padding: 24px;

    &__logo {
      padding-top: 10px;
      padding-bottom: 30px;
      border-bottom: 1px solid #CEDDE7;

      img {
        max-width: 100px;
      }
    }

    &__title {
      font-size: 24px;
      color: #707070;
      margin-bottom: 24px;
    }

    &__form {
      padding: 25px 5% 25px;
    }
    &__footer {
      border-top: 1px solid #CEDDE7;
      padding: 20px 0 0;
    }
  }
  .inputText {
    position: relative;

    &__error {
      position: absolute;
      color: #c11b0f;
      font-size: 32px;
      line-height: 0.8;
    }

    &__label {
      color: $secondaryGray;
    }

    &__error + &__label {
      padding-left: 15px;
    }

    &__input {
      display: flex;
      width: 100%;
      background: $white;
      color: #a19f9f;
      border: 1px solid #00818f;
      border-radius: 10px;
      outline: none;

      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;

      padding: 13px 16px;
      margin: 8px 0px;
    }
  }
</style>
