var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"boxCustomerDetail bg-light px-4 py-6 pt-md-6 px-md-6 pb-md-7"},[_c('div',{staticClass:"boxCustomerDetail__title d-flex flex-wrap justify-content-between"},[_c('h2',[_vm._v(" Customer ")]),_c('div',{staticClass:"edit d-flex align-items-center"},[_c('router-link',{attrs:{"to":{
            name: 'CustomerEdit',
            params: { customerId: _vm.customer.id }
          }}},[_c('p',{staticClass:"bold link"},[_vm._v("Edit Customer")])]),_c('router-link',{staticClass:"ml-4",attrs:{"to":{
            name: 'CustomerEdit',
            params: { customerId: _vm.customer.id }
          }}},[_c('IconEdit')],1)],1)]),_c('div',{staticClass:"boxCustomerDetail__info"},[_c('router-link',{staticClass:"align-self-end ml-",attrs:{"to":{
            name: 'CustomerDetail',
            params: { customerId: _vm.customer.id }
          }}},[(_vm.customer.company && _vm.customer.company.useAsName)?_c('h4',{staticClass:"link"},[_vm._v(_vm._s(_vm.customer.company.name))]):_vm._e(),_c('h4',{staticClass:"link"},[_vm._v(_vm._s(_vm.customer.firstName)+" "+_vm._s(_vm.customer.lastName))])]),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.customer.homePhone)?_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"bold"},[_vm._v("H:")]),_c('p',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.customer.homePhone)+" ")])]):_vm._e(),(_vm.customer.mobilePhone)?_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"bold"},[_vm._v("M:")]),_c('p',[_vm._v(" "+_vm._s(_vm.customer.mobilePhone)+" ")])]):_vm._e()]),(_vm.customer.email)?_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"bold"},[_vm._v("E:")]),_c('p',[_vm._v(" "+_vm._s(_vm.customer.email)+" ")])]):_vm._e()],1),_c('div',{staticClass:"boxCustomerDetail__address"},[_c('h4',[_vm._v("Shipping address")]),_c('p',[_vm._v(" "+_vm._s(_vm.customer.shippingAddress && _vm.customer.shippingAddress.street)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.customer.shippingAddress && _vm.customer.shippingAddress.city)+" "+_vm._s(_vm.customer.shippingAddress && _vm.customer.shippingAddress.city && _vm.customer.shippingAddress.stateOrProvince && ', ')+" "+_vm._s(_vm.customer.shippingAddress && _vm.customer.shippingAddress.stateOrProvince)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.customer.shippingAddress && _vm.customer.shippingAddress.postal)+" ")])]),_c('div',{staticClass:"boxCustomerDetail__profile"},[_c('h4',[_vm._v(" Account manager ")]),_c('div',{staticClass:"d-flex"},[_c('b-avatar',{attrs:{"size":"40px","src":_vm.customer.accountManager && _vm.customer.accountManager.avatar}}),_c('p',{staticClass:"align-self-center pl-4"},[_vm._v(" "+_vm._s(_vm.customer.accountManager && _vm.customer.accountManager.firstName)+" "+_vm._s(_vm.customer.accountManager && _vm.customer.accountManager.lastName)+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }