<template>
  <div>
    <QuoteHeader />
    <div class="quoteSelectCustomer px-5 pb-8 flex-column flex-md-row">
      <h2 class="quoteSelectCustomer__title pt-6 pb-4">
        Search customer
      </h2>
      <div class="quoteSelectCustomer__input">
        <vue-bootstrap-typeahead
          class="inputText__input"
          v-model="query"
          :data="customers"
          :serializer="item => `${item.firstName} ${item.lastName}`"
          @hit="selectItem($event)"
          placeholder="Enter customer name"
        >
          <template slot="append">
            <IconSearch class="quoteSelectCustomer__searchIcon" />
          </template>
        </vue-bootstrap-typeahead>

      </div>
      <h2>
        or
      </h2>
      <router-link to="/new-customer">
        <ButtonPrimary type="submit" value="Add a new customer">
          Add a new customer
        </ButtonPrimary>
      </router-link>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import QuoteHeader from '@/components/partials/quote/QuoteHeader'
import IconSearch from '@/components/icons/edit/IconSearch'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import axios from 'axios'
import { BACKEND_API_URL } from '../../constants/default'
import { mapGetters } from 'vuex'
import { getHeaders } from '../../utils'

export default {
  name: 'QuoteSelectCustomer',
  components: { ButtonPrimary, QuoteHeader, IconSearch, VueBootstrapTypeahead },
  data () {
    return {
      query: '',
      customers: []
    }
  },
  computed: {
    ...mapGetters({
      customer: 'getQuoteCustomer',
      products: 'getQuoteProducts'
    })
  },
  created () {
    console.log('customer', this.customer, this.customer && this.customer.id)
    this.$store.dispatch('resetQuote')
    console.log('customer2', this.customer, this.customer && this.customer.id)
    if (this.customer && this.customer.id) {
      this.customers.push(this.customer)
      this.selectItem(this.customer)
    }
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    query (newQuery) {
      axios.get(`${BACKEND_API_URL}/customers?q=${newQuery}`, {
        headers: getHeaders()
      })
        .then((res) => {
          this.customers = res.data.data
        })
    }
  },
  filters: {
    stringify (value) {
      return JSON.stringify(value, null, 2)
    }
  },
  methods: {
    selectItem (item) {
      // axios.get(`${BACKEND_API_URL}/quote-number`, {
      //   headers: getHeaders()
      // })
      //   .then((res) => {
      //     // this.$store.dispatch('setQuoteNumber', res.data)
      //     // this.$router.push({ name: 'QuoteSelectManufacturer' })
      //   })
      this.$router.push({ name: 'QuoteCharge' })
      this.$store.commit('RESET_QUOTE')
      this.$store.dispatch('setQuoteCustomer', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteSelectCustomer {
  &__searchIcon {
    font-size: 24px;

    /deep/ * {
      fill: $orange;
    }
  }

  h2 {
    color: $gray;
  }

  &__input {

    @include mq {
      width: 400px;
    }
  }
}
</style>

<style lang="scss">
  .inputText__input {
    padding: 6px 15px 6px 3px;

    .input-group > input.form-control {
      border: none;

    }
    input.form-control:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    .input-group-append {
      padding-top: 8px;
    }
  }
</style>
