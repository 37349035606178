import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const state = () => ({
  profile: {},
  token: null
})

const getters = {
  getProfile (state) {
    return state.profile
  },
  getToken (state) {
    return state.token
  },
  isAutorized (state) {
    return !!state.token
  }
}

const actions = {
  setProfile (context, payload) {
    console.log('payload', payload)
    if (window.localStorage) {
      window.localStorage.setItem('user', payload)
    }

    context.commit('SET_PROFILE', payload)
  },
  setToken (context, payload) {
    if (window.localStorage) {
      window.localStorage.setItem('accessToken', payload.accessToken)
    }
    context.commit('SET_TOKEN', payload)
  },
  logoutUser (context, payload) {
    if (window.localStorage) {
      window.localStorage.removeItem('accessToken')
    }

    context.commit('SET_PROFILE', null)
    context.commit('SET_TOKEN', null)
    window.location.href = '/'
  },
  async fetchProfile (context) {
    return new Promise((resolve, reject) => {
      const url = `${BACKEND_API_URL}/auth/me`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          if (res.data) {
            context.commit('SET_PROFILE', res.data)
          }
          resolve(res.data)
        })
        .catch(error => {
          const status = error.response.status

          if (status === 401) {
            context.dispatch('logoutUser')
          }

          context.commit('SET_USER_ERROR', error && error.message)
          resolve()
        })
    })
  },
  updateProfile (context, payload) {
    if (context.state.profile.id) {
      const url = `${BACKEND_API_URL}/users/${context.state.profile.id}`
      return axios
        .put(url, payload, {
          headers: getHeaders()
        })
        .catch(e => {
          context.commit('SET_USER_ERROR', e && e.message)
        })
    }
  },
  changePassword (context, payload) {
    if (context.state.profile.id) {
      const url = `${BACKEND_API_URL}/auth/password`
      return axios
        .put(url, payload, {
          headers: getHeaders()
        })
    }
  }
}

const mutations = {
  SET_PROFILE (state, payload) {
    state.profile = payload
  },
  SET_TOKEN (state, payload) {
    state.token = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
