<template>
  <div class="customerList">
    <div class="container-fluid">
      <div class="row px-3 py-3 py-md-6">
        <div class="col-md-7 d-flex flex-column">
          <h1 class="customerList__title">
            Customer List
          </h1>
          <p v-if="searchedCustomer" class="pl-5 mt-2">
            Search results for <strong>{{ searchedCustomer }}</strong>
          </p>
          <p v-if="searchStatus" class="small pl-5 mt-2">
            {{ searchStatus }}
          </p>
        </div>
      </div>
      <div class="row mt-2 mb-5 mr-1 mr-md-6 overflow-auto">
        <table class="customerList__table table">
          <thead>
            <tr>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>{{ $t('message.name') }}</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'firstName'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'firstName'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">{{ $t('message.email') }}</th>
              <th scope="col">{{ $t('message.phone') }}</th>
              <th scope="col">{{ $t('message.address') }}</th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Acc Man</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'accountManager.firstName'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'accountManager.firstName'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>{{ $t('message.lastAccess') }}</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'lastAccessTime'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'lastAccessTime'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="searchedCustomerList.length > 0">
            <tr v-for="(customer, index) in searchedCustomerList" :key="index">
              <td scope="row" class="name-col">
                <router-link
                  :to="{
                    name: 'CustomerDetail',
                    params: { customerId: customer.parentCustomer ? customer.parentCustomer.id : customer.id }
                  }"
                  class="text-dark"
                >
                  {{ customer.firstName }} {{ customer.lastName }} <br>
                  <span v-if="customer.company">{{ customer.company.name }}</span>
                </router-link>
              </td>
              <td>
              <a
                  :href="`mailto:${customer.email}`"
                  class="text-dark"
                >
                {{ customer.email }}
              </a>
              </td>
              <td>
                <div v-if="customer.mobilePhone">
                  <strong>M</strong> - {{ customer.mobilePhone }}
                </div>
                <div v-if="customer.homePhone">
                  <strong>H</strong> - {{ customer.homePhone }}
                </div>
              </td>
              <td>
                {{ getAddress(customer.billingAddress) }}
              </td>
              <td>
                Search
                <div v-if="customer.accountManager">
                  {{ customer.accountManager.firstName }}{{ customer.accountManager.lastName[0].toUpperCase() }}
                </div>
              </td>
              <td>
                <!-- {{ customer.primaryCustomer.lastAccess }} -->
                {{ customer.lastAccessTime | formatDate('MMMM D, YYYY h:mm A') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(customer, index) in customers" :key="index">
              <td scope="row" class="name-col">
                <router-link
                  :to="{
                    name: 'CustomerDetail',
                    params: { customerId: customer.parentCustomer ? customer.parentCustomer.id : customer.id }
                  }"
                  class="text-dark"
                >
                  {{ customer.firstName }} {{ customer.lastName }}
                </router-link>
                <div v-if="customer.company" class="mt-1">{{ customer.company.name }}</div>

              </td>
              <td>
                <a
                  :href="`mailto:${customer.email}`"
                  class="text-dark"
                >
                  {{ customer.email }}
                </a>
              </td>
              <td>
                <div v-if="customer.mobilePhone">
                  <strong>M</strong> - {{ customer.mobilePhone }}
                </div>
                <div v-if="customer.homePhone">
                  <strong>H</strong> - {{ customer.homePhone }}
                </div>
              </td>
              <td>
                {{ getAddress(customer.billingAddress) }}
              </td>
              <td>
                <div v-if="customer.accountManager" :id="`tooltip-target-${customer.id}`">
                  {{ customer.accountManager.firstName }}{{ customer.accountManager.lastName[0].toUpperCase() }}
                </div>
                <b-tooltip v-if="customer.accountManager" class="mr-8" :target="`tooltip-target-${customer.id}`" triggers="hover">
                  {{ customer.accountManager.firstName }} {{ customer.accountManager.lastName }}
                </b-tooltip>
              </td>
              <td>
                <!-- {{ customer.primaryCustomer.lastAccess }} -->
                {{ customer.lastAccessTime | formatDate('MMMM D, YYYY h:mm A') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="row text-center"
        v-if="customers && pagination.totalCount > customers.length"
      >
        <div class="col-12 pb-4">
          <div @click="loadMore()">
            <ButtonSecondary class="ml-3">Load More</ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSecondary from '@/components/buttons/ButtonSecondary'
import IconCaretUp from '@/components/icons/arrow/IconCaretUp'
import IconCaretDown from '@/components/icons/arrow/IconCaretDown'
import EventBus from '@/eventBus'
import { mapGetters } from 'vuex'
import { formatDate } from '@/utils'

export default {
  components: {
    IconCaretUp,
    IconCaretDown,
    ButtonSecondary
  },
  data () {
    return {
      page: 1,
      pageSize: 10,
      searchedCustomer: null,
      searchedCustomerList: [],
      searchStatus: null,
      name: 'lastAccessTime',
      direction: 'DESC'
    }
  },
  filters: {
    formatDate
  },
  computed: {
    ...mapGetters({
      keyword: 'getKeyword',
      customers: 'getCustomers',
      customersDemo: [
        {
          primaryCustomer: {
            accountManager: null,
            name: 'John',
            lastName: 'Doe',
            email: 'John@email.com',
            phoneMobile: '416 555-7777',
            phoneHome: null
          },

          additionalCustomer: {
            status: false,
            name: 'Philip',
            lastName: 'William',
            email: 'philip@email.com',
            phoneMobile: '416 213-1113',
            phoneHome: null
          },

          company: {
            status: true,
            useAsName: false,
            name: 'ABC Company',
            email: 'ABCcompany@email.com',
            phoneMobile: '(416) 555-5555',
            phoneOffice: '(416) 555-5555'
          },

          billingAddress: {
            address: '123 Address Rd. Toronto, ON, A1B 23C',
            addressFlat: '81 Main Street',
            zip: '90210',
            city: 'Toronto',
            state: 'Ontario'
          },

          shippingAddress: {
            status: true,
            address: '78 Main Street',
            addressFlat: '81 Main Street',
            zip: '90210',
            city: 'Toronto',
            state: 'Ontario'
          },

          info: {
            howHearUs: 2,
            notes:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
          }
        },
        {
          primaryCustomer: {
            accountManager: null,
            name: 'Adam',
            lastName: 'Martin',
            email: 'John@email.com',
            phoneMobile: '416 555-7777',
            phoneHome: null
          },

          additionalCustomer: {
            status: false,
            name: 'Philip',
            lastName: 'William',
            email: 'philip@email.com',
            phoneMobile: '416 213-1113',
            phoneHome: null
          },

          company: {
            status: false,
            useAsName: false,
            name: null,
            email: null,
            phoneMobile: null,
            phoneOffice: null
          },

          billingAddress: {
            address: '123 Address Rd. Toronto, ON, A1B 23C',
            addressFlat: '78 Main Street',
            zip: '90210',
            city: 'Toronto',
            state: 'Ontario'
          },

          shippingAddress: {
            status: false,
            address: '81 Main Street',
            addressFlat: '78 Main Street',
            zip: '90210',
            city: 'Toronto',
            state: 'Ontario'
          },

          info: {
            howHearUs: 2,
            notes:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
          }
        }
      ],
      pagination: 'getCustomerPagination'
    })
  },
  created () {
    EventBus.$on('search', payload => {
      this.page = 1
      this.getCustomers()
    })

    this.getCustomers()
  },
  methods: {
    getCustomers (append = false) {
      const params = {
        name: this.name,
        dir: this.direction,
        page: this.page,
        limit: this.pageSize,
        q: this.keyword
      }

      this.$store.dispatch('fetchCustomers', { params, append })
    },
    getAddress (address) {
      if (address) {
        const parts = ['street', 'city', 'stateOrProvince', 'postal']
        return parts
          .filter(item => address[item])
          .map(item => address[item])
          .join(', ')
      }

      return ''
    },
    handleSearch () {
      this.page = 1
      this.getCustomers()
    },
    loadMore () {
      this.page++
      this.getCustomers(true)
    }
  },
  watch: {
    keyword: function () {
      this.handleSearch()
    },
    name: function (val) {
      this.getCustomers()
    },
    direction: function (val) {
      this.getCustomers()
    }
  }
}
</script>

<style lang="scss" scoped>
.customerList {
  &__table {
    thead {
      background-color: $green;
      color: $white;
      max-height: 50px !important;
    }

    td[scope='row'] {
      font-size: 13px;
      line-height: 13px;
      a {
        color: $green !important;
        font-weight: bold;
        font-size: 13px;
        font-family: Raleway;
      }
    }

    th[scope='col'] {
      padding: 0 24px;
      min-height: 50px !important;
      height: 50px;
    }

    tr td {
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px !important;
      min-height: 50px !important;
      height: 50px;
    }
  }

  .sort-arrow {
    line-height: 10px;
  }

  .search-block {
    position: relative;

    @include mq {
      padding-left: 30px;
    }
    // margin-top: 36px;

    input {
      border-radius: 10px;
      padding: 13px 8px 13px 16px;
      border: 1px solid #00818f;
      height: 50px;
    }

    span {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 15px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
      /deep/ svg * {
        fill: $red;
      }
    }
  }
  .name-col {
    width: 200px;
  }
}
</style>
