<template>
  <div class="customerDetail">
    <div class="customerDetail__header container-fluid border-bottom">
      <div class="row py-3 py-md-6 px-0 px-lg-3">
        <div class="col-12 col-md-8 d-flex">
          <h1 class="">
            {{
              customer && customer.company
                ? customer.company.name
                : `${customer.firstName} ${customer.lastName}`
            }}
          </h1>
          <router-link
            :to="{
              name: 'CustomerEdit',
              params: { customerId: customer.id }
            }"
            class="align-self-end ml-4"
          >
            <h3 class="d-inline-flex">
              Edit
            </h3>
            <IconEdit class="ml-2" />
          </router-link>
        </div>
        <div class="col-12 col-md-4 d-flex">
          <router-link
            :to="{ name: 'CustomerNew' }"
            class="align-self-center ml-md-auto pt-4 pt-lg-0"
          >
            <h3>
              Add another customer
            </h3>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <h2 class="pb-6 pl-lg-3">
            Customer notes
          </h2>
          <p  style="white-space: pre-wrap;">
            {{ customer && customer.notes }}
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mb-7">
        <div class="col-lg-8">
          <div
            class="customerDetail__infoSmall pt-5 mx-lg-3 pb-5 border-bottom"
          >
            <h2>
              Account manager
            </h2>
            <div class="d-flex" v-if="customer && customer.accountManager">
              <b-avatar size="40px" :src="customer.accountManager.avatar"></b-avatar>
              <p class="align-self-center pl-4">
                {{`${customer.accountManager.firstName} ${customer.accountManager.lastName}`}}
              </p>
            </div>
          </div>
          <div class="customerDetail__infoBig border-bottom py-6 mx-lg-3">
            <div class="row">
              <div class="col-lg-6">
                <h2>Primary Customer</h2>
                <p class="values font-weight-bold">
                  <!-- <span class="label">First name:</span> -->
                  <span>{{ customer && customer.firstName }}</span><span v-if="customer && customer.lastName"> {{ customer && customer.lastName }}</span>
                  <!-- <span class="label" v-if="customer && customer.lastName">Last name:</span> -->
                </p>
                <p class="values">
                  <span class="label" v-if="customer && customer.homePhone">H:</span>
                  {{ customer && customer.homePhone }}
                  <span class="label" v-if="customer && customer.mobilePhone">M:</span>
                  {{ customer && customer.mobilePhone }}
                </p>
                <p class="values" v-if="customer && customer.email">
                  <span class="label">E:</span> {{ customer && customer.email }}
                </p>
              </div>
              <div class="col-lg-6">
                <h2>Additional Customer</h2>
                <p class="values font-weight-bold" v-if="customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.firstName">
                  <!-- <span class="label">First name:</span> -->
                  {{
                    customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.firstName
                  }}<span v-if="customer && customer.additionalCustomer && customer.additionalCustomer.lastName"> {{
                    customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.lastName
                  }}</span>
                  <!-- <span class="label" v-if="customer && customer.additionalCustomer && customer.additionalCustomer.lastName">Last name:</span> -->
                </p>
                <p class="values">
                  <span class="label" v-if="customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.homePhone">H:</span>
                  {{
                    customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.homePhone
                  }}
                  <span class="label" v-if="customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.mobilePhone">M:</span>
                  {{
                    customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.mobilePhone
                  }}
                </p>
                <p class="values">
                  <span class="label" v-if="customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.email">E:</span>
                  {{
                    customer &&
                      customer.additionalCustomer &&
                      customer.additionalCustomer.email
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="customerDetail__infoBig py-6 mx-lg-3 border-bottom">
            <div class="row">
              <div class="col-lg-6">
                <h2>
                  Billing Address
                </h2>
                <p class="values">
                  <!-- <span class="label">Address:</span> -->
                  {{
                    customer &&
                      customer.billingAddress &&
                      customer.billingAddress.street
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">Apartment, suite, etc.</span> -->
                  {{
                    customer &&
                      customer.billingAddress &&
                      customer.billingAddress.aptOrSuite
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">Zip/Postal Code:</span> -->
                  {{
                    customer &&
                      customer.billingAddress &&
                      customer.billingAddress.postal
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">City:</span> -->
                  {{
                    customer &&
                      customer.billingAddress &&
                      customer.billingAddress.city
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">State/Province:</span> -->
                  {{
                    customer &&
                      customer.billingAddress &&
                      customer.billingAddress.stateOrProvince
                  }}
                </p>
              </div>
              <div class="col-lg-6">
                <h2>
                  Shipping Address
                </h2>
                <p class="values">
                  <!-- <span class="label">Address:</span> -->
                  {{
                    customer &&
                      customer.shippingAddress &&
                      customer.shippingAddress.street
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">Apartment, suite, etc.</span> -->
                  {{
                    customer &&
                      customer.shippingAddress &&
                      customer.shippingAddress.aptOrSuite
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">Zip/Postal Code:</span> -->
                  {{
                    customer &&
                      customer.shippingAddress &&
                      customer.shippingAddress.postal
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">City:</span> -->
                  {{
                    customer &&
                      customer.shippingAddress &&
                      customer.shippingAddress.city
                  }}
                </p>
                <p class="values">
                  <!-- <span class="label">State/Province:</span> -->
                  {{
                    customer &&
                      customer.shippingAddress &&
                      customer.shippingAddress.stateOrProvince
                  }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="customer && customer.howHearUs"
            class="customerDetail__infoSmall pt-5 mx-lg-3 pb-5 border-bottom"
          >
            <h2>
              How did they hear about us?
            </h2>
            <p class="text-secondary">
              <template v-if="customer && customer.howHearUs == 1">
                Existing Customer
              </template>
              <template v-else-if="customer && customer.howHearUs == 2">
                Google
              </template>
              <template v-else-if="customer && customer.howHearUs == 3">
                Trade Show
              </template>
              <template v-else-if="customer && customer.howHearUs == 4">
                Walk-In
              </template>
              <template v-else-if="customer && customer.howHearUs == 5">
                Web Site
              </template>
              <template v-else-if="customer && customer.howHearUs == 6">
                Word of Mouth
              </template>
              <template v-else-if="customer && customer.howHearUs == 7">
                Other
              </template>
            </p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 pt-4 pr-4">
          <div
            class="card mb-4 w-100"
            v-if="customer && customer.company && customer.company"
          >
            <h2>
              Company Details
            </h2>
            <p class="font-weight-bold">
              {{ customer && customer.company && customer.company.name }}
            </p>
            <p>
              <span class="label-company">M:</span>
              {{ customer && customer.company && customer.company.mobilePhone }}
            </p>
            <p>
              <span class="label-company">O:</span>
              {{ customer && customer.company && customer.company.officePhone }}
            </p>
            <p>
              <span class="label-company">E:</span>
              {{ customer && customer.company && customer.company.email }}
            </p>
          </div>
          <div class="buttons d-flex justify-content-lg-end">
            <!-- <router-link to="/quote/select-customer"> -->
            <ButtonPrimary @click="buildQuoteWithCustomer(customer)">Build a quote</ButtonPrimary>
            <!-- </router-link> -->
            <!-- <router-link to="/loan-sample">
              <ButtonSecondary class="ml-sm-4">Loan Sample</ButtonSecondary>
            </router-link> -->
          </div>
          <div v-for="(currentOrder, index) in customerOrders" class="mt-3" :key="'order_' + index">
          <!-- <div v-if="currentOrder" class="mt-5 pt-5"> -->
            <BoxQuote :id="'currentOrder_' + currentOrder.id">
              <template v-slot:title><router-link :to="{ name: 'OrderList' }">Order</router-link></template>
              <template v-slot:quote>
                <div class="breadcrumbLink mr-1">Order #:</div>
                <router-link :to="{ name: 'OrderDetail', params: { orderId: currentOrder.id } }">
                  <p class="small">{{ currentOrder.orderNumber }}</p>
                </router-link>
              </template>
              <!-- Box Qoute: Middle - Date -->
              <template v-slot:lastUpdated>
                <div class="breadcrumbLink mr-1">Last updated</div>
                <p>{{ formatDate(currentOrder.createdAt, 'MMM DD, YYYY') }}</p>
              </template>
              <!-- Box Qoute: Middle - Logo -->
              <!-- <template v-slot:logo>
                <img
                  src="@/static/logos/logoMercier.png"
                  alt=""
                  class="my-auto img-fluid"
                />
              </template> -->
            </BoxQuote>
          </div>
          <div class="mt-3">
            <hr>
          </div>
          <div v-for="(currentQuote, index) in customer.quotes" class="mt-3" :key="'quote_' + index">
          <!-- <div v-if="currentQuote" class="mt-5 pt-5"> -->
            <BoxQuote :id="'currentQuote_' + currentQuote.id" class="">
              <template v-slot:title><router-link :to="{ name: 'QuoteList' }">Quote</router-link></template>
              <template v-slot:quote>
                <div class="breadcrumbLink mr-1">Quote #:</div>
                <router-link :to="{ name: 'QuoteDetail', params: { quoteId: currentQuote.id } }">
                  <p class="small">{{ currentQuote.quoteNumber }}</p>
                </router-link>
              </template>
              <!-- Box Qoute: Middle - Date -->
              <template v-slot:lastUpdated>
                <div class="breadcrumbLink mr-1">Last updated</div>
                <p>{{ formatDate(currentQuote.createdAt, 'MMM DD, YYYY') }}</p>
              </template>
              <!-- Box Qoute: Middle - Logo -->
              <!-- <template v-slot:logo>
                <img
                  src="@/static/logos/logoMercier.png"
                  alt=""
                  class="my-auto img-fluid"
                />
              </template> -->
              <!-- Box Qoute: Main Collapsible Content -->
              <!--                <template v-slot:content>-->
              <!--                  <div  v-for="(product, index2) in quote.products" :key="index2">-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Flooring:</h4>-->
              <!--                      <p>{{ product.type.flooring }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Species:</h4>-->
              <!--                      <p>{{ product.type.flooring }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Look /Grade:</h4>-->
              <!--                      <p>{{ product.type.lookGrade }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Platform:</h4>-->
              <!--                      <p>{{ product.type.platform }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Collection:</h4>-->
              <!--                      <p>{{ product.type.collection }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Gloss:</h4>-->
              <!--                      <p>{{ product.type.gloss }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Width:</h4>-->
              <!--                      <p>{{ product.type.width }}</p>-->
              <!--                    </span>-->
              <!--                    <span class="d-flex align-items-center mb-2">-->
              <!--                      <h4 class="pr-1">Finish:</h4>-->
              <!--                      <p>{{ product.type.finish }}</p>-->
              <!--                    </span>-->
              <!--                  </div>-->
              <!--                </template>-->
            </BoxQuote>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconEdit from '@/components/icons/edit/IconEdit'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
// import ButtonSecondary from '@/components/buttons/ButtonSecondary'
import BoxQuote from '@/components/boxes/BoxQuote'

import { mapGetters } from 'vuex'
import { formatDate } from '../../utils'
export default {
  components: {
    IconEdit,
    ButtonPrimary,
    // ButtonSecondary,
    BoxQuote
  },
  data () {
    return {
      formatDate,
      id: null
    }
  },
  computed: {
    ...mapGetters({
      customer: 'getCustomer'
    }),
    currentOrder () {
      const quotes = this.customer && this.customer.quotes
      const orders = quotes && quotes.filter(item => item.order)
      return orders && orders.length > 0 ? orders[orders.length - 1] : null
    },
    currentQuote () {
      const quotes = this.customer && this.customer.quotes ? this.customer.quotes.filter(item => !item.order) : []
      return quotes && quotes.length > 0 ? quotes[quotes.length - 1] : null
    },
    customerOrders () {
      const orders = []
      this.customer.quotes.forEach(quote => {
        if (!quote.orders) {
          return
        }
        quote.orders.forEach(order => {
          orders.push(order)
        })
      })
      return orders
    }
  },
  created () {
    this.$store.dispatch('fetchCustomer', this.$route.params.customerId)
  },
  methods: {
    buildQuoteWithCustomer (customer) {
      this.$store.commit('SET_QUOTE_CUSTOMER', customer)
      this.$router.push({ name: 'QuoteSelectCustomer' })
    }
  }
}
</script>

<style lang="scss" scoped>
.customerDetail {
  &__header {
    /deep/ svg {
      * {
        fill: $orange;
      }
    }

    a {
      color: $green;
    }

    h2 {
      color: $black;
    }

    p {
      color: $gray;
    }
  }

  &__infoSmall {
    h2 {
      margin-bottom: 12px;
    }
  }

  &__infoBig {
    h2 {
      margin-bottom: 12px;
    }

    p {
      color: $gray;
      margin-bottom: 12px;
    }
  }
}

.card {
  background-color: $yellow;
  border-radius: 20px;
  padding: 24px;
}

.buttons {
  flex-wrap: wrap;
}

.label {
  font-weight: bold;
  color: #bbb;
}
.label-company {
  font-weight: bold;
}
</style>
