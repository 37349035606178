<template>
  <div class="quoteCharge p-3 px-md-5 pt-md-6">
    <!-- Quoto Charge Title -->
    <!-- <div class="row">
      <div class="col-12">
        <h2 class="quoteCharge__title pb-6 text-secondary">
          Add customer charges
        </h2>
      </div>
    </div> -->
    <!-- 1.Quoto Charges -->
    <div class="container-fluid px-0 mb-4">
      <div class="row no-gutters flex-nowrap flex-md-wrap">
        <!-- 1.1 Header -->
        <div
          class="quoteCharge__tableHeader row col-4 col-md-12 no-gutters px-0 pb-md-4"
        >
          <div class="col-md-2 px-md-4 my-md-auto">
            <h4 class="text-info my-auto">Brand</h4>
          </div>
          <div class="col-md-2 px-md-4 my-md-auto">
            <h4 class="text-info my-auto">Type</h4>
          </div>
          <div class="col-md-3 px-md-4 my-md-auto">
            <h4 class="text-info my-3">
              Description
            </h4>
          </div>
          <div class="col-md-1 px-md-4 my-md-auto">
            <h4 class="text-info my-3">
              SKU
            </h4>
          </div>
          <div class="col-md-1 pl-md-4 my-md-auto">
            <h4 class="text-info my-3 text-md-left">
              Price $
            </h4>
          </div>
          <div class="bigHeader col-md-1 pl-md-4 pl-md-0 pr-4 my-md-auto d-flex">
            <h4 class="text-info my-auto">
              Unit
            </h4>
          </div>
          <div class="col-md-1 pl-md-4 my-md-auto">
            <h4 class="text-info my-3 text-md-right">
              Total
            </h4>
          </div>
          <div class="col-md-1 pl-md-4 my-md-auto">
            <h4 class="text-info my-3 text-md-right"></h4>
          </div>
        </div>
        <!-- 1.2 Body | Rows -->
        <div
          class="quoteCharge__tableRow row col-8 col-sm-4 col-md-12 no-gutters align-items-md-center px-0 pt-md-4"
          v-for="(item, index) in charges"
          :key="index"
        >
          <div class="col-md-2 px-3 pr-md-4">
            <div class="qty">
              <InputText placeholder="" v-model="item.quantity" />
            </div>
          </div>
          <div class="col-md-4 px-3 px-md-4">
            <p class="text-secondary">
              {{ item.title }}
            </p>
          </div>
          <div class="col-md-1 px-3 px-md-4">
            <p class="text-secondary">
              {{ item.sku }}
            </p>
          </div>
          <div class="col-md-3 px-3 pl-md-4">
            <p class="text-secondary text-md-right unit-cost">
              <span>$</span> <InputText placeholder="" defaultValue="20" v-model="item.unitCost" />
            </p>
          </div>
          <div class="col-md-2 px-3 pl-md-4">
            <h4 class="text-secondary text-md-right">
              $ {{ (item.quantity * parseFloat(item.unitCost)).toFixed(2) }}
            </h4>
          </div>
        </div>

        <b-alert v-if="additionalCharges.length == 0"  show class="mt-3 w-100">Use buttons below to start adding line items or choosing from catalog.</b-alert>
        <div
          class="quoteCharge__tableRow row col-8 col-sm-4 col-md-12 no-gutters align-items-md-center px-0 pt-md-4"
          v-for="(addedItem, index) in $v.additionalCharges.$each.$iter"
          :key="'add'+index"
        >
          <div class="col-md-2 px-2 px-md-2">
            <InputText :disabled="addedItem.$model.id > 0" placeholder="" v-model="addedItem.$model.brand" />
          </div>
          <div class="col-md-2 px-2 px-md-2">
            <InputText :disabled="addedItem.$model.id > 0" placeholder="" v-model="addedItem.$model.type" />
          </div>
          <div class="col-md-3 px-2 px-md-2">
            <InputText :disabled="addedItem.$model.id > 0" placeholder="" v-model="addedItem.$model.description" />
          </div>
          <div class="col-md-1 px-2 px-md-2">
            <InputText :disabled="addedItem.$model.id > 0" placeholder="" v-model="addedItem.$model.sku" />
          </div>
          <div class="col-md-1 px-2 pl-md-2">
            <p class="text-secondary text-md-right unit-cost">
              <InputText placeholder="$" v-model="addedItem.$model.price" :status="addedItem.$error" />
            </p>
          </div>
          <div class="col-md-1 px-2 pr-md-2">
            <div class="qty">
              <InputText placeholder="" v-model="addedItem.$model.quantity" />
            </div>
          </div>
          <div class="col-md-1 px-2 pl-md-2">
            <h4 role="button" class="text-secondary text-md-right" :id="'lineItemPopover-' + index" :class="{ 'tax-partial' : addedItem.$model.taxes && (addedItem.$model.taxes.length != settings.taxes.length)}">
              {{ addedItem.$model.price && addedItem.$model.quantity ? formatter.format(addedItem.$model.quantity * parseFloat(addedItem.$model.price)) : formatter.format(0) }}
            </h4>
            <b-popover :target="'lineItemPopover-' + index" triggers="hover" placement="left">
              <template #title>Taxes</template>
              <b-form-group label="" >
                <b-form-checkbox-group
                  v-model="addedItem.$model.taxes"
                  :options="taxTemplate"
                  name="line-item-taxes"
                  text-field="name"
                  value-field="name"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-popover>
          </div>
          <div class="col-md-1 px-2 pl-md-2">
            <h4 class="text-md-right">
              <b-button variant="link"  @click="handleRemoveLineItem(index)" >
                <b-icon icon="trash" class="text-danger"></b-icon>
              </b-button>
              <!-- <IconTrashFull /> -->
            </h4>
          </div>

        </div>
        <div
          class="quoteCharge__tableRow row col-8 col-sm-4 col-md-12 no-gutters align-items-md-center px-0 pt-md-4"
        >
          <div class="flex">
            <IconPlusCircle variant="primary" hidden/>
            <b-button variant="info" @click="addAdditionalProduct" class="mx-2">
              <b-icon icon="plus-circle"></b-icon> Add Line Item
            </b-button>
            <b-button variant="info" @click="addItemFromCatalog" class="mx-2">
              <b-icon icon="plus-circle"></b-icon> Choose From Catalog
            </b-button>
            <!-- <h4 class="quoteCharge--link text-primary cursor-pointer" @click="addAdditionalProduct">+ </h4> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 2.Total Customer Charges -->
    <hr>
    <div class="d-flex justify-content-between mb-6">
      <h2 class="text-info">
        Subtotal:
      </h2>
      <h2 class="text-info">
        {{ formatter.format(totalCharges())}}
      </h2>
    </div>
    <!-- 3.Add another product -->
    <!-- <div class="mb-6">
      <h4 class="quoteCharge--link text-primary cursor-pointer" @click="addProduct">
        Add another product
      </h4>
    </div> -->
    <!-- <div>

      <b-form-checkbox
      id="tax-exempt"
      v-model="taxExempt"
      name="tax-exempt"
    >
      Tax Exempt
    </b-form-checkbox>
    </div> -->
    <!-- 6.Internal Notes -->
    <div class="mb-4" v-show="this.saveMode == 'create'">
      <InputTextArea
        placeholder=""
        v-model="internalNotes"
        label="Internal notes"
        rows="4"
      />
      <p class="text-secondary small text-right">
        {{ textAreaChartCountSecond }} / 1000
      </p>
    </div>

    <!-- Save Charge -->
    <div class="pb-5">
      <b-button v-if="quoteStatus == 'saving'" variant="primary" disabled size="lg mr-2" @click="submitForm">Saving...</b-button>
      <b-button v-else variant="primary" size="lg mr-2" @click="submitForm">Save Quote</b-button>
    </div>

    <!-- Modal: Product Catalog -->
    <b-modal id="modal-product-catalog" size="xl" ok-title="Add items to quote" style="max-height: 80vh" @ok="handleAddItemsToQuote">
      <ProductList :allow-edit="false" />
    </b-modal>
  </div>
</template>

<script>
// import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import IconPlusCircle from '@/components/icons/edit/IconPlusCircle'
import InputText from '@/components/inputs/InputText'
import InputTextArea from '@/components/inputs/InputTextArea'
import { mapGetters } from 'vuex'
// import { CHARGES } from '../../constants/quote'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders, getTotal, formatter } from '../../utils'
import ProductList from '@/views/product/ProductList.vue'
import {
  required
  // between
} from 'vuelidate/lib/validators'

const defaultAdditionalCharge = {
  brand: '',
  type: '',
  description: '',
  sku: '',
  price: null,
  quantity: 1,
  taxes: []
}
export default {
  name: 'QuoteCharge',
  components: {
    // ButtonPrimary,
    InputText,
    IconPlusCircle,
    ProductList,
    InputTextArea
  },
  data () {
    return {
      getTotal,
      formatter,
      charges: [],
      additionalCharges: [],
      taxExempt: false,
      saveMode: null,
      quote: null,
      quoteStatus: null,
      internalNotes: ''
    }
  },
  computed: {
    ...mapGetters({
      customer: 'getQuoteCustomer',
      manufacturer: 'getQuoteManufacturer',
      type: 'getQuoteType',
      measurements: 'getQuoteMeasurements',
      quoteNumber: 'getQuoteNumber',
      products: 'getQuoteProducts',
      settings: 'getSettings',
      profile: 'getProfile',
      quoteCustomer: 'getQuoteCustomer',
      quoteTaxes: 'getQuoteTaxes',
      quotePartners: 'getQuotePartners'
    }),
    taxTemplate () {
      return this.quote == null ? this.settings.taxes : this.quote.taxTemplate
    },
    totalTax () {
      let total = 0

      if (this.taxExempt) {
        return total
      }

      this.quoteTaxes.forEach(item => {
        total += item.amount
      })
      return total
    },
    totalAll () {
      return this.totalProducts() + this.totalCharges() + this.totalTax
    },
    textAreaChartCountSecond () {
      if (!this.internalNotes) {
        return 0
      }
      return this.internalNotes ? this.internalNotes.length : 0
    }
  },
  async created () {
    console.log('created Router', this.$router.currentRoute)

    if (this.$router.currentRoute.name === 'QuoteDetailEdit') {
      this.saveMode = 'update'
      this.quote = await this.$store.dispatch('fetchQuote', {
        quoteId: this.$route.params.quoteId
      })
      this.internalNotes = this.quote.internalNotes
    } else if (!this.quoteCustomer) {
      this.$router.push({ name: 'QuoteSelectCustomer' })
      this.saveMode = 'create'
    }

    // this.saveMode = this.$store.state.quote.quote?.charges ? 'update' : 'create'
    // CHARGES.forEach(item => {
    //   this.charges.push({ ...item })
    // })
    if (this.saveMode === 'update') {
      // Load quote into memory
      this.quote.charges.forEach(item => {
        this.additionalCharges.push({ ...item })
      })
    }
  },
  validations: {
    additionalCharges: {
      $each: {
        price: {
          required
          // between: between(0.00, 999999)
        }
      }
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('addQuoteProduct')
      this.$store.commit('SET_QUOTE', {
        quote: {
          taxTemplate: this.taxTemplate,
          internalNotes: this.internalNotes
        },
        charges: this.additionalCharges
      })

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        alert('Item price cannot be 0.')
        return
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      }
      this.saveQuote()
    },
    saveQuote () {
      this.quoteStatus = 'saving'
      const chargesData = [
        ...this.charges,
        ...this.additionalCharges
      ]
      this.$store.dispatch('setQuoteCharges', chargesData)

      const url = `${BACKEND_API_URL}/quotes`
      const subtotal = this.totalProducts() + (this.totalCharges() || 0)
      // const taxRate = this.taxExempt ? 0 : this.settings.taxes[0].rate
      const payload = {
        // quoteNumber: this.quoteNumber,
        customer: this.customer.id,
        manufacturer: this.products && this.products[0] && this.products[0].manufacturer.name,
        accountManagerId: this.profile.id,
        subtotal,
        tax: this.totalTax,
        total: this.totalAll,
        products: this.products,
        charges: chargesData.filter(item => item.quantity >= 0),
        taxTemplate: this.settings.taxes,
        internalNotes: this.internalNotes,
        partners: this.quotePartners
      }
      const updateQuoteNumber = this.$store.state.quote?.number
      // const updateQuoteId = this.$store.state.quote.quote.id
      this.$store.dispatch('setQuote', payload)

      if (this.saveMode === 'update') {
        axios.put(url + '/' + updateQuoteNumber, payload, { headers: getHeaders() })
          .then(response => {
            this.$router.push({
              path: `/quotes/${response.data.id}`
            })
            // this.$store.dispatch('setQuote', response.data)
            this.quoteStatus = null
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
        return
      }

      axios.post(url, payload, {
        headers: getHeaders()
      })
        .then(res => {
          this.$store.dispatch('setAlert', { title: 'Save result', message: 'Quote saved', type: 'success' })
          this.$router.push({ name: 'QuoteDetail', params: { quoteId: res.data && res.data.id } })
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    totalCharges () {
      let total = 0

      this.charges.forEach(item => {
        total += item.quantity * parseFloat(item.price)
      })

      this.additionalCharges.forEach(item => {
        total += item.quantity && item.price ? item.quantity * parseFloat(item.price) : 0
      })

      return total
    },
    totalProducts () {
      let total = 0

      this.products.forEach(item => {
        item.measurements.forEach(measurement => {
          total += parseFloat(measurement.square || '0') * parseFloat(this.settings.price)
        })
      })

      return total
    },
    addProduct () {
      this.$store.dispatch('addQuoteProduct')
      this.$router.push({ name: 'QuoteSelectManufacturer' })
    },
    addAdditionalProduct () {
      const additionalCharge = { ...defaultAdditionalCharge }
      // console.log('addAdditionalProduct', this.taxTemplate.map((item) => {
      //   return item.name
      // }))
      additionalCharge.taxes = this.taxTemplate.map((item) => {
        return item.name
      })
      this.additionalCharges.push({ ...additionalCharge })
    },
    addItemFromCatalog () {
      // this.$store.dispatch('fetchProducts').then(() => {
      //   console.log('show product catalog modal')
      this.$bvModal.show('modal-product-catalog')
      // })
    },
    handleAddItemsToQuote () {
      const selectedItems = this.$store.state.product.productTable.getSelectedData()
      selectedItems.forEach(item => {
        delete item.search_field
        this.additionalCharges.push({
          ...item,
          quantity: 1,
          taxes: this.taxTemplate.map(item => {
            return item.name
          })
        })
      })
    },
    handleRemoveLineItem (index) {
      this.additionalCharges.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteCharge {

  .tax-partial {
    text-decoration: underline;
  }

  &__tableHeader {
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.17));
    position: sticky;
    background-color: $white;
    top: 0;
    left: 0;
    z-index: 10;

    border-bottom: none;
    border-right: 1px solid $secondaryBlue;

    @include mq {
      filter: none;
      border-right: none;
      border-bottom: 1px solid $secondaryBlue;
    }

    .bigHeader {
      height: 68px;

      @include mq {
        height: auto;
      }
    }
  }

  &__tableRow {
    p {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  &--link {
    cursor: pointer;
  }

  &__input {
    @include mq {
      width: 50px;
    }
  }

  .qty {
    .inputText {
      width: 100%;
      max-width: 80px;
    }
  }
  .unit-cost {
    display: flex;
    align-items: center;

    .inputText {
      // max-width: 50%;
      // margin-left: 8px;
      // margin-right: 8px;
    }
  }
}
</style>
