import UserSettings from '../views/flooring/UserSettings'
import UserProfile from '../views/flooring/UserProfile'
import Users from '../views/flooring/Users'
import ProductSettings from '../views/flooring/ProductSettings'
import CompanySettings from '../views/flooring/CompanySettings'
import RolePermissions from '../views/flooring/RolePermissions'

export default [
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      permissions: ['manage-profile']
    }
  },
  {
    path: '/user-settings',
    name: 'UserSettings',
    component: UserSettings,
    meta: {
      permissions: ['manage-settings']
    }
  },
  {
    path: '/company-settings',
    name: 'CompanySettings',
    component: CompanySettings,
    meta: {
      permissions: ['manage-company-settings']
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      permissions: ['manage-users']
    }
  },
  {
    path: '/product-settings',
    name: 'ProductSettings',
    component: ProductSettings,
    meta: {
      permissions: ['manage-product-settings']
    }
  },
  {
    path: '/role-permissions',
    name: 'RolePermissions',
    component: RolePermissions,
    meta: {
      permissions: ['manage-permissions']
    }
  }
]
