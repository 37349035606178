<template>
  <div class="customer" v-if="store">
    <HeaderMenu />

        <div
          class=" mx-lg-5 pb-5 pt-5 border-bottom"
        >
          <h2>
            Store Logo
          </h2>
          <div class="row no-gutters p-2 pt-lg-3 pl-lg-3">

            <div class="col-lg-2" v-if="store">

                <div class="mt-3 text-center mr-3">
                  <b-img :src="store.logo" style="max-height: 100px; max-width: 100px" ></b-img>
                  <!-- <p class="mt-5">
                    {{ profile.firstName }} {{ profile.lastName }}
                  </p> -->
                  <!-- <p class="mt-5">
                    <b-badge class="role-badge">{{ profile.role }}</b-badge>
                  </p> -->
                </div>
            </div>
            <div class="col-lg-10 pt-2">
              <div>Upload store logo</div>
              <!-- <div>Logo should be at least 300px x 300px</div> -->
              <a href="#" @click="confirmDelete">Remove current logo</a>
              <div class="pt-2">
                <input ref="file" id="fileUpload" type="file" accept="image/*" @change="uploadImage($event)" hidden>
                <ButtonPrimary @click="chooseFiles()">
                  Upload Logo
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>

    <div class="row no-gutters">
      <div class="col-lg-5">
        <form
          @submit.prevent="handleFormRequest"
          class="row no-gutters p-3 pt-lg-5 pl-lg-5"
          autocomplete="on"
        >

          <div class="col-lg-12">
            <!-- 1.Primary Customer -->
            <h2>
              Basic settings
            </h2>
            <!-- Field Requirements -->
<!--            <div class="row no-gutters mt-5">-->
<!--              <p class="customer__fieldRequirement">-->
<!--                Fields marked with <span>*</span> are mandatory-->
<!--              </p>-->
<!--            </div>-->
            <!-- Username -->
            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.businessName"
                      label="Business Name"
                      name="businessName"
                    />
                </div>
              </div>
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.website"
                      label="Website"
                      name="website"
                    />
                </div>
              </div>
            </div>

            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.streetAddress"
                      label="Street Address"
                      name="streetAddress"
                    />
                </div>
              </div>
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.streetAddressLine2"
                      label="Street Address (line 2)"
                      name="streetAddressLine2"
                    />
                </div>
              </div>
            </div>

            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.city"
                      label="City"
                      name="city"
                    />
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.tax"
                      label="Tax"
                      name="tax"
                    />
                </div>
              </div> -->
            </div>

            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-4">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.province"
                      label="Province/State"
                      name="province"
                    />
                </div>
              </div>
              <div class="col-md-4">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.country"
                      label="Country"
                      name="country"
                    />
                </div>
              </div>
              <div class="col-md-4">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.hstGST"
                      label="HST/GST Number"
                      name="HST/GST"
                    />
                </div>
              </div>
            </div>
            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.postalCode"
                      label="Postal Code / Zip"
                      name="postalCode"
                    />
                </div>
              </div>
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                      v-model.trim="basicSettings.phoneNumber"
                      label="Phone Number"
                      name="phoneNumber"
                    />
                </div>
              </div>
            </div>

            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputTextArea
                    v-model.trim="basicSettings.policyText"
                    label="Policy text"
                    placeholder=""
                    rows="6"
                  />
                </div>
              </div>
            </div>

            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                    label="Starting Quote Number"
                    v-model="basicSettings.startingQuoteNumber"
                    type="number"
                    step="1"
                    min="1"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="pr-md-2">
                  <InputText
                    label="Starting Order Number"
                    v-model="basicSettings.startingOrderNumber"
                    type="number"
                    step="1"
                    min="1"
                  />
                </div>
              </div>
            </div>

            <h2>Tax Settings</h2>
            <div>
              <b-table fixed responsive outlined :striped="false" :items="taxes" :fields="taxFields">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'rate' ? '60px' : '100px' }"
                  >
                </template>
                <template v-slot:cell()="{ value, item, field: { key }}">
                  <b-form-input v-model="item[key]" />
                </template>
                <template #cell(rate)="data">
                  <b-input-group append="%">
                    <b-form-input v-model="data.item.rate" />
                  </b-input-group>
                </template>
                <template #cell(actions)="row">
                  <b-button variant="danger" size="sm" @click="removeTaxItem(row.index)" class="mr-1">Delete</b-button>
                </template>
              </b-table>
              <b-button variant="info" @click="addTaxItem" class="">
                <b-icon icon="plus-circle"></b-icon> Add Tax
              </b-button>
            </div>
            <hr>

            <b-button
              variant="primary"
              size="lg"
              type="submit"
              value="Submit"
              :disabled="submitStatus === 'PENDING'"
              ref="submit"
            >
              Save
            </b-button>

            <!-- <h2>
              Prices
            </h2>
            <div class="row no-gutters mt-5 mb-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="$v.basicSettings.price.$model"
                    label="Mercier"
                    name="price"
                    required="true"
                    :status="$v.basicSettings.price.$error"
                  />
                  <div class="error" v-if="$v.basicSettings.price.$error">
                    Field is required
                  </div>
                </div>
              </div>
            </div>

            <h2>
              Taxes
            </h2>

            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="$v.basicSettings.tax.$model"
                    name="tax"
                    type="number"
                    label="Tax (HST/GST)"
                    required="true"
                    :status="$v.basicSettings.tax.$error"
                  />
                  <div class="error" v-if="$v.basicSettings.tax.$error">
                    Field is required
                  </div>
                </div>
              </div>
            </div> !-->
            <!-- 7.Submit Button -->
            <div class="row no-gutters mb-4 my-md-5">
              <div class="col-md-12">
                <div>
                  <p class="typo__p" v-if="submitStatus === 'OK'">
                    Thanks for your submission!
                  </p>
                  <p class="error" v-if="submitStatus === 'ERROR'">
                    Please fill in the form correctly.
                  </p>
                  <p class="typo__p" v-if="submitStatus === 'PENDING'">
                    Sending...
                  </p>
                </div>
              </div>
            </div>
            <!-- /7.Submit Button -->
          </div>
        </form>
      </div>
      <div class="col-lg-5">
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/inputs/InputText'
// import InputTextPlace from '@/components/inputs/InputTextPlace'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
// import {
//   required,
//   numeric
// } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import HeaderMenu from '../../components/flooring/HeaderMenu'
import InputTextArea from '../../components/inputs/InputTextArea'
// import { defaultPolicyText } from '../../constants/settings'
// import { scroller } from 'vue-scrollto/src/scrollTo'
import { mapGetters } from 'vuex'

const initialSettings = {
  policyText: '',
  price: null,
  tax: null,
  businessName: null,
  website: null,
  streetAddress: null,
  phoneNumber: null,
  city: null,
  province: null,
  hstGST: null,
  storeName: null
}

export default {
  components: {
    InputText,
    InputTextArea,
    ButtonPrimary,
    HeaderMenu
    // scroller
    // InputTextPlace
  },
  directives: { mask },
  data () {
    return {
      basicSettings: {
        ...initialSettings
      },
      submitStatus: false,
      taxes: [],
      taxFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'rate',
          label: 'Rate',
          sortable: false
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        }
      ]
    }
  },
  validations: {
    basicSettings: {
    }
  },
  computed: {
    ...mapGetters({
      store: 'getStore'
    })
  },
  created () {
    this.getSettings()
  },
  methods: {
    getSettings () {
      console.log(this.basicSettings)
      this.$store.dispatch('fetchSettings').then((res) => {
        console.log('update basicSettings', res.data)
        this.basicSettings = {
          ...res.data.storeSettings
        }
        console.log('update basicSettings after', this.basicSettings)
        this.taxes = res.data.taxes
      }).catch(e => {
        console.log(e)
        this.submitStatus = 'ERROR'
      })
    },
    handleFormRequest () {
      // console.log('validation', this.$v)
      // console.log('submit!', JSON.parse(JSON.stringify(allFormFields)))

      const payload = {
        policyText: this.basicSettings.policyText,
        prices: [
          { price: parseFloat(this.basicSettings.price) }
        ],
        // taxes: [
        //   { tax: parseFloat(this.basicSettings.tax) }
        // ],
        taxes: this.taxes.map(tax => {
          return {
            name: tax.name,
            rate: parseInt(tax.rate)
          }
        }),
        storeSettings: {
          ...this.basicSettings
        }
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        // setTimeout(() => {
        //   this.submitStatus = 'OK'
        //   this.$bvToast.toast('This is toast number 3', {
        //     title: 'BootstrapVue Toast',
        //     autoHideDelay: 5000,
        //     appendToast: true
        //   })
        // }, 500)

        this.$store.dispatch('putSettings', payload).then(() => {
          this.submitStatus = 'OK'
        })
          .then(() => {
            this.$store.dispatch('fetchStores')
          })
          .then((stores) => {
            console.log(stores)
            this.$store.dispatch('loadStoreFromSessionStorage', {
              stores
            })
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    uploadImage (event) {
      console.log('files', event.target.files)
      this.$store.dispatch('uploadStoreLogo', event.target.files[0]).then(() => {
        this.$store.dispatch('fetchStores')
      }).catch(error => {
        console.log('Error', error)
      })
    },
    chooseFiles () {
      const fileInputElement = this.$refs.file
      fileInputElement.click()
      console.log('fileInputElement', fileInputElement)
    },
    deleteLogoRequest () {
      const payload = {
        logo: null
      }

      this.$store.dispatch('updateStore', payload).then(response => {
        this.$store.dispatch('fetchStores')
      }, () => {
        this.submitStatus = 'ERROR'
      })
    },
    confirmDelete () {
      this.$bvModal.msgBoxConfirm('Are you sure to delete store logo?', {
        centered: true,
        okTitle: 'Delete'
      })
        .then(value => {
          if (value) {
            this.deleteLogoRequest()
          }
        })
        .catch(err => {
          // An error occurred
          console.log('Error', err)
        })
    },

    addTaxItem () {
      this.taxes.push({
        name: '',
        rate: ''
      })
    },

    removeTaxItem (index) {
      this.taxes.splice(index, 1)
    }
  },
  updated () {

  }
}
</script>

<style lang="scss" scoped>
.customer {
  h1 {
    border-bottom: 1px solid #cedde7;
  }

  &__fieldRequirement {
    span {
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: $error;
    }
  }

  &__dropdown {
    position: relative;
    color: $green;
    cursor: pointer;

    span {
      position: absolute;
      font-size: 32px;
      font-weight: 300;
      top: 50%;
      transform: translate(13px, -50%);
      color: $orange;
    }
  }

  &__chartCounter {
    color: $gray;
  }

  /deep/ .v-select {
    filter: none;
  }
}

.inputText {
  position: relative;

  &__error {
    position: absolute;
    color: #c11b0f;
    font-size: 32px;
    line-height: 0.8;
  }

  &__label {
    color: $gray;
  }

  &__error + &__label {
    padding-left: 15px;
  }

  &__input {
    display: flex;
    width: 100%;
    background: $white;
    color: #a19f9f;
    border: 1px solid #00818f;
    border-radius: 10px;
    outline: none;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    padding: 13px 16px;
    margin: 8px 0px;
  }
}
</style>
