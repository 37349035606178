<template>
  <div class="quoteList">
    <div class="container-fluid">
      <!-- Quote Header -->
      <div class="quoteList__header row pt-6 pl-3 pr-5 pb-4 align-items-center justify-content-between">
        <!-- Header: Title -->
        <div class="col-md-4">
          <h1 class="text-dark">Quotes</h1>
        </div>
        <!-- Header: Date -->
        <div class="quoteList__date col-md-3 d-flex justify-content-md-end">
          <div class="d-flex align-items-center mt-4 mt-md-0"
            v-b-toggle="'collapseOrderDate'"
          >
            <div class="breadcrumbLink">{{ customDateRange.start | formatDate }} - {{ customDateRange.end | formatDate }}</div>
            <span class="ml-4 d-flex">
              <IconChevronDown />
            </span>
          </div>
        </div>
        <!-- Header: Date Collapse -->
        <b-collapse
          id="collapseOrderDate"
          class="quoteList__dateCollapse col-12 order-4 mt-6"
        >
          <div class="row">
            <!-- Calendar -->
            <!-- <div
              class="quoteList__calendar col-md-4 d-flex flex-wrap"
            >
              <b-calendar locale="en-US" class="mr-4"></b-calendar>
              <b-calendar locale="en-US" class="ml-4" v-model="miniCalendar"></b-calendar>
            </div> -->
            <!-- Choose Date Range -->
            <div class="quoteList__dateRange col-md-12">
              <div class="row">
                <div class="col-3 mb-4">
                  <h4 class="text-secondary mb-2">
                    Date Range
                  </h4>
                  <v-select
                    v-model="dateRangeOptionSelected"
                    @input="handleDateRangeChange"
                    :options="dateRangeOptions"
                    :key="dateRangeOptionSelected.label"
                    placeholder="Select a date range"
                  ></v-select>
                </div>
                <div class="col-3 pr-3">
                  <h4 class="text-secondary mb-2">
                    From
                  </h4>
                  <b-form-datepicker
                    size="sm"
                    v-model="customDateRange.start"
                    @input="handleDateChange"
                    dropright
                  ></b-form-datepicker>
                </div>
                <div class="col-3 pl-3">
                  <h4 class="text-secondary mb-2">
                    To
                  </h4>
                  <b-form-datepicker
                    size="sm"
                    v-model="customDateRange.end"
                    @input="handleDateChange"
                    dropright
                  ></b-form-datepicker>
                </div>
                <!-- <div class="col-3 mb-4 px-3">
                  <h4 class="text-secondary mb-2">
                    To
                  </h4>
                  <ButtonPrimary @click="handleClickConfirmDateRange">
                    Apply
                  </ButtonPrimary>
                  <ButtonSecondary class="border-0">
                    Cancel
                  </ButtonSecondary>
                </div> -->
              </div>

            </div>
          </div>
        </b-collapse>
      </div>
      <!-- Quote List -->
      <div class="row mt-2 mb-5 mr-1 mr-md-6 overflow-auto">
        <table class="table">
          <!-- Header -->
          <thead>
            <tr>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Quote number</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'quoteNumber'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'quoteNumber'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Date</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'createdAt'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'createdAt'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Customer</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'customer.firstName'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'customer.firstName'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Phone</h4>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Brand</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'manufacturer'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'manufacturer'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Partners</h4>
                  <span class="d-flex flex-column">
                    <div class="sort-arrow">
                      <IconCaretUp />
                    </div>
                    <div class="sort-arrow">
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Acc Man</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'accountManager.firstName'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'accountManager.firstName'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Total</h4>
                  <span class="d-flex flex-column">
                    <div
                      class="sort-arrow"
                      @click=";(name = 'total'), (direction = 'ASC')"
                    >
                      <IconCaretUp />
                    </div>
                    <div
                      class="sort-arrow"
                      @click=";(name = 'total'), (direction = 'DESC')"
                    >
                      <IconCaretDown />
                    </div>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <!-- Rows | Data -->
          <tbody>
            <tr v-for="(item, index) in quotes" :key="index">
              <th scope="row" class="breadcrumbLink">
                <!-- <router-link
                  :to="{
                    name: 'OrderDetail',
                    params: { orderId: item.order && item.order.id }
                  }"
                  class="text-dark"
                  v-if="item.order"
                >
                  {{ item.quoteNumber }}
                </router-link> -->
                <router-link
                  :to="{
                    name: 'QuoteDetail',
                    params: { quoteId: item.id }
                  }"
                  class="text-dark"
                >
                  {{ item.quoteNumber }}
                </router-link>
              </th>
              <td>
                <p class="small">{{ formatDate(item.createdAt) }}</p>
              </td>
              <td class="breadcrumbLink">
                <router-link
                  :to="{
                    name: 'CustomerDetail',
                    params: { customerId: item.customer && item.customer.id }
                  }"
                  class="text-dark"
                >
                  {{ getCustomerFullName(item.customer) }}
                  <div v-if="item.customer && item.customer.company">
                    <p class="small">{{ item.customer && item.customer.company.name }}</p>
                  </div>
                </router-link>
              </td>
              <td>
                <p class="small">{{ getCustomerPhone(item.customer) }}</p>
              </td>
              <td>
                <p class="small">{{ getManufacturerTitle(item) }}</p>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <router-link
                    v-for="partner in item.partners" :key="partner.id"
                    :to="{ name: 'PartnerEdit', params: { partnerId: partner.id }}"
                  >
                    {{ partner.partnerCompanyName }}
                  </router-link>
                </div>
              </td>
              <td>
                <p class="small" v-if="item.customer && item.customer && item.customer.accountManager">
                  {{ item.customer && item.customer.accountManager.firstName }} {{ item.customer && item.customer.accountManager.lastName }}
                </p>
              </td>
              <td class="text-right">
                <p class="small">{{ formatter.format(item.total) }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="row text-center"
        v-if="quotes && pagination.totalCount > quotes.length"
      >
        <div class="col-12 pb-4">
          <div @click="loadMore()">
            <ButtonSecondary class="ml-3">Load More</ButtonSecondary>
          </div>
        </div>
      </div>
      <!-- Quote Stats -->
      <div class="mb-4">
        <h4 class="text-secondary">Quote Count: <span v-html="quotesCount"></span> -- Total Amount: {{ quotesTotal }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import ButtonSecondary from '@/components/buttons/ButtonSecondary'
import IconCaretUp from '@/components/icons/arrow/IconCaretUp'
import IconCaretDown from '@/components/icons/arrow/IconCaretDown'
import IconChevronDown from '@/components/icons/arrow/IconChevronDown'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { mapGetters } from 'vuex'
import { MANUFACTURERS } from '../../constants/quote'
import { getHeaders, formatter } from '../../utils'

dayjs.extend(utc)

export default {
  components: {
    IconCaretUp,
    IconCaretDown,
    IconChevronDown,
    ButtonSecondary
  },
  data () {
    return {
      formatter,
      manufacturers: MANUFACTURERS,
      page: 1,
      pageSize: 50,
      searchStatus: null,
      name: 'id',
      direction: 'DESC',
      totalCount: 0,
      // statuses: ORDER_STATUSES,

      miniCalendar: null,
      customDateRange: {
        start: dayjs().startOf('month').format(),
        end: dayjs().format()
      },
      dateRangeOptionSelected: null,
      dateRangeOptions: [
        {
          label: 'Custom',
          start: null,
          end: null
        },
        {
          label: 'Today',
          start: dayjs().startOf('day'),
          end: dayjs().endOf('day')
        },
        {
          label: 'Yesterday',
          start: dayjs().startOf('day').subtract(1, 'day'),
          end: dayjs().endOf('day').subtract(1, 'day')
        },
        {
          label: 'Last Week',
          start: dayjs().subtract(1, 'week').startOf('week'),
          end: dayjs().subtract(1, 'week').endOf('week')
        },
        {
          label: 'Last Month',
          start: dayjs().subtract(1, 'month').startOf('month'),
          end: dayjs().subtract(1, 'month').endOf('month')
        },
        {
          label: 'Last 7 Days',
          start: dayjs().subtract(7, 'day'),
          end: dayjs()
        },
        {
          label: 'Last 30 Days',
          start: dayjs().subtract(30, 'day'),
          end: dayjs()
        },
        {
          label: 'Month to Date (MTD)',
          start: dayjs().startOf('month'),
          end: dayjs().endOf('day')
        },
        {
          label: 'Year to Date (YTD)',
          start: dayjs().startOf('year'),
          end: dayjs()
        }
      ]
    }
  },
  filters: {
    formatDate (date) {
      return dayjs(date).format('MMMM D, YYYY')
    }
  },
  computed: {
    ...mapGetters({
      quotes: 'getQuotes',
      pagination: 'getQuotePagination',
      keyword: 'getKeyword'
    }),
    quotesCount () {
      return this.quotes.length
    },
    quotesTotal () {
      // Create our number formatter.
      const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
      })

      const total = this.quotes.reduce((total, quote) => {
        return total + parseFloat(quote.total)
      }, 0)

      return formatter.format(total)
    }
  },
  created () {
    EventBus.$on('search', payload => {
      this.page = 1
      this.getQuotes()
    })

    this.dateRangeOptionSelected = this.dateRangeOptions[8]
    this.handleDateRangeChange(this.dateRangeOptionSelected)
    // this.getQuotes()
  },
  methods: {
    getQuotes (append = false) {
      const params = {
        name: this.name,
        dir: this.direction,
        page: this.page,
        limit: this.pageSize,
        q: this.keyword,
        date_start: this.customDateRange.start,
        date_end: this.customDateRange.end
      }
      const queryParams = new URLSearchParams(params)
      const url = `${BACKEND_API_URL}/quotes${
        queryParams ? '?' + queryParams : ''
      }`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch(
            append ? 'appendQuotes' : 'setQuotes',
            res.data
          )
          this.totalCount = res.totalCount
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    getCustomerFullName (customer) {
      let fullName = ''

      if (customer) {
        fullName = `${customer.firstName} ${customer.lastName}`
      }

      return fullName
    },
    getCustomerPhone (customer) {
      let phone = ''

      if (customer) {
        phone = customer.mobilePhone || customer.homePhone || ''
      }

      return phone
    },
    getManufacturerTitle (item) {
      if (item.charges && item.charges.length) {
        return item.charges[0].brand
      }

      return ''
      // const manufacturer = this.manufacturers.find(item => item.name === name)

      // return manufacturer ? manufacturer.title : ''
    },
    formatDate (date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    formatWeek () {
      return `${dayjs().startOf('week').format('MMMM DD, YYYY')} - ${dayjs().format('MMMM DD, YYYY')}`
    },
    handleSearch () {
      this.page = 1
      this.getQuotes()
    },
    loadMore () {
      this.page++
      this.getQuotes(true)
    },
    // getStatusName (name) {
    //   const status = this.statuses.find(item => name ? item.name === name : item.name === 'empty')
    //   return status ? status.name : 'empty'
    // },
    // getStatusTitle (name) {
    //   const status = this.statuses.find(item => name ? item.name === name : item.name === 'empty')
    //   return status.name === 'empty' ? 'No Payment' : status.title
    // },
    handleDateRangeChange (newDateRangeOption) {
      if (newDateRangeOption.start == null) {
        this.customDateRange.start = dayjs().startOf('month').format()
      } else {
        this.customDateRange.start = newDateRangeOption.start.format()
      }
      if (newDateRangeOption.end == null) {
        this.customDateRange.end = dayjs().format()
      } else {
        this.customDateRange.end = newDateRangeOption.end.format()
      }
      // else {
      //   this.customDateRange.start = newDateRangeOption.start.format('YYYY-MM-DD')
      //   this.customDateRange.end = newDateRangeOption.end.format('YYYY-MM-DD')
      // }
      this.getQuotes()
    },
    handleDateChange (date) {
      this.handleDateRangeChange({
        label: 'Custom',
        start: dayjs(this.customDateRange.start),
        end: dayjs(this.customDateRange.end)
      })
    },
    handleClickConfirmDateRange () {
      this.getQuotes()
    }
  },
  watch: {
    keyword: function () {
      this.handleSearch()
    },
    name: function (val) {
      this.getQuotes()
    },
    direction: function (val) {
      this.getQuotes()
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteList {
  &__searchIcon {
    font-size: 24px;

    /deep/ * {
      fill: $orange;
    }
  }

  &__date {
    span {
      font-size: 24px;
      cursor: pointer;

      /deep/ svg * {
        fill: $orange;
      }
    }
  }

  &__dateCollapse {
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;

      /deep/ svg * {
        fill: $orange;
      }

      &.left {
        left: 10px;
      }
      &.right {
        right: 10px;
      }
    }
  }

  &__calendar {
    /deep/ .b-calendar {
      &-grid {
        background-color: $grayBg;
        border-radius: 20px;
        padding: 0 19px 29px;
      }

      &-header {
        display: none;
      }

      // &-nav {
      //   display: none !important;
      // }
    }
  }

  &__dateRange {
    /deep/ .b-form-datepicker {
      height: 50px;
      border: 1px solid $green;
      border-radius: 10px;

      .b-calendar-header {
        display: none;
      }
      // button {
      //   visibility: hidden;
      //   width: 1px;
      // }

      label {
        white-space: nowrap;
        overflow: hidden;
        line-height: 42px;
      }
    }
  }

  .table {
    thead {
      background-color: $green;
      color: $white;
      max-height: 50px !important;
    }

    td[scope='row'] {
      font-size: 13px;
      line-height: 13px;
      a {
        color: $green !important;
        font-weight: bold;
        font-size: 13px;
        font-family: Raleway;
      }
    }

    th[scope='col'] {
      padding: 0 24px;
      min-height: 50px !important;
      height: 50px;
    }

    tr td {
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px !important;
      min-height: 50px !important;
      height: 50px;
    }
  }

  .sort-arrow {
    line-height: 10px;
  }

  .search-block {
    position: relative;

    @include mq {
      padding-left: 30px;
    }
    // margin-top: 36px;

    input {
      border-radius: 10px;
      padding: 13px 8px 13px 16px;
      border: 1px solid #00818f;
      height: 50px;
    }

    span {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 15px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
      /deep/ svg * {
        fill: $red;
      }
    }
  }
  a {
    color: $green !important;
    font-weight: bold;
    font-size: 13px;
    font-family: Raleway;
  }
}
</style>
