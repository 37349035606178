<template>
  <div class="mx-auto" style="max-width: 1000px; font-size: 14px;">
    <table width="100%">
      <thead>
        <div style="height: 240px;" class="thead-container">
          <b-row>
            <b-col cols="5">
              <div id="branding" class="pt-6">
                <b-row>
                  <b-col cols="4">
                    <LogoGeneric style="font-size: 1em; width: 120px;"/>
                  </b-col>
                  <b-col>
                    <h3 v-if="$store.state.settings.settings.storeSettings">{{ $store.state.settings.settings.storeSettings.businessName }}</h3>
                    <p v-if="$store.state.settings.settings.storeSettings">
                      <span>{{ $store.state.settings.settings.storeSettings.streetAddress }}</span><br>
                      <span v-if="$store.state.settings.settings.storeSettings.streetAddressLine2">{{ $store.state.settings.settings.storeSettings.streetAddressLine2 }}<br></span>
                      <span>{{ $store.state.settings.settings.storeSettings.city}}, {{ $store.state.settings.settings.storeSettings.province}}</span>
                      <span>, {{ $store.state.settings.settings.storeSettings.postalCode }}</span> <span v-if="$store.state.settings.settings.storeSettings.country">{{ $store.state.settings.settings.storeSettings.country }}</span><br>
                      <span v-if="$store.state.settings.settings.storeSettings.email">E: {{ $store.state.settings.settings.storeSettings.email }}<br></span>
                      <span>P: {{ $store.state.settings.settings.storeSettings.phoneNumber}}<br></span>
                      <span v-if="$store.state.settings.settings.storeSettings.website">{{ $store.state.settings.settings.storeSettings.website }}</span>
                    </p>
                  </b-col>
                </b-row>
              </div>

            </b-col>
            <b-col cols="7">
              <div class="print__customer p-6">
                <!-- 1.Customer Title -->
                <h3 class="mb-4">
                  Order prepared for
                </h3>
                <div class="print__profile mt-4 w-100">
                  <b-row>
                    <b-col>
                      <h4 class="mb-3">
                        <div v-if="customer && customer.company && customer.company.useAsName">{{ customer.company.name }}</div>
                        <div class="mt-2">{{ customer && customer.firstName }} {{ customer && customer.lastName }}</div>
                      </h4>

                      <div>
                        <div class="" v-if="customer && customer.homePhone">
                          <div>
                            <span class="font-weight-bold">H:</span> <span>{{ customer && customer.homePhone }}</span>
                          </div>
                        </div>
                        <div class="" v-if="customer && customer.mobilePhone">
                          <div>
                            <span class="font-weight-bold">M:</span> <span>{{ customer && customer.mobilePhone }}</span>
                          </div>
                        </div>
                        <div class="" v-if="customer && customer.email">
                          <div>
                            <span class="font-weight-bold">E:</span> <span>{{ customer && customer.email }}</span>
                          </div>
                        </div>
                      </div>

                    </b-col>
                    <b-col>
                      <!-- 3.Customer Shipping Address -->
                      <div class="print__address">
                        <h4 class="mb-3">Address</h4>
                        <div v-if="customer && customer.billingAddress">
                          {{ customer.billingAddress.street }}
                        </div>
                        <div v-if="customer && customer.billingAddress">
                          {{ customer.billingAddress.city }}, {{ customer.billingAddress.stateOrProvince }}<span v-if="customer && customer.billingAddress">, {{ customer.billingAddress.postal }}</span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <!-- 4.Customer Profile -->
                <div class="print__profile mt-4 w-100">
                  <b-row>
                    <b-col cols="6">
                      <h4>
                        Account manager
                      </h4>
                      <div v-if="customer && customer.accountManager">
                        <div>{{ customer.accountManager.firstName }} {{ customer.accountManager.lastName }}</div>
                        <div>{{ customer.accountManager.email }}</div>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <h4 class="mb-3">Date</h4>
                      <div>{{ formatDate(order.createdAt, 'MMM DD, YYYY') }}</div>
                    </b-col>
                  </b-row>
                </div>
              </div>

            </b-col>
          </b-row>
        </div>
      </thead>
      <tr>
        <td class="p-20">
          <table class="print__top " width="100%">
            <!-- Print Table -->
            <tr class="print__table ">
              <td width="50%" class="py-4">
                <h3 class="print--green font-weight-bold">
                  Order #: {{ order && order.orderNumber }} <span v-if="order.poNumber"> / P.O. # {{ order.poNumber }}</span>
                </h3>
              </td>
              <td align="right" v-if="order && order.installedAt">
                <!-- <div class="print--gray">Installation date: {{ order && order.installedAt && formatDate(order.installedAt, 'MMMM DD, YYYY')}}</div> -->
              </td>
            </tr>
          </table>
          <!-- Print Table -->
          <table class="print__table print__top" width="100%" style="border-collapse: collapse;">
            <!-- 1.1 Header -->
            <thead>
              <tr>
                <td class="pt-6"></td>
              </tr>
              <tr class="print__borderBottom pb-12">
              <td width="25%">
                <h4 class="print--blue">
                  Product
                </h4>
              </td>
              <td width="35%">
                <h4 class="print--blue">
                  Description
                </h4>
              </td>
              <td width="15%" class="text-right">
                <h4 class="print--blue">
                  Price
                </h4>
              </td>
              <td width="10%" class="text-right">
                <h4 class="print--blue">
                  Unit
                </h4>
              </td>
              <td width="15%" align="right">
                <h4 class="print--blue">
                  Sub Total
                </h4>
              </td>
            </tr>
            </thead>
            <tr
              class="print__borderBottom pt-4 pb-2 print__line_item"
              v-for="(item) in order.lineItems"
              :key="item.id"
            >
              <td>
                <p class="print--gray">
                  <span v-if="item.brand">{{ item.brand }} <br></span>
                  <span v-if="item.type">{{ item.type }} <br></span>
                  <span v-if="item.sku">{{ item.sku }}</span>
                </p>
              </td>
              <td class="pr-3">
                <p class="print--gray"  style="white-space: pre-wrap;">
                  {{ item.description }}
                </p>
              </td>
              <td class="text-right">
                <p class="print--gray">
                  {{ formatter.format(parseFloat(item.price)) }}
                </p>
              </td>
              <td class="text-right">
                <p class="print--gray">
                  {{ item.quantity }}
                </p>
              </td>
              <td align="right">
                <p class="print--gray">
                  {{ formatter.format(item.quantity * parseFloat(item.price)) }}
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td class="p-20">
          <table class="print__top" width="100%" style="border-collapse: collapse;">
            <!-- Print Total Cost -->
            <tr class="print__borderBottom pt-3 pb-3">
              <td class="" align="right">
                <table class="print__top" width="30%">
                  <tr class="print__cost">
                    <td class="py-1">
                      Subtotal
                    </td>
                    <td class="py-1" align="right">
                      {{ formatter.format(order.subTotal) }}
                    </td>
                  </tr>
                  <tr class="print__cost" v-for="(taxAmount, taxName) in order.taxBreakdown" :key="taxName">
                    <td class="py-1">
                      {{ taxName }} ({{ taxMap[taxName] }}%)
                      <!-- Tax HST/GST {{ order && order.quote && parseFloat(`${order.quote.tax || 0}`) }}% -->
                    </td>
                    <td class="py-1" align="right">
                      {{ formatter.format(taxAmount) }}
                      <!-- {{ order && order.quote && formatter.format(parseFloat(order.quote.tax) * parseFloat(order.quote.subtotal) / 100) }} -->
                    </td>
                  </tr>
                  <tr class="print__cost font-weight-bold">
                    <td class="print--blue py-1">
                      <span class="print--blue">Total:</span>
                    </td>
                    <td class="print--blue py-1" align="right">
                      <span class="print--blue">{{ formatter.format(order.total) }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="p-20">
          <table class="print__top" width="100%">
            <!-- Print Total Cost -->
            <tr class="print__cost" v-for="(item, index) in order && order.payments" :key="index">
              <td class="">
                Payment - {{item.type}} on {{formatDate(item.createdAt, 'MMMM DD, YYYY')}}
              </td>
              <td class="" align="right">
                {{ formatter.format(item.amount) }}
              </td>
            </tr>
            <tr class="print__cost">
              <td style="padding-top: 20px">
                Balance Due
              </td>
              <td style="padding-top: 20px" align="right">
                {{ order && formatter.format(order.due || 0) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="order && order.quote && order.quote.notes">
        <td class="p-20">
          <table class="print__table print__top" width="100%" style="border-collapse: collapse;">
            <tr class="print__borderBottom pt-3 pb-3">
              <td colspan="5">
                <h3 class="print--green pt-6 pb-4 mt-5">Notes:</h3>
                <p class="print--black pb-4" style="white-space: pre-wrap; font-size: 0.8rem">
                  {{ order && order.quote && order.quote.notes }}
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="order && order.notes">
        <td class="p-20">
          <table class="print__table print__top" width="100%" style="border-collapse: collapse;">
            <tr class="print__borderBottom pt-3 pb-3">
              <td colspan="5">
                <h3 class="print--green pt-6 pb-4 mt-5">Notes:</h3>
                <p class="print--black pb-4">
                  {{ order && order.notes }}
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td style="padding-top: 60px;">
          <table class="print__top" width="100%">
            <!-- Print Info And Signature -->
            <tr class="print__bottom mt-auto">
              <td width="50%"></td>
              <td width="50%" valign="top" style="padding-top: 5px">
                <table class="print__table print__top" width="100%" style="border-collapse: collapse; padding-top: 20px;">
                  <tr class="print__signature print__bottom mt-auto">
                    <td width="100%" style="padding-top: 10px">Signature</td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- Policy -->
            <tr>
              <td class="align-top" colspan="2">
                <div class="mt-5">
                  <!-- <p class="semi-bold print--green">
                    Thank you for shopping at {{ STORE_TITLE }}
                  </p> -->
                  <p class="store-policy">{{ settings.policyText }}</p>
                  <div class="mt-5">
                    <p v-if="$store.state.settings.settings.storeSettings">
                      Tax # {{ $store.state.settings.settings.storeSettings.hstGST }}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <footer class="text-center w-100">
      <p class="text-muted" v-html="POWERED_BY"></p>
    </footer>
  </div>
</template>

<script>
// import LogoFlooringAndHome from '../../components/logos/LogoFlooringAndHome.vue'
import LogoGeneric from '../../components/logos/LogoGeneric'
import { BACKEND_API_URL, STORE_TITLE, POWERED_BY } from '../../constants/default'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { formatDate, formatter, getHeaders } from '../../utils'

export default {
  components: {
    LogoGeneric
  },
  data () {
    return {
      STORE_TITLE,
      POWERED_BY,
      formatter,
      formatDate,
      paymentVisible: false,
      customerNotes: '',
      internalNotes: '',
      status: ''
    }
  },
  computed: {
    ...mapGetters({
      order: 'getOrder',
      // charges: 'getQuoteCharges',
      settings: 'getSettings',
      customer: 'getQuoteCustomer',
      store: 'getStore',
      taxMap: 'getTaxMap'
    }),
    avatar () {
      let value = ''
      if (this.customer?.accountManager?.avatar) {
        value = "'" + this.customer.accountManager.avatar + "'"
      }
      return value
    },
    productList () {
      return this.order?.quote?.products ? this.order?.quote?.products?.map(item => ({
        ...item,
        price: this.settings.price,
        totalSquare: this.getTotal(item.measurements, 'square'),
        totalPrice: this.getTotal(item.measurements, 'square') * this.settings.price
      })) : []
    },
    textAreaChartCount () {
      if (this.customerNotes === 0) {
        return 0
      }
      return this.customerNotes.length
    },
    textAreaChartCountSecond () {
      if (this.internalNotes === 0) {
        return 0
      }
      return this.internalNotes.length
    }
  },
  created () {
    this.getOrder()
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    getTotal (items = [], field) {
      let total = 0

      Array.isArray(items) && items.forEach(item => {
        total += item[field] ? parseFloat(item[field]) : 0
      })

      return total
    },
    format (value) {
      return Math.round(value * 100) / 100
    },
    getOrder () {
      const url = `${BACKEND_API_URL}/orders/${this.$route.params.orderId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(async (res) => {
          await Promise.all([
            this.$store.dispatch('setOrder', res.data),
            this.status = res.data && res.data.status,
            this.$store.dispatch('setQuoteCustomer', res.data?.quote?.customer),
            this.$store.dispatch('setQuoteCharges', res.data?.quote?.charges),
            this.$store.dispatch('fetchStores')
          ])
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
        .then(() => {
          this.$store.dispatch('loadStoreFromSessionStorage', [])
        })
        .then(() => {
          const img = new Image()
          img.src = this.store.logo
          img.onload = () => {
            console.log(process.env.VUE_APP_ENV_DEV)
            if (typeof process.env.VUE_APP_ENV_DEV === 'undefined') {
              window.onafterprint = window.close
              window.print()
            }
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.thead-container {
  max-height: 230px;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;
}
.thead-container * {
  font-size: 0.8rem;
}
@page {
  size: 8.5in 11in;
}

@media print {
  footer {
    position: fixed;
    bottom: 0;
  }
  .content-block, p {
    page-break-inside: avoid;
  }
  html, body {
    width: 210mm;
    height: 297mm;
  }
}

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 1rem;
  vertical-align: baseline;
  background: transparent;
}

td {
  p, div {
    font-size: 1rem;
  }
}

.print__line_item td {
  padding: 14px 0;
}

.print {
  height: 100vh;

  &__table,
  &__cost,
  &__bottom {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__borderBottom {
    border-bottom: 1px solid $secondaryBlue;
  }

  &--green {
    color: $green;
  }

  &--blue {
    color: $blue;
  }

  &--gray {
    color: $gray;
  }

  &--black {
    color: $black;
  }

  &__logo {
    padding-top: 25px;

    padding-left: 20px;

    span {
      margin-right: 21px;
    }

    p {
      font-size: 0.8em;
      line-height: 13px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $gray;
    }
  }

  &__customer {
    padding-left: 20px;
    background-color: $grayBg;

    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 1em;
      line-height: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Green */
      color: #00818f;
      margin-bottom: 5px;
    }

    h4 {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      // font-size: 0.8em;
      // line-height: 13px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;

      > * {
        font-size: 1rem;
      }
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-size: 0.8em;
      line-height: 13px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Secondary/Accessible Grey */
      color: #707070;
    }
  }

  &__profile {
    h4 {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 12px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Green */
      // color: #00818f;
      color: black;
      margin-bottom: 11px;
    }

    img {
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }

    p {
      font-weight: normal;
      // line-height: 9px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }
  }

  &__table {
    h4 {
      font-weight: 600;
      font-size: 1.2em;
      line-height: 1.2em;
      height: 1.8em;
    }

  }

  &__cost {
    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      // font-size: 9px;
      line-height: 12px;
      text-align: right;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }

    h2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Blue 1 */
      color: #004d8b;
    }
  }

  &__bottom {
    padding-bottom: 38px;

    p.semi-bold {
      font-weight: 600;
    }

    p {
      // font-family: Raleway;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }

  &__signature {
    border-top: 1px solid #1d1c1c;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      // font-size: 9px;
      line-height: 12px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      /* Primary/Black */
      color: #1d1c1c;
    }
  }
}
</style>
