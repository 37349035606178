<template>
  <div class="card">
    <div class="d-flex justify-content-center card__logo">
      <img src="@/static/logos/logoStacked.svg" class="mr-3" alt="" />
    </div>
    <div class="card__form">
      <form @submit.prevent="handleFormRequest">
        <h3 class="w-100 text-center card__title">Set New Password</h3>

        <div class="form-group">
          <InputText
            v-model="password"
            type="password"
            label="New password"
            required="true"
            :status="$v.password.$error"
          />
          <div v-if="$v.password.$error">
            <div class="error" v-if="!$v.password.required">
              Field is required
            </div>
            <div class="error" v-if="!$v.password.minLength">
              Password must be greater than 6 characters
            </div>
          </div>

          <InputText
            v-model="password_confirm"
            type="password"
            label="Confirm new password"
            required="true"
            :status="$v.password_confirm.$error"
          />
          <div v-if="$v.password_confirm.$error">
            <div class="error" v-if="!$v.password_confirm.required">
              Field is required
            </div>
            <div class="error" v-if="!$v.password_confirm.sameAsPassword">
              Passwords do not match
            </div>
          </div>
        </div>

        <ButtonPrimary type="submit" class="btn btn-dark btn-lg btn-block">Update Password</ButtonPrimary>

      </form>

      <!-- <div class="card__footer">
        <div>
          New to Build CRM? <router-link to="/signup">Create an account</router-link>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import InputText from '@/components/inputs/InputText'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { getHeaders } from '../../utils'

export default {
  components: {
    ButtonPrimary,
    InputText
  },
  data () {
    return {
      password: null,
      password_confirm: null,
      token: this.$route.query.token
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    password_confirm: {
      required,
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    handleFormRequest () {
      const url = `${BACKEND_API_URL}/auth/reset-password`
      const payload = {
        token: this.token, // password reset token
        password: this.password // new password
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'

        axios.post(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            this.$router.push('/login')
          })
          .catch(e => {
            alert(e.response.data.error)
            this.submitStatus = 'ERROR'
          })
      }
    }
  }
}
</script>

<style lang="scss">
  .card {
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 630px;
    padding: 24px;

    &__logo {
      padding-top: 10px;
      padding-bottom: 30px;
      border-bottom: 1px solid #CEDDE7;

      img {
        max-width: 100px;
      }
    }

    &__title {
      font-size: 24px;
      color: #707070;
      margin-bottom: 24px;
    }

    &__form {
      padding: 25px 5% 25px;
    }
    &__footer {
      border-top: 1px solid #CEDDE7;
      padding: 20px 0 0;
    }
  }
  .inputText {
    position: relative;

    &__error {
      position: absolute;
      color: #c11b0f;
      font-size: 32px;
      line-height: 0.8;
    }

    &__label {
      color: $secondaryGray;
    }

    &__error + &__label {
      padding-left: 15px;
    }

    &__input {
      display: flex;
      width: 100%;
      background: $white;
      color: #a19f9f;
      border: 1px solid #00818f;
      border-radius: 10px;
      outline: none;

      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;

      padding: 13px 16px;
      margin: 8px 0px;
    }
  }
</style>
