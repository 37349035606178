<template>
  <div class="quoteSelectType px-3 px-md-5">
    <Accordion
      :id="type.name"
      v-for="(type, index) in types"
      :key="type.id"
      :disabled="index > 0 ? !!!values[types[index - 1].name] : false"
      :visible="index > 0 ? !!values[types[index - 1].name] : false"
    >
      <template v-slot:header>
        {{ type.title }}
      </template>
      <template v-slot:body>
        <div class="pb-2" v-for="item in type.items" :key="item.id">
          <input
            type="radio"
            :value="item.title"
            :id="item.name"
            v-model="values[type.name]"
            :disabled="item.disabled"
          />
          <label :for="item.name">{{ item.title }}</label>
        </div>
      </template>
    </Accordion>

    <!-- Save Selected Types -->
    <div class="mb-5">
      <ButtonPrimary class="w-200" @click="submit">
        Save
      </ButtonPrimary>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import Accordion from '@/components/common/Accordion'
import { TYPES } from '../../constants/quote'
import { mapGetters } from 'vuex'

export default {
  name: 'QuoteSelectManufacturer',
  components: { ButtonPrimary, Accordion },
  data () {
    return {
      types: TYPES,
      values: {}
    }
  },
  computed: {
    ...mapGetters({
      quoteNumber: 'getQuoteNumber'
    })
  },
  created () {
    if (!this.quoteNumber) {
      this.$router.push({ name: 'QuoteSelectCustomer' })
    }
  },
  methods: {
    submit () {
      const lastType = this.types[this.types.length - 1]

      if (this.values[lastType.name]) {
        this.$store.dispatch('setQuoteType', this.values)
        this.$router.push({ name: 'QuoteMeasurement' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteSelectType {
  input[type='radio'] + label {
    color: $gray;
    padding-left: 8px;
    cursor: pointer;
  }

  input[type='radio']:disabled + label {
    color: $disabledText;
  }
}
</style>
