import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const state = () => ({
  products: [],
  pagination: {},
  productTable: null
})

const getters = {
  // getOrder (state) {
  //   return state.order
  // },
  getProducts (state) {
    return state.products
  }
  // getOrderPagination (state) {
  //   return state.pagination
  // }
}

const actions = {
  fetchProducts ({ commit }, payload) {
    // const queryParams = new URLSearchParams(payload.params)
    const url = `${BACKEND_API_URL}/products`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data) {
          commit(payload.append ? 'APPEND_PRODUCTS' : 'SET_PRODUCTS', res.data)
          commit('SET_PRODUCTS_TOTAL', res.totalCount)
        }
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  }
  // setOrder (context, payload) {
  //   context.commit('SET_ORDER', payload)
  // },
  // setOrders (context, payload) {
  //   context.commit('SET_ORDERS', payload)
  // },
  // appendOrders (context, payload) {
  //   context.commit('APPEND_ORDERS', payload)
  // }
}

const mutations = {
  // SET_ORDER (state, payload) {
  //   state.order = payload
  // },
  SET_PRODUCTS (state, payload) {
    const { data, ...pagination } = payload
    state.products = data || []
    state.pagination = pagination || {}
  },
  SET_PRODUCT_TABLE (state, payload) {
    state.productTable = payload
  }
  // APPEND_ORDERS (state, payload) {
  //   const { data, ...pagination } = payload
  //   state.orders = data ? [...state.orders, ...data] : []
  //   state.pagination = pagination || {}
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
