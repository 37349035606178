<template>
  <header class="header" v-if="stores && currentStore" >
    <b-navbar
      toggleable="lg"
      class=" row no-gutters align-items-center pl-4 p-0"
    >
      <!-- Hamburger Menu -->
      <div class="order-1 order-lg-1 col-8 col-lg-auto">
        <b-navbar-toggle target="nav-collapse">
          <IconHamburger />
        </b-navbar-toggle>
      </div>
      <!-- Search -->
      <div
        v-if="isShowSearchBar"
        class="pr-4 pr-lg-0 pl-lg-32 order-3 order-lg-2 col-12 col-md-8 col-lg-3"
      >
        <div class="header__search">
          <b-form-input
            v-model="search"
            @keyup="handleSearch"
            type="text"
            placeholder="Search"
          />
          <span @click="handleSearch">
            <IconSearch />
          </span>
        </div>
      </div>
      <!-- Profile -->

      <div
        class="order-4 order-lg-3 col-12 col-md-4 col-lg-auto py-3 ml-auto pr-4 pr-lg-0 mr-lg-5"
      >
        <div
          class="header__profile d-flex align-items-center justify-content-between justify-content-md-end"
        >
          <!-- <Language class="px-0 pr-md-4" /> -->
          <div class="image d-none d-lg-flex pr-4">
            <b-avatar :src="profile.avatar" size="41px"></b-avatar>
          </div>
          <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div class="name d-flex">
                <h3 class="align-self-center">{{profile.firstName}}</h3>
                <IconChevronBigDown class="ml-1" />
              </div>
            </template>
            <b-dropdown-item @click="handleUserSettings">User Settings</b-dropdown-item>
            <b-dropdown-item :key="store.id" v-for="store in stores" :variant="currentStore.id == store.id ? 'primary' : 'outline-primary'" @click="switchStore(store.id)">
              {{ store.name }}
            </b-dropdown-item>
            <!-- <div class="order-3" v-if="currentStore">
              <b-button class="mr-2" :key="store.id" v-for="store in stores" :variant="currentStore.id == store.id ? 'primary' : 'outline-primary'" @click="switchStore(store.id)">Switch to {{ store.name }}</b-button>
            </div> -->
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="handleLogout">Log Out</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!-- Logo -->
      <div
        class="header__logo order-2 order-lg-4 col-3 col-sm-2 col-lg-auto d-flex align-self-stretch"
        v-if="currentStore"
        style="width: 66px; height: 66px;"
      >
          <LogoGeneric class="m-auto" />
      </div>
    </b-navbar>
  </header>
</template>

<script>
import IconChevronBigDown from '@/components/icons/arrow/IconChevronBigDown'
import IconHamburger from '@/components/icons/menu/IconHamburger'
import IconSearch from '@/components/icons/edit/IconSearch'
// import LogoFlooringAndHome from '@/components/logos/LogoFlooringAndHome'
import { mapGetters } from 'vuex'
import LogoGeneric from '@/components/logos/LogoGeneric'

// import Language from '@/components/language/Language'

export default {
  components: {
    IconChevronBigDown,
    IconHamburger,
    IconSearch,
    // LogoFlooringAndHome,
    LogoGeneric
    // Language
  },
  data () {
    return {
      search: '',
      isShowSearchBar: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
      storeId: 'getStoreId',
      currentStore: 'getStore',
      stores: 'getStores'
    })
  },
  methods: {
    handleSearch () {
      this.$store.dispatch('setKeyword', this.search)
    },
    handleLogout () {
      this.$store.dispatch('logoutUser')
    },
    handleUserSettings () {
      this.$router.push({ name: 'UserProfile' })
    },
    switchStore (storeId) {
      this.$store.dispatch('switchStore', {
        storeId: storeId
      })
    }
  },
  mounted () {
    this.$store.dispatch('setKeyword', '')
    this.search = ''
    this.isShowSearchBar = this.$router.currentRoute.name ===
      'PartnerList' || 'CustomerList' || 'QuoteList' || 'OrderList' || 'ProductList'

    if (!(this.profile && this.profile.email)) {
      this.$store.dispatch('fetchStores')
        .then((stores) => {
          this.$store.dispatch('loadStoreFromSessionStorage', {
            stores
          })
          return stores
        })
        .then((stores) => {
          this.$store.dispatch('fetchProfile').then((profile) => {
            const sessionStoreId = window.sessionStorage.getItem('storeId')
            if (!sessionStoreId) {
              const targetStoreId = profile.storeId ? profile.storeId : stores[0].id
              window.sessionStorage.setItem('storeId', targetStoreId)
              this.$store.dispatch('switchStore', {
                storeId: targetStoreId
              })
            }
          })
        })
    }
  },
  watch: {
    $route (v) {
      this.search = ''
      this.$store.dispatch('setKeyword', '')
      this.isShowSearchBar = v.name === 'PartnerList' || 'CustomerList' || 'QuoteList' || 'OrderList' || 'ProductList'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: $secondaryBlue;
  filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.17));
  z-index: 100;

  &__search {
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;

    input {
      background-color: $grayBg;
      box-shadow: 5.44973087310791px 5.44973087310791px 3.6331534385681152px 0px
        #aeaec026 inset;
      border-radius: 10px;
      padding: 13px 10px;
    }

    span {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }

      /deep/ svg * {
        fill: $green;
      }
    }
  }

  &__profile {
    .image {
      border-radius: 50%;
    }

    .name {
      font-size: 24px;
      color: $blue;
    }

  }

  &__logo {
    background-color: white;
    padding: 5px 10px;

    @include mq('sm') {
      padding: 1% 3%;
    }

    @include mq('lg') {
      padding: 0;
    }
  }
}
</style>
