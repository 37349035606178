export const BACKEND_API_URL = process.env.VUE_APP_BACKEND_API || 'https://buildcrm-backend.eba-vii78mq6.us-east-2.elasticbeanstalk.com:3000'
// http://d3an4gd7bzn6hf.cloudfront.net/login?redirect=%2F
// export const BACKEND_API_URL = process.env.VUE_APP_BACKEND_API || 'http://localhost:3000'
export const STORE_ID = process.env.VUE_APP_STORE_ID || ''
export const STORE_TITLE = process.env.VUE_APP_STORE_TITLE || ''
export const POWERED_BY = process.env.VUE_APP_POWERED_BY || 'Software by BuildCRM Inc. &trade;'

export const ROLES = [
  {
    name: 'admin',
    title: 'Admin',
    description: 'The person(s) who serve as the primary contact for the account. They can invite and remove users, manage Store Settings and set the levels of authority. They can add or edit Customers, Products, Pricing and Orders and Quotes.'
  },
  {
    name: 'manager',
    title: 'Manager',
    description: 'Allows a user  to manage Store Settings, and their own profile. They cannot invite users. They can add or edit Customers, Products, Pricing, Orders and Quotes.'
  },
  {
    name: 'member',
    title: 'Member',
    description: 'Allows a user to manage their own profile. They cannot invite new users or see Store Settings. They can add or edit Customers, Products, Pricing and Orders and Quotes.'
  }
]
