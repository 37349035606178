import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const state = () => ({
  partners: [],
  partnersTotal: 0,
  partner: Object
})

const getters = {
  getPartners (state) {
    return state.partners
  },
  getPartner (state) {
    return state.partner
  }
}

const actions = {
  setPartners (context, payload) {
    context.commit('SET_PARTNERS', payload)
  },
  addPartner (context, payload) {
    context.commit('ADD_PARTNER', payload)
  },
  fetchPartners ({ commit }, payload) {
    const queryParams = new URLSearchParams(payload.params)
    const searchUrL = payload.searchField === 'companyName' ? 'by-name' : ''
    const url = `${BACKEND_API_URL}/partners/${searchUrL}${
      payload.params ? '?' + queryParams : ''
    }`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data) {
          commit('SET_PARTNERS', res.data)
          commit('SET_PARTNERS_TOTAL', res.totalCount)
        }
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  },
  fetchPartner ({ commit }, payload) {
    const url = `${BACKEND_API_URL}/partners/${payload}`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data) {
          commit('SET_PARTNER', res.data)
        }
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  }
}

const mutations = {
  SET_PARTNERS (state, payload) {
    state.partners = payload || []
  },
  SET_PARTNERS_TOTAL (state, payload) {
    state.partnersTotal = payload || 0
  },
  ADD_PARTNER (state, payload) {
    state.partners.push(payload)
  },
  SET_PARTNER (state, payload) {
    state.partner = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
