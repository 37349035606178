export const MANUFACTURERS = [
  { id: 1, name: 'karndean', title: 'Karndean', image: 'logoBoxKarndean.png', active: false },
  { id: 2, name: 'craft', title: 'Craft', image: 'craft.png', active: false },
  { id: 3, name: 'mercier', title: 'Mercier', image: 'mercier.png', active: true },
  { id: 4, name: 'torlys', title: 'Torlys', image: 'torlys.png', active: false },
  { id: 5, name: 'lauzon', title: 'Lauzon', image: 'lauzon.png', active: false },
  { id: 6, name: 'mohawk', title: 'Mohawk', image: 'mohawk.png', active: false },
  { id: 7, name: 'beaulieu', title: 'Beaulieu', image: 'beaulieu.png', active: false },
  { id: 8, name: 'evoke', title: 'Evoke', image: 'evoke.png', active: false },
  { id: 9, name: 'abode', title: 'Abode', image: 'abode.png', active: false },
  { id: 10, name: 'kentwood', title: 'Kentwood', image: 'kentwood.png', active: true }
]

export const TYPES = [
  {
    id: 1,
    name: 'flooring',
    title: 'Select Flooring',
    items: [
      { id: '1.1', name: 'Hardwood', title: 'Hardwood' }
    ]
  },
  {
    id: 2,
    name: 'species',
    title: 'Species',
    items: [
      { id: '2.1', name: 'AmericanWalnut', title: 'American Walnut' },
      { id: '2.2', name: 'HardMaple', title: 'Hard Maple' },
      { id: '2.3', name: 'Hickory', title: 'Hickory' },
      { id: '2.4', name: 'RedOak', title: 'Red Oak' },
      { id: '2.5', name: 'WhiteAsh', title: 'White Ash' },
      { id: '2.6', name: 'WhiteOak', title: 'White Oak' },
      { id: '2.7', name: 'YellowBirch', title: 'Yellow Birch' }
    ]
  },
  {
    id: 3,
    name: 'lookGrade',
    title: 'Look / Grade',
    items: [
      { id: '3.1', name: 'Authentic', title: 'Authentic' },
      { id: '3.2', name: 'Distinction', title: 'Distinction' },
      { id: '3.3', name: 'PRO', title: 'PRO' },
      { id: '3.4', name: 'RQ', title: 'R&Q', disabled: true },
      { id: '3.5', name: 'SelectBetter', title: 'Select & Better' }
    ]
  },
  {
    id: 4,
    name: 'platform',
    title: 'Platform',
    items: [
      { id: '4.1', name: '1/2', title: 'Engineered 1/2”' },
      { id: '4.2', name: '3/4', title: 'Engineered 3/4”' },
      { id: '4.3', name: 'Solid', title: 'Solid' }
    ]
  },
  {
    id: 5,
    name: 'collection',
    title: 'Collection',
    items: [
      { id: '5.1', name: 'Atmosphere', title: 'Atmosphere' },
      { id: '5.2', name: 'Design', title: 'Design +' },
      { id: '5.3', name: 'Elegancia', title: 'Elegancia', disabled: true },
      { id: '5.4', name: 'Element', title: 'Element', disabled: true },
      { id: '5.5', name: 'Herringbone', title: 'Herringbone', disabled: true },
      { id: '5.6', name: 'Naked', title: 'Naked', disabled: true },
      { id: '5.7', name: 'Origins', title: 'Origins' },
      { id: '5.8', name: 'PRO', title: 'PRO', disabled: true }
    ]
  },
  {
    id: 6,
    name: 'gloss',
    title: 'Gloss',
    items: [
      { id: '6.1', name: 'Matte', title: 'Matte' },
      { id: '6.2', name: 'MatteBrished', title: 'Matte-Brished' },
      { id: '6.3', name: 'Satin', title: 'Satin' }
    ]
  },
  {
    id: 7,
    name: 'width',
    title: 'Width',
    items: [
      { id: '7.1', name: '2 1/4”', title: '2 1/4”' },
      { id: '7.2', name: '3 1/4”', title: '3 1/4”' },
      { id: '7.3', name: '4 1/4”', title: '4 1/4”' },
      { id: '7.4', name: '5”', title: '5”' },
      { id: '7.5', name: '6 1/2”', title: '6 1/2”' },
      { id: '7.6', name: '8 1/2”', title: '8 1/2”' }
    ]
  },
  {
    id: 8,
    name: 'finish',
    title: 'Finish',
    items: [
      { id: '8.1', name: 'MercierGenerations', title: 'Mercier Generations' }
    ]
  }
]

export const CHARGES = [
  { id: 1, quantity: 12, unitCost: 20, title: 'Removal Carpet on Stairs' },
  { id: 2, quantity: 12, unitCost: 20, title: 'Install regular steps (wall to wall)' },
  { id: 3, quantity: 12, unitCost: 20, title: 'Install steps with stringers' },
  { id: 4, quantity: 12, unitCost: 20, title: 'Install Pie shaped steps (no stringers)' },
  { id: 5, quantity: 12, unitCost: 20, title: 'Removal Carpet on Stairs' },
  { id: 6, quantity: 12, unitCost: 20, title: 'Install regular steps (wall to wall)' },
  { id: 7, quantity: 12, unitCost: 20, title: 'Install steps with stringers' }
]

export const ITEM_PRICE = 0
