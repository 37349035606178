import ProductList from '../views/product/ProductList.vue'
// import OrderDetail from '../views/order/OrderDetail.vue'
// import OrderPrint from '../views/order/OrderPrint'

export default [
  {
    path: '/products',
    name: 'ProductList',
    component: ProductList
  }
  // {
  //   path: '/orders/:orderId',
  //   name: 'OrderDetail',
  //   component: OrderDetail
  // },
  // {
  //   path: '/orders/:orderId/print',
  //   name: 'OrderPrint',
  //   component: OrderPrint
  // }
]
