import { defaultPolicyText } from '../../constants/settings'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const state = () => ({
  settings: {
    policyText: defaultPolicyText,
    price: 0,
    tax: 13
  },
  roles: [],
  allPermissions: [],
  permissions: {},
  storeId: null,
  stores: [],
  keyword: ''
})

const getters = {
  getSettings (state) {
    return state.settings
  },
  getRoles (state) {
    return state.roles
  },
  getPermissions (state) {
    return state.permissions
  },
  getAllPermissions (state) {
    return state.allPermissions
  },
  getStoreId (state) {
    return state.storeId
  },
  getStore (state) {
    return state.stores.find(store => store.id === parseInt(state.storeId))
  },
  getStores (state) {
    return [...state.stores].sort((a, b) => {
      return a.id - b.id
    })
  },
  getKeyword (state) {
    return state.keyword
  }
}

const actions = {
  setKeyword (context, payload) {
    context.commit('SET_KEYWORD', payload)
  },
  setSettings (context, payload) {
    context.commit('SET_SETTINGS', payload)
  },
  fetchSettings ({ commit }) {
    return new Promise((resolve, reject) => {
      const url = `${BACKEND_API_URL}/settings`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          if (res.data) {
            const data = {
              ...res.data,
              policyText: res.data.policyText,
              tax: res.data.taxes[0]?.tax
            }
            commit('SET_SETTINGS', data)
            resolve(res)
          }
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
          reject(e)
        })
    })
  },
  putSettings ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const url = `${BACKEND_API_URL}/settings`
      axios
        .post(url, payload, {
          headers: getHeaders()
        })
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  fetchPermissions ({ commit }) {
    const url = `${BACKEND_API_URL}/permissions`
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then(res => {
        if (res.data) {
          commit('SET_PERMISSIONS', res.data.data)
          commit('SET_ALL_PERMISSIONS', res.data.permissions)
          commit('SET_ROLES', res.data.roles)
        }
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  },
  savePermissions ({ commit }, payload) {
    const url = `${BACKEND_API_URL}/permissions/role/${payload.role}`
    axios
      .post(url, payload.permissions, {
        headers: getHeaders()
      })
      .then(res => {
        console.log('res', res)
      })
      .catch(e => {
        this.submitStatus = 'ERROR'
      })
  },

  fetchStores ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      const url = `${BACKEND_API_URL}/stores`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          if (res.data) {
            commit('SET_STORES', {
              stores: res.data.data
            })
            resolve(res.data.data)
          }
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    })
  },

  switchStore ({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      window.sessionStorage.storeId = payload.storeId
      commit('SET_STORE_ID', payload)
      dispatch('updateUser', {
        id: rootState.auth.profile.id,
        storeId: payload.storeId
      }).then(() => {
        window.location.reload()
        resolve()
      })
    })
  },
  loadStoreFromSessionStorage ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const storeId = window.sessionStorage.storeId
      console.log('loadStoreFromSessionStorage', storeId)
      if (storeId) {
        commit('SET_STORE_ID', { storeId })
        resolve()
      } else if (payload.stores.length > 0) {
        // dispatch('switchStore', { storeId: payload.stores[0].id }).then(() => {
        resolve()
        // })
      }
    })
  },

  uploadStoreLogo (context, payload) {
    const url = `${BACKEND_API_URL}/auth/store-logo`

    const data = new FormData()
    data.append('name', 'logo')
    data.append('file', payload)

    const config = {
      headers: getHeaders()
    }
    console.log('payload', payload)
    console.log('data', data)
    return axios.post(
      url,
      data,
      config
    )
  },

  updateStore ({ state }, payload) {
    const url = `${BACKEND_API_URL}/stores/${state.storeId}`
    return axios.patch(
      url,
      payload,
      {
        headers: getHeaders()
      }
    )
  }
}

const mutations = {
  SET_KEYWORD (state, payload) {
    state.keyword = payload
  },
  SET_SETTINGS (state, payload) {
    state.settings = {
      ...payload
    }
  },
  SET_PERMISSIONS (state, payload) {
    state.permissions = payload
  },
  SET_ALL_PERMISSIONS (state, payload) {
    state.allPermissions = payload
  },
  SET_ROLES (state, payload) {
    state.roles = payload
  },

  SET_STORE_ID (state, payload) {
    state.storeId = payload.storeId
  },

  SET_STORES (state, payload) {
    state.stores = payload.stores
    state.stores.forEach(store => {
      if (!store.logo) {
        store.logo = 'https://craftypixels.com/placeholder-image/100x100/e6e6e6/6e6e6e&text=No+Image'
      }
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
