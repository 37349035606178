<template>
  <div class="partner">
    <h1 class="px-3 py-3 px-md-5 py-md-6">
      {{ this.$route.params.partnerId ? 'Edit' : 'Add New' }}  Partner
    </h1>
    <!-- Field Requirements -->
    <div class="px-3 px-md-5 row no-gutters">
      <p class="partner__fieldRequirement">
        Fields marked with <span>*</span> are mandatory
      </p>
    </div>
    <form
      @submit.prevent="handleFormRequest"
      class="row no-gutters p-3 pt-lg-5 pl-lg-5"
    >
      <div class="col-lg-10">
        <!-- Partner Company Name | Main Phone | Account number -->
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="pr-md-2">
              <InputText
                label="Partner Company name"
                required="true"
                v-model.trim="primaryPartner.partnerCompanyName"
                name="partnerCompanyName"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="px-md-2">
              <InputText
                label="Main phone number"
                v-model.trim="primaryPartner.mainPhone"
                v-mask="['###-###-####']"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="pl-md-2">
              <InputText
                label="Account number"
                v-model.trim="primaryPartner.accountNumber"
              />
            </div>
          </div>
        </div>
        <!-- Address -->
        <div class="row no-gutters mt-5">
          <div class="col-md-6">
            <div class="pr-md-2">
              <label for="">
                <h3 class="inputText__label">
                  Addres&zwnj;s
                </h3>
              </label>
              <input
                type="text"
                ref="place_addres_2"
                @input="initPlaceAutocompleteAddress"
                v-model.trim="primaryPartner.address"
                class="inputText__autoAddress"
                autocomplete="chrome-off"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-2">
              <InputText
                label="Unit, etc"
                v-model.trim="primaryPartner.unit"
              />
            </div>
          </div>
        </div>
        <!-- Zip Code | City | State -->
        <div class="row no-gutters mt-5">
          <div class="col-md-3">
            <div class="pr-md-2">
              <InputText
                label="Zip/Postal code"
                v-model="primaryPartner.postal"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="px-md-2">
              <div class="inputText">
                <label for="">
                  <h3 class="inputText__label">
                    City
                  </h3>
                </label>
                <input
                  type="text"
                  ref="place_city_2"
                  @input="initPlaceAutocomplete"
                  class="inputText__input"
                  autocomplete="chrome-off"
                  v-model.trim="primaryPartner.city"
                />
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="pl-md-2">
              <div class="inputText">
                <label for="">
                  <h3 class="inputText__label">
                    State/Province
                  </h3>
                </label>
                <input
                  type="text"
                  ref="place_state_2"
                  @input="initPlaceAutocomplete"
                  class="inputText__input"
                  autocomplete="chrome-off"
                  v-model.trim="primaryPartner.stateOrProvince"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Type | Shipping Terms | Credit Terms -->
        <div class="row no-gutters align-items-stretch mt-5">
          <div class="col-md-3">
            <div class="pr-md-2">
              <InputSelect
                label="Type"
                placeholder="Select"
                v-model="primaryPartner.type"
              >
                <option value="Architect">Architect</option>
                <option value="Contractor">Contractor</option>
                <option value="Custom Builder">Custom Builder</option>
                <option value="Designer">Designer</option>
                <option value="Distributor">Distributor</option>
                <option value="Installer">Installer</option>
                <option value="Manufacturer">Manufacturer</option>
                <option value="Other">Other</option>
                <option value="Shipper">Shipper</option>
              </InputSelect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="px-md-2">
              <InputTextArea
                label="Shipping Terms"
                v-model="primaryPartner.shippingTerms"
                class="shipping__terms"
              />
              <p class="partner__chatCounter small text-left">
                {{ textAreaChatCountShippingTerm }} / 1000
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pl-md-2">
              <InputSelect
                label="Credit terms"
                placeholder="Select"
                v-model="primaryPartner.creditTerms"
              >
                <option value="C.O.D">C.O.D</option>
                <option value="Net 15">Net 15</option>
                <option value="Net 30">Net 30</option>
                <option value="Net 60">Net 60</option>
                <option value="Net 90">Net 90</option>
                <option value="1%-15, Net 30">1%-15, Net 30</option>
                <option value="1%-20, Net 30">1%-20, Net 30</option>
                <option value="2%-30, Net 30">2%-30, Net 30</option>
                <option value="2%-30, Net 30">2%-30, Net 30</option>
                <option value="See Partner Notes">See Partner Notes</option>
              </InputSelect>
            </div>
          </div>
        </div>
        <!-- Notes -->
        <div class="row no-gutters my-5">
          <div class="col-md-12">
            <div>
              <InputTextArea
                label="Internal Notes"
                v-model="primaryPartner.notes"
              />
              <p class="partner__chatCounter small text-left">
                {{ textAreaChatCount }} / 1000
              </p>
            </div>
          </div>
        </div>
        <div v-for="(additionalContact, index) in additionalContacts" :key="index">
          <!-- First Additional Contact -->
          <div class="row no-gutters flex-column mt-4">
            <h3
              v-if="additionalContact.status === false"
              @click="handleContact(index, true)"
              class="partner__dropdown"
            >
              {{ index === 0 ? 'Contact' : 'Additional Contact' }}
              <span>+</span>
            </h3>
            <h3
              v-else
              @click="handleContact(index, false)"
              class="partner__dropdown"
            >
              Remove Contact
              <span>-</span>
            </h3>
          </div>
          <transition name="fade">
            <div v-if="additionalContact.status">
              <!-- Additional Contact Name And Last Name -->
              <div class="row no-gutters mt-5">
                <div class="col-md-6">
                  <div class="pr-md-2">
                    <InputText
                      name="firstName"
                      required="true"
                      label="First Name"
                      v-model.trim="additionalContact.firstName"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="pl-md-2">
                    <InputText
                      name="lastName"
                      label="Last Name"
                      v-model.trim="additionalContact.lastName"
                    />
                  </div>
                </div>
              </div>
              <!-- Additional Contact Email And Title -->
              <div class="row no-gutters mt-5">
                <div class="col-md-6">
                  <div class="pr-md-2">
                    <InputText
                      name="email"
                      label="Email"
                      type="email"
                      v-model.trim="additionalContact.email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="pl-md-2">
                    <InputText
                      name="title"
                      label="Title"
                      v-model.trim="additionalContact.title"
                    />
                  </div>
                </div>
              </div>
              <!-- Additional Contact Mobile phone number And Direct line -->
              <div class="row no-gutters mt-5">
                <div class="col-md-6">
                  <div class="pr-md-2">
                    <InputText
                      name="mobilePhone"
                      label="Mobile Phone Number"
                      v-mask="['###-###-####']"
                      v-model.trim="additionalContact.mobilePhone"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="pl-md-2">
                    <InputText
                      name="directLine"
                      label="Direct Line"
                      v-mask="['### ### ####']"
                      v-model.trim="additionalContact.directLine"
                    />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- Save Contact -->
        <div class="row no-gutters mb-4 my-md-5">
          <div class="col-md-12">
            <ButtonPrimary
              type="submit"
              value="Submit"
              :disabled="submitStatus === 'PENDING'"
              ref="submit"
            >
              Save
            </ButtonPrimary>
            <ButtonWarning
              v-if="this.$route.params.partnerId"
              type="button"
              :disabled="submitStatus === 'PENDING'"
              class="ml-4"
              @click="handleModal"
            >
              Delete Partner
            </ButtonWarning>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      v-model="modalShow"
      size="md"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <template #default="{ hide }">
        <div class="p-3 text-secondary">
          <div class="modal__content row mb-4">
            <div class="col-12">
              <p class="text-secondary text-center">
                Are you sure you want to delete the Partner?
              </p>
            </div>
          </div>
          <div class="modal__footer d-flex align-items-center justify-content-around">
            <ButtonPrimary @click="handleDeletePartner">
              Yes
            </ButtonPrimary>
            <ButtonWarning @click="hide()">
              Cancel
            </ButtonWarning>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import InputText from '@/components/inputs/InputText'
import InputSelect from '@/components/inputs/InputSelect'
import InputTextArea from '@/components/inputs/InputTextArea'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import ButtonWarning from '@/components/buttons/ButtonWarning'
import { mask } from 'vue-the-mask'
import { required } from 'vuelidate/lib/validators'
import { getHeaders } from '../../utils'
import { BACKEND_API_URL } from '../../constants/default'

const initialState = {
  additionalContacts: [
    {
      status: false,
      firstName: null,
      lastName: null,
      email: null,
      title: null,
      mobilePhone: null,
      directLine: null
    }
  ],

  primaryPartner: {
    partnerCompanyName: null,
    mainPhone: null,
    accountNumber: null,
    address: null,
    unit: null,
    postal: null,
    stateOrProvince: null,
    city: null,
    type: null,
    creditTerms: null,
    shippingTerms: null,
    notes: null
  }
}

export default {
  components: {
    InputText,
    InputSelect,
    InputTextArea,
    ButtonPrimary,
    ButtonWarning
  },
  directives: { mask },
  metaInfo () {
    return {
      script: [
        {
          vmid: 1,
          src:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyAvH0a8n-6cscVt6xgeRKGWAnYQH0CpIiM&libraries=places',
          async: true,
          defer: true,
          callback: () => this.initPlaceAutocomplete()
        }
      ]
    }
  },
  data () {
    return {
      primaryPartner: {
        ...initialState.primaryPartner
      },

      additionalContacts: [
        ...initialState.additionalContacts
      ],
      autocompleteInitializer1: [],
      autocompleteInitializer2: [],
      submitStatus: false,
      modalShow: false
    }
  },
  validations: {
    primaryPartner: {
      partnerCompanyName: {
        required
      }
    }
  },
  computed: {
    textAreaChatCountShippingTerm () {
      if (this.primaryPartner.shippingTerms && this.primaryPartner.shippingTerms.length === 0) {
        return 0
      }
      return this.primaryPartner.shippingTerms ? this.primaryPartner.shippingTerms.length : 0
    },
    textAreaChatCount () {
      if (this.primaryPartner.notes && this.primaryPartner.notes.length === 0) {
        return 0
      }
      return this.primaryPartner.notes ? this.primaryPartner.notes.length : 0
    }
  },
  methods: {
    handleModal () {
      this.modalShow = !this.modalShow
    },
    resetContractField (index) {
      this.additionalContacts[index].firstName = null
      this.additionalContacts[index].lastName = null
      this.additionalContacts[index].email = null
      this.additionalContacts[index].title = null
      this.additionalContacts[index].mobilePhone = null
      this.additionalContacts[index].directLine = null
    },
    handleContact (index, status) {
      if (status) {
        this.additionalContacts[index].status = status
        this.resetContractField(index)
        this.additionalContacts.push({
          status: false,
          firstName: null,
          lastName: null,
          email: null,
          title: null,
          mobilePhone: null,
          directLine: null
        })
      } else {
        this.additionalContacts.splice(index, 1)
      }
    },
    initPlaceAutocompleteAddress () {
      if (this.autocompleteInitializer1.length > 0) {
        return false
      }
      const $placeNames = Object.keys(this.$refs).filter(ref =>
        ref.startsWith('place_')
      )
      $placeNames.forEach(placeName => {
        const autocomplete = new window.google.maps.places.Autocomplete(
          this.$refs[placeName],
          {
            types: ['address'],
            fields: ['name', 'formatted_address', 'address_components'],
            componentRestrictions: { country: ['us', 'ca'] }
          }
        )
        this.autocompleteInitializer1.push(autocomplete)
        autocomplete.addListener('place_changed', () => {
          this.onChangePlace(placeName, autocomplete)
        })
      })
    },
    initPlaceAutocomplete () {
      if (this.autocompleteInitializer2.length > 0) {
        return false
      }
      const $placeNames = Object.keys(this.$refs).filter(ref =>
        ref.startsWith('place_')
      )

      $placeNames.forEach(placeName => {
        const autocomplete = new window.google.maps.places.Autocomplete(
          this.$refs[placeName],
          {
            types: placeName.includes('city') ? ['(cities)'] : ['(regions)'],
            fields: ['name', 'formatted_address'],
            componentRestrictions: { country: ['us', 'ca'] }
          }
        )
        this.autocompleteInitializer2.push(autocomplete)
        autocomplete.addListener('place_changed', () => {
          this.onChangePlace(placeName, autocomplete)
        })
      })
    },
    onChangePlace (placeName, autocomplete) {
      const place = autocomplete.getPlace()
      switch (placeName) {
        case 'place_addres_2':
          this.primaryPartner.address = place.name
          for (const component of place.address_components) {
            const componentType = component.types[0]

            switch (componentType) {
              case 'postal_code': {
                this.primaryPartner.postal = `${component.long_name}`
                break
              }

              case 'locality':
                this.primaryPartner.city = component.long_name
                this.$refs.place_city_2.value = component.long_name
                break

              case 'administrative_area_level_1': {
                this.primaryPartner.stateOrProvince = component.short_name
                this.$refs.place_state_2.value = component.short_name
                break
              }
            }
          }
          break
        case 'place_city_2':
          this.primaryPartner.city = autocomplete?.getPlace().formatted_address
          break
        case 'place_state_2':
          this.primaryPartner.stateOrProvince = autocomplete?.getPlace().formatted_address
          break
      }
    },
    handleFormRequest () {
      const contacts = []

      this.additionalContacts.forEach(additionalContact => {
        if (additionalContact.status) {
          contacts.push(additionalContact)
        }
      })

      const partnerData = {
        primaryPartner: {
          ...this.primaryPartner
        },
        contacts: [
          ...contacts
        ]
      }

      this.submitStatus = 'PENDING'
      const url = `${BACKEND_API_URL}/partners${
        this.$route.params.partnerId
          ? '/' + this.$route.params.partnerId
          : ''
      }`
      const method = this.$route.params.partnerId ? axios.put : axios.post
      method(url, partnerData, {
        headers: getHeaders()
      })
        .then(res => {
          this.$store.dispatch('addPartner', partnerData)
          this.$router.push({ name: 'PartnerList' })
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    handleDeletePartner () {
      const url = `${BACKEND_API_URL}/partners/${this.$route.params.partnerId}`
      axios
        .delete(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.handleModal()
          this.$bvToast.toast('Partner successfully Deleted!', {
            autoHideDelay: 2000,
            appendToast: true,
            solid: true,
            toaster: 'b-toaster-top-right',
            variant: 'success'
          })
          setTimeout(() => {
            this.$router.push({ name: 'PartnerList' })
          }, 2000)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    resetData () {
      this.primaryPartner = {
        ...initialState.primaryPartner
      }

      this.additionalContacts = [...initialState.additionalContacts]

      if (this.$refs.place_addres_2) this.$refs.place_addres_2.value = ''
    }
  },
  mounted () {
    if (this.$route.params.partnerId) {
      const url = `${BACKEND_API_URL}/partners/${this.$route.params.partnerId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(async (res) => {
          const { contacts, ...partnerData } = res.data
          if (res.data) {
            this.primaryPartner = { ...this.primaryPartner, ...partnerData }
            this.additionalContacts = [
              ...contacts,
              {
                status: false,
                firstName: null,
                lastName: null,
                email: null,
                title: null,
                mobilePhone: null,
                directLine: null
              }
            ]
            if (contacts.length === 0) {
              this.additionalContacts.push({
                status: false,
                firstName: null,
                lastName: null,
                email: null,
                title: null,
                mobilePhone: null,
                directLine: null
              })
            }
            if (this.$refs.place_addres_2) {
              this.$refs.place_addres_2.value = this.primaryPartner.address
            }
          }
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    } else {
      this.resetData()
    }
  },
  watch: {
    $route (v) {
      this.resetData()
    }
  }
}
</script>

<style lang="scss">
.partner {
  &__fieldRequirement {
    span {
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: $error;
    }
  }

  &__chatCounter {
    color: $gray;
  }

  &__dropdown {
    position: relative;
    color: $green;
    cursor: pointer;

    span {
      position: absolute;
      font-size: 32px;
      font-weight: 300;
      top: 50%;
      transform: translate(13px, -50%);
      color: $orange;
    }
  }

  .inputText {
    position: relative;

    &__autoAddress {
      display: flex;
      width: 100%;
      background: $white;
      color: #a19f9f;
      border: 1px solid #00818f;
      border-radius: 10px;
      outline: none;

      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;

      padding: 8px 16px;
      margin: 8px 0;
    }

    .inputText__input {
      padding: 8px 10px;
    }
  }

  .shipping__terms {
    textarea {
      height: 51px;
      padding: 0 8px;
    }
  }
}
</style>
