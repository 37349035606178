import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import customer from './modules/customer'
import quote from './modules/quote'
import order from './modules/order'
import invoice from './modules/invoice'
import ui from './modules/ui'
import settings from './modules/settings'
import user from './modules/user'
import product from './modules/product'
import partner from './modules/partner'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    customer,
    quote,
    order,
    invoice,
    auth,
    ui,
    settings,
    user,
    product,
    partner
  }
})
