<template>
  <div class="quoteMeasurement p-3 px-md-5 pt-md-6">
    <!-- Quoto Measurement Title -->
    <div class="row">
      <div class="col-12">
        <h2 class="quoteMeasurement__title text-secondary pb-6">
          Add measurements
        </h2>
      </div>
    </div>
    <form @submit.prevent="submitForm">
      <!-- 1.Quoto Measurement Square footage -->
      <div class="row flex-nowrap flex-md-wrap overflow-auto">
        <div
          class="col-6 col-sm-4 col-md-12 quoteMeasurement__table pb-md-4 row no-gutters"
        >
          <div class="col-md-3 pr-md-4 my-auto">
            <h4 class="text-info">
              Square footage
            </h4>
          </div>
          <div class="col-md-6 px-md-4 my-auto">
            <h4 class="text-info">
              Description
            </h4>
          </div>
          <div class="col-md-3 pl-md-4 my-auto">
            <h4 class="text-info">
              Cutting & waste %
            </h4>
          </div>
        </div>
        <div class="col-8 col-md-12 row pt-md-4">
          <div class="col-md-3 pr-md-4">
            <InputText
              placeholder="1200 sq ft"
              v-model="$v.form.square.$model"
              required="true"
              :status="$v.form.square.$error"
            />
          </div>
          <div class="col-md-6 px-md-4">
            <InputText
              placeholder="Basement"
              v-model="form.description"
            />
          </div>
          <div class="col-md-3 pl-md-4">
            <InputText
              placeholder="10"
              v-model="form.cutting"
            />
          </div>
        </div>
      </div>
      <!-- 2.Quoto Measurement Width And Length Of Room -->
      <div class="border-bottom pt-4">
        <div
          @click="roomSize.status = !roomSize.status"
          v-b-toggle="'collapse-1'"
          variant="primary"
        >
          <h4 class="text-primary pb-4">
            Add width and length of room
            <span
              class="quoteMeasurement__icon quoteMeasurement__icon--arrow pl-sm-4"
            >
              <IconChevronBigDown :class="{ active: roomSize.status }" />
            </span>
          </h4>
        </div>
        <b-collapse id="collapse-1" class="px-0 mt-2">
          <div class="row">
            <div class="col-md-3 pr-md-4">
              <h4 class="text-secondary">
                Length
              </h4>
              <InputText
                placeholder="12 ft"
                class="mb-4"
                v-model="$v.form.width.$model"
                :status="$v.form.width.$error"
              />
            </div>
            <div class="col-md-3 px-md-4">
              <h4 class="text-secondary">
                Width
              </h4>
              <InputText
                placeholder="10 ft"
                class="mb-4"
                v-model="$v.form.height.$model"
                :status="$v.form.height.$error"
              />
            </div>
          </div>
        </b-collapse>
      </div>
      <!-- 3.Quoto Measurement Add Another Measurement-->
      <div class="pt-4">
        <div class="row" v-for="(room, index) in rooms" :key="index">
          <div class="col-md-3 pr-md-4">
            <InputText placeholder="12 ft" :readonly="true" :value="room.square" />
          </div>
          <div class="col-8 px-md-4">
            <InputText placeholder="10 ft" :readonly="true" :value="room.description" />
          </div>
          <div class="quoteMeasurement__icon quoteMeasurement__icon--remove" @click="deleteRoom(index)">
            <IconTrashEmpty />
          </div>
        </div>
        <div>
          <h4 class="text-primary pb-4 cursor-pointer" @click="addAnother()">
            Add another measurement
            <span class="quoteMeasurement__icon pl-4">
              <IconPlus  />
            </span>
          </h4>
        </div>
      </div>
      <!-- Save Selected Measurment -->
      <div class="mb-5">
        <ButtonPrimary class="w-200" type="submit">
          Save
        </ButtonPrimary>
      </div>
    </form>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import InputText from '@/components/inputs/InputText'
import IconTrashEmpty from '@/components/icons/basic/IconTrashEmpty'
import IconChevronBigDown from '@/components/icons/arrow/IconChevronBigDown'
import IconPlus from '@/components/icons/edit/IconPlus'
import {
  numeric,
  required
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

const initialForm = {
  width: '',
  height: '',
  description: '',
  square: '',
  cutting: ''
}
export default {
  name: 'QuoteSelectManufacturer',
  components: {
    ButtonPrimary,
    InputText,
    IconTrashEmpty,
    IconChevronBigDown,
    IconPlus
  },
  data () {
    return {
      form: {
        ...initialForm
      },
      roomSize: {
        status: false
      },
      rooms: []
    }
  },
  validations: {
    form: {
      width: {
        numeric
      },
      height: {
        numeric
      },
      square: {
        required,
        numeric
      },
      cutting: {
        numeric
      }
    }
  },
  computed: {
    ...mapGetters({
      quoteNumber: 'getQuoteNumber'
    })
  },
  created () {
    if (!this.quoteNumber) {
      this.$router.push({ name: 'QuoteSelectCustomer' })
    }
  },
  methods: {
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'

        if (this.rooms.length > 0) {
          this.$store.dispatch('setQuoteMeasurements', this.rooms)
          this.$router.push({ name: 'QuoteCharge' })
        }
      } else {
        if (this.form.cutting) {
          this.form.square = parseFloat(this.form.square) + parseFloat(this.form.cutting) * parseFloat(this.form.square) / 100
        }

        this.submitStatus = 'PENDING'
        this.rooms.push(this.form)
        this.$store.dispatch('setQuoteMeasurements', this.rooms)
        this.$router.push({ name: 'QuoteCharge' })
      }
    },
    addAnother () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.rooms.push(this.form)
        this.form = { ...initialForm }
      }
    },
    deleteRoom (index) {
      this.rooms = this.rooms.filter((item, i) => i !== index)
    }
  },
  watch: {
    'form.width': function (newVal, oldVal) {
      if (this.form.width && !isNaN(this.form.width) && this.form.height && !isNaN(this.form.height)) {
        this.form.square = parseInt(this.form.width) * parseInt(this.form.height)
      }
    },
    'form.height': function (newVal, oldVal) {
      if (this.form.width && !isNaN(this.form.width) && this.form.height && !isNaN(this.form.height)) {
        this.form.square = parseInt(this.form.width) * parseInt(this.form.height)
      }
    },
    'form.square': function (newVal, oldVal) {
      if (parseInt(this.form.square) !== parseInt(this.form.width) * parseInt(this.form.height)) {
        this.form.width = ''
        this.form.height = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteMeasurement {
  &__table {
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.17));
    position: sticky;
    background-color: $white;
    top: 0;
    left: 0;
    z-index: 10;

    border-bottom: none;
    border-right: 1px solid $secondaryBlue;

    @include mq {
      filter: none;
      border-right: none;
      border-bottom: 1px solid $secondaryBlue;
    }
  }

  &__icon {
    font-size: 24px;

    /deep/ svg * {
      fill: $orange;
    }

    &--arrow {
      svg {
        transition: transform 0.5s;
      }

      .active {
        transform: rotate(-180deg);
      }
    }

    &--remove {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 8px;
      cursor: pointer;
    }
  }
}
</style>
