import InvoiceList from '../views/invoice/InvoiceList.vue'
import InvoiceDetail from '../views/invoice/InvoiceDetail.vue'
import InvoicePrint from '../views/invoice/InvoicePrint'

export default [
  {
    path: '/invoices',
    name: 'InvoiceList',
    component: InvoiceList
  },
  {
    path: '/invoice/:invoiceId',
    name: 'InvoiceDetail',
    component: InvoiceDetail
  },
  {
    path: '/invoice/:invoiceId/print',
    name: 'InvoicePrint',
    component: InvoicePrint
  }
]
