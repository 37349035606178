<template>
  <div class="container-fluid px-0">
    <!-- Invoice Detail: Header -->
    <div
      class="invoiceDetail__header border-bottom px-3 py-4 py-md-6 px-md-5 row no-gutters"
    >
      <div class="col-md-6 mb-4 mb-md-0">
        <h1 class="">Invoice</h1>
        <h2 class="text-secondary mt-2">
          Invoice # {{ invoice && invoice.order && invoice.order.orderNumber }}
        </h2>
      </div>
      <div
        class="col-md-2 mb-4 mb-md-0 ml-auto d-flex flex-column justify-content-end"
      >
        <h4 class="text-secondary mb-2">
          Installation date
        </h4>
        <p class="text-secondary">
          {{ invoice && invoice.order && invoice.order.installedAt && formatDate(invoice.order.installedAt, 'MMMM DD, YYYY')}}
        </p>
      </div>
      <div class="col-md-3 mb-4 mb-md-0 ml-auto">
        <h4 class="text-secondary mb-2">
          Pick-up status
        </h4>
        <v-select v-model="status" :options="statuses"></v-select>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-lg-8">
        <div class="invoiceDetail__body px-5 pt-6">
          <!-- 1.Invoice Table -->
          <div class="container-fluid px-0 mb-4">
            <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto">
              <!-- 1.1 Header -->
              <div
                class="invoiceDetail__tableHeader col-5 col-sm-3 col-md-12 border-bottom pb-md-4 row no-gutters"
              >
                <div class="col-md-2 pr-md-4">
                  <h4 class="text-info">
                    Product
                  </h4>
                </div>
                <div class="col-md-4 px-md-4">
                  <h4 class="text-info">
                    Description
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-center">
                    Cost
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-right">
                    SF
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-right">
                    Sub Total
                  </h4>
                </div>
              </div>
              <!-- 1.2 Body | Rows -->
              <div
                class="orderDetail__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2"
                v-for="(item, index) in productList"
                :key="index"
              >
                <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary">
                    {{ item.manufacturer.title }}
                  </p>
                </div>
                <div class="col-md-4 px-md-4">
                  <p class="text-secondary pb-3 pb-md-0 px-3 px-md-0">
                    {{ item.type.flooring }},
                    {{ item.type.species }},
                    {{ item.type.lookGrade }},
                    {{ item.type.platform }},
                    {{ item.type.collection }},
                    {{ item.type.gloss }},
                    {{ item.type.width }},
                    {{ item.type.finish }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary text-md-center">
                    ${{ format(item.price) }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary text-md-right">
                    {{ format(item.totalSquare) }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary text-md-right">
                    ${{ format(item.totalPrice) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto mt-4">
              <div
                class="quoteInternal__tableHeader col-5 col-sm-3 col-md-12 border-bottom pb-md-4 row no-gutters"
              >
                <div class="col-md-2 px-md-4" style="padding-left: 0 !important">
                  <h4 class="text-info">
                    Qty
                  </h4>
                </div>
                <div class="col-md-4 px-md-4">
                  <h4 class="text-info">
                    Description
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-center text-info ">
                    Cost
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-right text-info ">
                    Sub Total
                  </h4>
                </div>
              </div>
              <div
                class="quoteInternal__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2"
                v-for="(item) in charges"
                :key="item.id"
              >
                <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary">
                    {{ item.quantity }}
                  </p>
                </div>
                <div class="col-md-4 px-md-4">
                  <p class="text-secondary">
                    {{ item.title }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4">
                  <p class="text-secondary text-md-center">
                    $ {{ parseFloat(item.unitCost).toFixed(2) }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4">
                </div>
                <div class="col-md-2 px-3 pl-md-4 px-md-0">
                  <p class="text-secondary text-md-right">
                    $ {{ (item.quantity * parseFloat(item.unitCost)).toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 2.Total Cost -->
          <div
            class="d-flex justify-content-between pb-2 mb-3 pb-md-4 mb-md-4"
          >
            <h2 class="text-info">
              Sub Total Cost:
            </h2>
            <h2 class="text-info">
              {{ invoice && invoice.quote && formatter.format(invoice.quote.subtotal) }}
            </h2>
          </div>
          <div
            class="d-flex justify-content-between pb-3 mb-3 pb-md-4 mb-md-4"
          >
            <h2 class="text-info">
              Tax (HST/GST)  {{ invoice && invoice.quote && parseFloat(`${invoice.quote.tax || 0}`) }}%
            </h2>
            <h2 class="text-info">
              {{ invoice && invoice.quote && formatter.format(parseFloat(invoice.quote.tax) * parseFloat(invoice.quote.subtotal) / 100) }}
            </h2>
          </div>
          <div
            class="d-flex justify-content-between pb-4 pb-md-6 mb-6 border-bottom"
          >
            <h2 class="text-info">
              Total:
            </h2>
            <h2 class="text-info">
              {{ invoice && invoice.quote && formatter.format(invoice.quote.total) }}
            </h2>
          </div>
          <!-- 3.Payments -->
          <div class="pb-6 mb-6 border-bottom">
            <h4 class="text-secondary mb-6">
              Payments
            </h4>
            <div class="my-4 d-flex justify-content-between text-secondary" v-for="(item, index) in invoice.order && invoice.order.payments" :key="index">
              <div class="d-flex align-items-baseline">
                <h4 class="pr-1">{{item.type}}</h4>
                <p>{{formatDate(item.createdAt, 'MMMM DD, YYYY')}}</p>
              </div>
              <p>{{ formatter.format(item.amount) }}</p>
            </div>

            <div class="d-flex justify-content-between">
              <h2 class="text-info">
                Balance Due
              </h2>
              <h2 class="text-info">
                {{ invoice && invoice.order && invoice.quote && formatter.format((invoice.quote.total - getTotal(invoice.order.payments || [], 'amount')) || 0) }}
              </h2>
            </div>
          </div>
          <!-- 4.Customer Notes -->
          <div class="row mb-4">
            <div class="col-sm-9">
              <h4 class="text-secondary mb-4">
                Customer notes
              </h4>
              <p class="text-secondary">
                {{ invoice && invoice.quote && invoice.quote.notes }} <br />
              </p>
            </div>
          </div>
          <!-- 5.Save Changes -->
          <div class="mb-4">
            <ButtonPrimary class="w-200 mr-4" @click="saveInvoice">
              Save
            </ButtonPrimary>
            <ButtonSecondary class="w-200" @click="editOrder">
              Edit order
            </ButtonSecondary>
          </div>
          <!-- 6.Email And Print -->
          <div class="invoiceDetail__icon d-flex mb-6">
<!--            <span class="d-flex align-items-center mr-6">-->
<!--              <div class="link">Email</div>-->
<!--              <IconMail class="ml-1" />-->
<!--            </span>-->
            <span class="d-flex align-items-center mr-6" @click="downloadPdf">
              <p class="link">Download PDF</p>
              <IconFilePdf class="ml-2" />
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 ml-auto">
        <BoxCustomerDetail />
      </div>
    </div>
    <div ref="print" class="hidden">
      <InvoicePrint />
    </div>
  </div>
</template>

<script>
import BoxCustomerDetail from '@/components/boxes/BoxCustomerDetail'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import ButtonSecondary from '@/components/buttons/ButtonSecondary'
// import IconMail from '@/components/icons/basic/IconMail'
import IconFilePdf from '@/components/icons/file/IconFilePdf'
import { mapGetters } from 'vuex'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { formatDate, formatter, getHeaders } from '../../utils'
import { INVOICE_STATUSES } from '../../constants/invoice'
import InvoicePrint from './InvoicePrint'

export default {
  name: 'LayoutDefaultQoute',
  components: {
    BoxCustomerDetail,
    ButtonPrimary,
    ButtonSecondary,
    // IconMail,
    IconFilePdf,
    InvoicePrint
  },
  data () {
    return {
      formatter,
      formatDate,
      paymentVisible: false,
      customerNotes: '',
      internalNotes: '',
      statuses: INVOICE_STATUSES.map(item => ({ code: item.name, label: item.title })),
      status: ''
    }
  },
  computed: {
    ...mapGetters({
      invoice: 'getInvoice',
      charges: 'getQuoteCharges',
      settings: 'getSettings'
    }),
    productList () {
      return this.invoice?.quote?.products ? this.invoice?.quote?.products?.map(item => ({
        ...item,
        price: this.settings.price,
        totalSquare: this.getTotal(item.measurements, 'square'),
        totalPrice: this.getTotal(item.measurements, 'square') * this.settings.price
      })) : []
    },
    textAreaChartCount () {
      if (this.customerNotes === 0) {
        return 0
      }
      return this.customerNotes.length
    },
    textAreaChartCountSecond () {
      if (this.internalNotes === 0) {
        return 0
      }
      return this.internalNotes.length
    }
  },
  created () {
    this.getInvoice()
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    getTotal (items = [], field) {
      let total = 0

      Array.isArray(items) && items.forEach(item => {
        total += item[field] ? parseFloat(item[field]) : 0
      })

      return total
    },
    format (value) {
      return Math.round(value * 100) / 100
    },
    getInvoice () {
      const url = `${BACKEND_API_URL}/invoices/${this.$route.params.invoiceId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.$store.dispatch('setInvoice', res.data)
          this.status = res.data && res.data.status
          this.$store.dispatch('setQuoteCustomer', res.data?.quote?.customer)
          this.$store.dispatch('setQuoteCharges', res.data?.quote?.charges)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    downloadPdf () {
      var elm = this.$refs.print
      var value = elm.innerHTML
      const url = `${BACKEND_API_URL}/invoices/${this.invoice.id}/pdf`
      const FileDownload = require('js-file-download')

      const payload = {
        content: value
      }

      axios.post(url, payload, {
        headers: getHeaders(),
        responseType: 'blob'
      })
        .then(response => {
          FileDownload(response.data, `Invoice-${this.invoice.order.orderNumber}.pdf`)
          console.log('res invoice', response)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    saveInvoice () {
      if (this.invoice && this.invoice.id) {
        const url = `${BACKEND_API_URL}/invoices/${this.invoice.id}`

        const payload = {
          status: this.status && this.status.code
        }

        axios.patch(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            console.log('res invoice', res)
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    editOrder () {
      this.$router.push({ name: 'OrderDetail', params: { orderId: this.invoice.order && this.invoice.order.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiceDetail {
  &--link {
    cursor: pointer;
  }

  &__icon {
    font-size: 24px;

    /deep/ svg * {
      fill: $orange;
    }
  }

  &__tableHeader {
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.17));
    position: sticky;
    background-color: $white;
    top: 0;
    left: 0;
    z-index: 10;

    border-bottom: none;
    border-right: 1px solid $secondaryBlue;

    @include mq {
      filter: none;
      border-right: none;
      border-bottom: 1px solid $secondaryBlue;
    }
  }

  &__tableRow {
    p {
      @media screen and (max-width: 667px) {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
}

</style>
