<template>
  <div class="card">
    <div class="d-flex justify-content-center card__logo">
      <img src="@/static/logos/logoStacked.svg" class="mr-3" alt="" />
    </div>
    <div class="card__form">
      <form @submit.prevent="handleFormRequest">
        <h3 class="w-100 text-center card__title">Sign Up</h3>
        <div class="w-100 text-center error mb-2">{{ error }}</div>
        <input type="hidden" name="subscriptionKey" v-model="subscriptionKey" />
        <div class="form-group">
          <InputText
            v-model="firstName"
            label="First Name"
            required="true"
            :status="$v.firstName.$error"
          />
        </div>

        <div class="form-group">
          <InputText
            v-model="lastName"
            label="Last Name"
            required="true"
            :status="$v.lastName.$error"
          />
        </div>

        <div class="form-group">
          <InputText
            v-model="email"
            label="Email address"
            required="true"
            :disabled="stripeCustomer"
            :status="$v.email.$error"
          />
        </div>

        <div style="display: flex">
          <div class="form-group" style="width: 90%;">
              <InputText
                v-model="password"
                label="Create a password"
                :type="passwordVisible ? 'text' : 'password'"
                required="true"
                :status="$v.password.$error"
              />
            </div>
          <b-icon icon="eye" class="passwordEye" @click="togglePassword" v-if="!passwordVisible"></b-icon>
          <b-icon icon="eye-slash" class="passwordEye" @click="togglePassword" v-else></b-icon>
        </div>

        <ButtonPrimary type="submit" class="btn btn-dark btn-lg btn-block"
          >Sign Up</ButtonPrimary
        >

        <p>
          By clicking Sign Up, you agree to our <b><a href="https://buildcrm.ca/tos/">Terms</a></b> and have read and
          acknowledge our <b><a href="https://buildcrm.ca/privacy-policy/">Privacy Statement</a></b>.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import InputText from '@/components/inputs/InputText'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { email, minLength, required } from 'vuelidate/lib/validators'
import { getHeaders } from '../../utils'

export default {
  components: {
    ButtonPrimary,
    InputText
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      error: null,
      subscriptionKey: this.$route.query.key,
      stripeCustomer: null,
      passwordVisible: false
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    password: {
      required,
      minLength: minLength(8)
    },
    email: {
      email,
      required
    }
  },
  created () {
    this.initializeForm()
  },
  methods: {
    initializeForm () {
      const url = `${BACKEND_API_URL}/auth/subscription`
      const payload = {
        key: this.subscriptionKey
      }

      axios
        .get(url, {
          headers: getHeaders(),
          params: payload
        })
        .then(res => {
          const names = res.data?.shipping?.name.split(' ')
          this.stripeCustomer = res.data
          this.email = res.data.email
          this.firstName = names && names.length > 0 ? names[0] : ''
        })
        .catch(error => {
          if (error.response && error.response.data) {
            console.log(error)
            alert(error.response.data.message)
            window.location.href = '/'
          }
          this.error = error.response.data.error
          this.submitStatus = 'ERROR'
        })
    },
    handleFormRequest () {
      this.error = ''
      const url = `${BACKEND_API_URL}/auth/register`
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        subscriptionKey: this.subscriptionKey
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'

        axios
          .post(url, payload, {
            headers: getHeaders()
          })
          .then(res => {
            this.login()
          })
          .catch(error => {
            if (error.response && error.response.data) {
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            }
            this.error = error.response.data.error
            this.submitStatus = 'ERROR'
          })
      }
    },

    login () {
      const url = `${BACKEND_API_URL}/auth/login`
      const payload = {
        email: this.email,
        password: this.password
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'

        axios
          .post(url, payload, {
            headers: getHeaders()
          })
          .then(res => {
            if (res.data && res.data.user) {
              this.$store.dispatch('setToken', res.data.token)
              this.$store.dispatch('setProfile', res.data.user).then(() => {
                const id = res.data && res.data.user && res.data.user.id

                if (id) {
                  window.location.href = '/'
                }
              })
            }
          })
          .catch(error => {
            console.log('e', error)
            if (error.response && error.response.data) {
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            }
            this.error = error.response.data.error
            this.submitStatus = 'ERROR'
          })
      }
    },

    togglePassword () {
      this.passwordVisible = !this.passwordVisible
    }
  }
}
</script>

<style lang="scss">
.card {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 630px;
  padding: 24px;

  &__logo {
    padding-top: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #cedde7;

    img {
      max-width: 100px;
    }
  }

  &__title {
    font-size: 24px;
    color: #707070;
    margin-bottom: 24px;
  }

  &__form {
    padding: 25px 5% 25px;
  }
  &__footer {
    border-top: 1px solid #cedde7;
    padding: 20px 0 0;
  }
}
.inputText {
  position: relative;

  &__error {
    position: absolute;
    color: #c11b0f;
    font-size: 32px;
    line-height: 0.8;
  }

  &__label {
    color: $secondaryGray;
  }

  &__error + &__label {
    padding-left: 15px;
  }

  &__input {
    display: flex;
    width: 100%;
    background: $white;
    color: #a19f9f;
    border: 1px solid #00818f;
    border-radius: 10px;
    outline: none;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    padding: 13px 16px;
    margin: 8px 0px;
  }
}
.passwordEye {
  font-size: 24px;
  margin-top: 36px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
