import Vue from 'vue'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

const initialProduct = {
  index: 0,
  type: {},
  manufacturer: {},
  measurements: []
}
const initialState = {
  customer: {},
  number: null,
  product: {
    ...initialProduct
  },
  products: [],
  quotes: [],
  charges: [],
  quote: {},
  pagination: {},
  quotePartners: []
}
const state = Vue.util.extend({}, initialState)

const getters = {
  getQuote (state) {
    return state.quote
  },
  getQuoteCustomer (state) {
    return state.customer
  },
  getQuoteNumber (state) {
    return state.number
  },
  getQuoteProduct (state) {
    return state.product
  },
  getQuoteProducts (state) {
    return state.products
  },
  getQuotePartners (state) {
    return state.quotePartners
  },
  getQuoteManufacturer (state) {
    return state.product.manufacturer
  },
  getQuoteType (state) {
    return state.product.type
  },
  getQuoteMeasurements (state) {
    return state.product.measurements
  },
  getQuotes (state) {
    return state.quotes
  },
  getQuotePagination (state) {
    return state.pagination
  },
  getQuoteCharges (state) {
    return state.charges
  },
  getQuoteTaxes (state) {
    const taxes = {}
    const taxDict = {}
    if (!state.quote.taxTemplate) {
      return []
    }
    state.quote.taxTemplate.forEach(tax => {
      taxDict[tax.name] = tax.rate
    })

    state.charges.forEach(charge => {
      if (!charge.taxes) {
        return
      }
      charge.taxes.forEach(taxName => {
        if (taxes[taxName]) {
          taxes[taxName].amount += taxDict[taxName] / 100 * parseFloat(charge.price) * charge.quantity
        } else {
          taxes[taxName] = {
            name: taxName,
            amount: taxDict[taxName] / 100 * parseFloat(charge.price) * charge.quantity,
            rate: taxDict[taxName]
          }
        }
      })
    })
    return Object.values(taxes)
  },
  getQuoteTaxMap (state) {
    const taxMap = {}
    state.quote.taxTemplate.map(tax => {
      taxMap[tax.name] = tax.rate
    })
    return taxMap
  }
}

const actions = {
  setQuote (context, payload) {
    context.commit('SET_QUOTE', payload)
  },
  setQuoteCustomer (context, payload) {
    context.commit('SET_QUOTE_CUSTOMER', payload)
  },
  setQuoteNumber (context, payload) {
    context.commit('SET_QUOTE_NUMBER', payload)
  },
  setQuoteManufacturer (context, payload) {
    context.commit('SET_QUOTE_MANUFACTURER', payload)
  },
  setQuotePartners (context, payload) {
    context.commit('SET_QUOTE_PARTNER', payload)
  },
  setQuoteType (context, payload) {
    context.commit('SET_QUOTE_TYPE', payload)
  },
  setQuoteMeasurements (context, payload) {
    context.commit('SET_QUOTE_MEASUREMENTS', payload)
  },
  addQuoteProduct (context, payload) {
    context.commit('ADD_QUOTE_PRODUCT', payload)
  },
  setQuoteProducts (context, payload) {
    context.commit('ADD_QUOTE_PRODUCTS', payload)
  },
  setQuotes (context, payload) {
    context.commit('SET_QUOTES', payload)
  },
  appendQuotes (context, payload) {
    context.commit('APPEND_QUOTES', payload)
  },
  setQuoteCharges (context, payload) {
    context.commit('SET_QUOTE_CHARGES', payload)
  },
  resetQuote (context) {
    context.commit('RESET_QUOTE')
  },
  async fetchQuote (context, payload) {
    return new Promise((resolve, reject) => {
      const url = `${BACKEND_API_URL}/quotes/${payload.quoteId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          context.commit('SET_QUOTE_NUMBER', res.data.quoteNumber)
          context.commit('SET_QUOTE_MANUFACTURER', res.data.manufacturer)
          context.commit('ADD_QUOTE_PRODUCTS', res.data.products)
          context.commit('SET_QUOTE_CHARGES', res.data.charges)
          context.commit('SET_QUOTE', res.data)
          context.commit('SET_QUOTE_CUSTOMER', res.data.customer)
          context.commit('SET_QUOTE_PARTNER', res.data.quote?.partners ?? [])
          // this.customerNotes = res.data.notes
          // this.updateKey++
          resolve(res.data)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    })
  }
}

const mutations = {
  SET_QUOTE (state, payload) {
    state.quote = payload
  },
  SET_QUOTE_CUSTOMER (state, payload) {
    state.customer = payload || {}
  },
  SET_QUOTE_NUMBER (state, payload) {
    state.number = payload || {}
  },
  SET_QUOTE_MANUFACTURER (state, payload) {
    state.product.manufacturer = payload || {}
  },
  SET_QUOTE_TYPE (state, payload) {
    state.product.type = payload || {}
  },
  SET_QUOTE_MEASUREMENTS (state, payload) {
    state.product.measurements = payload || {}
  },
  ADD_QUOTE_PRODUCTS (state, payload) {
    state.products = [...payload]
  },
  ADD_QUOTE_PRODUCT (state, payload) {
    state.products.push(state.product)
    state.product = { ...initialProduct }
    state.product.index = state.products.length
  },
  SET_QUOTE_PARTNER (state, payload) {
    state.quotePartners = payload
    if (state.quote) {
      state.quote.partners = payload
    }
  },
  SET_QUOTES (state, payload) {
    const { data, ...pagination } = payload
    state.quotes = data || []
    state.pagination = pagination || {}
  },
  APPEND_QUOTES (state, payload) {
    const { data, ...pagination } = payload
    state.quotes = data ? [...state.quotes, ...data] : []
    state.pagination = pagination || {}
  },
  SET_QUOTE_CHARGES (state, payload) {
    state.charges = [...payload]
  },
  RESET_QUOTE (state) {
    for (const f in state) {
      if (f !== 'customer') {
        Vue.set(state, f, initialState[f])
      }
    }
    state.products = []
    state.quotes = []
    state.charges = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
