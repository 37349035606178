<template>
    <div>
        <OrderDetail mode="edit" />
    </div>
</template>

<script>
import OrderDetail from './OrderDetail.vue'

export default {
  name: 'LayoutDefaultQoute',
  components: {
    OrderDetail
  },
  data () {
    return {
    }
  }
}
</script>
