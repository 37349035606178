<template>
  <div class="card">
    <div class="d-flex justify-content-center card__logo">
      <img src="@/static/logos/logoStacked.svg" class="mr-3" alt="" />
    </div>
    <div class="card__form">
      <div class="w-100 text-center mb-2">{{ message }}</div>
      <div class="w-100 text-center error mb-2">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { getHeaders } from '../../utils'

export default {
  data () {
    return {
      error: '',
      message: ''
    }
  },
  created () {
    this.acceptInvitation()
  },
  methods: {
    acceptInvitation () {
      this.error = ''
      const url = `${BACKEND_API_URL}/invitations/accept`
      const payload = {
        code: this.$route.query.code
      }

      axios.post(url, payload, {
        headers: getHeaders()
      })
        .then(res => {
          this.message = 'Account created. Please check your email for instructions. You may close this window.'
        })
        .catch(error => {
          console.log('e', error)
          if (error.response && error.response.data) {
            console.log('data', error.response.data)
            console.log('status', error.response.status)
            console.log('headers', error.response.headers)

            if (error.response.data.message === 'error.user_not_found') {
              this.error = 'User already exist.'
            } else {
              this.error = error.response.data.message
            }
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .card {
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 630px;
    padding: 24px;

    &__logo {
      padding-top: 10px;
      padding-bottom: 30px;
      border-bottom: 1px solid #CEDDE7;

      img {
        max-width: 100px;
      }
    }

    &__title {
      font-size: 24px;
      color: #707070;
      margin-bottom: 24px;
    }

    &__form {
      padding: 25px 5% 25px;
    }
    &__footer {
      border-top: 1px solid #CEDDE7;
      padding: 20px 0 0;
    }
  }
</style>
