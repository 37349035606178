<template>
  <div
    class="border-bottom d-flex align-items-md-center flex-column flex-md-row"
  >
    <h1 class="px-3 py-3 px-md-5 py-md-6 text-dark">
      {{ title }}
    </h1>
    <p class="ml-4 mt-2 text-dark" v-if="quoteNumber">
      Quote #: {{ quoteNumber }}
    </p>
    <!-- <div class="ml-auto">
      <img src="@/static/logos/logoMercier.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['title'],
  computed: {
    ...mapGetters({
      quoteNumber: 'getQuoteNumber'
    })
  }
}
</script>

<style lang="scss" scoped></style>
