
<template>
  <div class="customer">
    <HeaderMenu />
    <div
      class=" mx-lg-5 pb-5 pt-5 border-bottom"
    >
      <h2>
        Profile Photo
      </h2>
      <div class="row no-gutters p-2 pt-lg-3 pl-lg-3">

        <div class="col-lg-2">

            <div class="mt-3 text-center mr-3">
              <b-avatar :src="profile.avatar" size="75px" ></b-avatar>
              <p class="mt-5">
                {{ profile.firstName }} {{ profile.lastName }}
              </p>
              <p class="mt-5">
                <b-badge class="role-badge">{{ profile.role }}</b-badge>
              </p>
            </div>
        </div>
        <div class="col-lg-10 pt-2">
          <div>Upload your photo</div>
          <div>Photo should be at least 300px x 300px</div>
          <a href="#" @click="confirmDelete">Remove current photo</a>
          <div class="pt-2">
            <input ref="file" id="fileUpload" type="file" accept="image/*" @change="uploadImage($event)" hidden>
            <ButtonPrimary @click="chooseFiles()">
              Upload Photo
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters mt-5">
      <div class="col-lg-5">
        <form
          @submit.prevent="handleFormRequest"
          class="row no-gutters p-3 pt-lg-5 pl-lg-5"
          autocomplete="on"
        >
          <div class="col-lg-12">
            <!-- 1.Primary Customer -->
            <h2>
              Basic information
            </h2>
            <!-- Field Requirements -->
            <div class="row no-gutters mt-5">
              <p class="customer__fieldRequirement">
                Fields marked with <span>*</span> are mandatory
              </p>
            </div>
            <!-- Username -->
            <!-- <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="basicInformation.username"
                    label="Username"
                  />
                </div>
              </div>
            </div> -->
            <!-- Name And Last Name -->
            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="$v.basicInformation.firstName.$model"
                    label="First Name"
                    name="firstName"
                  />
                  <!-- <div class="error" v-if="$v.basicInformation.firstName.$error">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.basicInformation.firstName.minLength">
                    Name must have at least
                    {{ $v.basicInformation.firstName.$params.minLength.min }}
                    letters.
                  </div>!-->
                </div>
              </div>
            </div>
            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="$v.basicInformation.lastName.$model"
                    name="lname"
                    label="Last Name"
                  />
                  <!-- <div class="error" v-if="$v.basicInformation.lastName.$error">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.basicInformation.lastName.minLength">
                    Last Name must have at least
                    {{ $v.basicInformation.lastName.$params.minLength.min }}
                    letters.
                  </div> -->
                </div>
              </div>
            </div>
            <!-- Email Address -->
            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    autocomplete="on"
                    name="email"
                    type="email"
                    v-model.trim="$v.basicInformation.email.$model"
                    label="Email Address"
                    required="true"
                    :status="$v.basicInformation.email.$error"
                  />
                  <div class="error" v-if="$v.basicInformation.email.$error">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.basicInformation.email.email">
                    Please Enter Valid Email Address
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="basicInformation.website"
                    label="Website"
                  />
                </div>
              </div>
            </div> -->
            <!-- 7.Submit Button -->
            <div class="row no-gutters mb-4 my-md-5">
              <div class="col-md-12">
                <div>
                  <p class="typo__p" v-if="submitStatus1 === 'OK'">
                    Thanks for your submission!
                  </p>
                  <p class="error" v-if="submitStatus1 === 'ERROR'">
                    Please fill in the form correctly.
                  </p>
                  <p class="typo__p" v-if="submitStatus1 === 'PENDING'">
                    Sending...
                  </p>
                </div>
                <ButtonPrimary
                  type="submit"
                  value="Submit"
                  :disabled="submitStatus1 === 'PENDING'"
                  ref="submit"
                >
                  Update
                </ButtonPrimary>
              </div>
            </div>
            <!-- /7.Submit Button -->
          </div>
        </form>
      </div>
      <div class="col-lg-5">
        <form
          @submit.prevent="securityFormRequest"
          class="row no-gutters p-3 pt-lg-5 pl-lg-5"
          autocomplete="on"
        >
          <div class="col-lg-12">
            <!-- 1.Primary Customer -->
            <h2>
              Change Password
            </h2>
            <!-- Field Requirements -->
            <div class="row no-gutters mt-5">
              <p class="customer__fieldRequirement">
                Fields marked with <span>*</span> are mandatory
              </p>
            </div>
            <!-- Name And Last Name -->
            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="$v.securityInformation.oldPassword.$model"
                    label="Verify current password"
                    name="oldPassword"
                    type="password"
                    required="true"
                    :status="$v.securityInformation.oldPassword.$error"
                  />
                  <div class="error" v-if="$v.securityInformation.oldPassword.$error">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.securityInformation.oldPassword.minLength">
                    Name must have at least
                    {{ $v.securityInformation.oldPassword.$params.minLength.min }}
                    letters.
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    v-model.trim="$v.securityInformation.password.$model"
                    name="password"
                    type="password"
                    label="New password"
                    required="true"
                    :status="$v.securityInformation.password.$error"
                  />
                  <div class="error" v-if="$v.securityInformation.password.$error">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.securityInformation.password.minLength">
                    Password must have at least
                    {{ $v.securityInformation.password.$params.minLength.min }}
                    letters.
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters mt-5">
              <div class="col-md-6">
                <ul>
                  <li>8 characters minimum</li>
                  <li>At least one number</li>
                </ul>
              </div>
            </div>
            <!-- Email Address -->
            <div class="row no-gutters mt-5">
              <div class="col-md-12">
                <div class="pr-md-2">
                  <InputText
                    autocomplete="on"
                    name="confirmPassword"
                    type="password"
                    v-model.trim="$v.securityInformation.confirmPassword.$model"
                    label="Confirm new password"
                    required="true"
                    :status="$v.securityInformation.confirmPassword.$error"
                  />
                  <div class="error" v-if="$v.securityInformation.confirmPassword.$error">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.securityInformation.confirmPassword.sameAsPassword">
                    Passwords not equal
                  </div>
                </div>
              </div>
            </div>
            <!-- 7.Submit Button -->
            <div class="row no-gutters mb-4 my-md-5">
              <div class="col-md-12">
                <div>
                  <p class="typo__p" v-if="submitStatus2 === 'OK'">
                    Thanks for your submission!
                  </p>
                  <p class="error" v-if="submitStatus2 === 'ERROR'">
                    Please fill in the form correctly.
                  </p>
                  <p class="typo__p" v-if="submitStatus2 === 'PENDING'">
                    Sending...
                  </p>
                </div>
                <ButtonPrimary
                  type="submit"
                  value="Submit"
                  :disabled="submitStatus2 === 'PENDING'"
                  ref="submit"
                >
                  Update
                </ButtonPrimary>
              </div>
            </div>
            <!-- /7.Submit Button -->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import InputText from '@/components/inputs/InputText'
// import InputTextPlace from '@/components/inputs/InputTextPlace'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import {
  required,
  minLength,
  email,
  sameAs
} from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import HeaderMenu from '../../components/flooring/HeaderMenu'
import { mapGetters } from 'vuex'
// import { scroller } from 'vue-scrollto/src/scrollTo'

export default {
  components: {
    InputText,
    ButtonPrimary,
    HeaderMenu
    // scroller
    // InputTextPlace
  },
  directives: { mask },
  data () {
    return {
      basicInformation: {
        firstName: null,
        lastName: null,
        email: null,
        username: null,
        website: null
      },
      securityInformation: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      submitStatus1: false,
      submitStatus2: false,
      service: null
    }
  },
  validations: {
    basicInformation: {
      firstName: {
        required,
        minLength: minLength(2)
      },
      lastName: {
        required,
        minLength: minLength(2)
      },
      email: {
        email,
        required
      }
      // phoneMobile: { numeric },
      // website: { numeric }
    },
    securityInformation: {
      oldPassword: {
        required,
        minLength: minLength(8)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs(function () {
          return this.securityInformation.password
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),
    textAreaChartCount () {
      if (this.basicInformation.notes.length === 0) {
        return 0
      }
      return this.basicInformation.notes.length
    },
    additionalCustomerStatus () {
      return this.additionalCustomer.status
    },
    companyStatus () {
      return this.company.status
    }
  },
  methods: {
    uploadImage (event) {
      console.log('files', event.target.files)
      this.$store.dispatch('uploadProfilePhoto', event.target.files[0]).then(() => {
        this.$store.dispatch('fetchProfile')
      }).catch(error => {
        console.log('Error', error)
      })
    },
    chooseFiles () {
      const fileInputElement = this.$refs.file
      fileInputElement.click()
      console.log('fileInputElement', fileInputElement)
    },
    handleFormRequest () {
      const allFormFields = {
        ...this.basicInformation
      }

      // console.log('validation', this.$v)
      // console.log('submit!', JSON.parse(JSON.stringify(allFormFields)))

      this.$v.basicInformation.$touch()
      if (this.$v.basicInformation.$invalid) {
        this.submitStatus1 = 'ERROR'
      } else {
        this.submitStatus1 = 'PENDING'
        this.$store.dispatch('updateProfile', allFormFields).then(() => {
          this.$store.dispatch('fetchProfile')
          this.submitStatus1 = false
        })
      }
    },
    securityFormRequest () {
      const allFormFields = {
        oldPassword: this.securityInformation.oldPassword,
        password: this.securityInformation.password
      }

      // console.log('validation', this.$v)
      // console.log('submit!', JSON.parse(JSON.stringify(allFormFields)))

      this.$v.securityInformation.$touch()
      if (this.$v.securityInformation.$invalid) {
        this.submitStatus2 = 'ERROR'
      } else {
        this.submitStatus2 = 'PENDING'
        this.$store.dispatch('changePassword', allFormFields).then(() => {
          this.submitStatus2 = false
          this.securityInformation.oldPassword = ''
          this.securityInformation.password = ''
          this.securityInformation.confirmPassword = ''
          setTimeout(() => { this.$v.securityInformation.$reset() }, 0)
          this.$nextTick(() => { this.$v.securityInformation.$reset() })
        }).catch(() => {
          this.submitStatus2 = 'ERROR'
        })
      }
    },
    deletePhotoRequest () {
      const payload = {
        avatar: null,
        id: this.profile.id
      }

      this.$store.dispatch('updateUser', payload).then(response => {
        this.$store.dispatch('fetchProfile')
      }, () => {
        this.submitStatus = 'ERROR'
      })
    },
    confirmDelete () {
      this.$bvModal.msgBoxConfirm('Are you sure to delete profile photo?', {
        centered: true,
        okTitle: 'Delete'
      })
        .then(value => {
          if (value) {
            this.deletePhotoRequest()
          }
        })
        .catch(err => {
          // An error occurred
          console.log('Error', err)
        })
    }
  },
  created () {
    this.$store.dispatch('fetchProfile')
  },
  watch: {
    profile: function (newVal, oldVal) {
      this.basicInformation.firstName = newVal.firstName || ''
      this.basicInformation.lastName = newVal.lastName || ''
      this.basicInformation.email = newVal.email || ''
      this.basicInformation.username = newVal.username || ''
      this.basicInformation.website = newVal.website || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.customer {
  h1 {
    border-bottom: 1px solid #cedde7;
  }

  &__fieldRequirement {
    span {
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: $error;
    }
  }

  &__dropdown {
    position: relative;
    color: $green;
    cursor: pointer;

    span {
      position: absolute;
      font-size: 32px;
      font-weight: 300;
      top: 50%;
      transform: translate(13px, -50%);
      color: $orange;
    }
  }

  &__chartCounter {
    color: $gray;
  }

  /deep/ .v-select {
    filter: none;
  }
}

.inputText {
  position: relative;

  &__error {
    position: absolute;
    color: #c11b0f;
    font-size: 32px;
    line-height: 0.8;
  }

  &__label {
    color: $gray;
  }

  &__error + &__label {
    padding-left: 15px;
  }

  &__input {
    display: flex;
    width: 100%;
    background: $white;
    color: #a19f9f;
    border: 1px solid #00818f;
    border-radius: 10px;
    outline: none;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    padding: 13px 16px;
    margin: 8px 0px;
  }
}
.role-badge {
  color: $blue;
  background: $grayBg;
  padding: 7px 9px;
  text-transform: capitalize;
}
</style>
