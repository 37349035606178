<template>
  <div class="customer">
    <HeaderMenu />
    <div class="row no-gutters p-3 pt-lg-5 pl-lg-5" align-v="bottom">
      <h2>
        Users
      </h2>
      <div class="pl-3 link-dark pt-1" v-b-modal.invite-user>
        Invite user
      </div>
      <!-- Modal -->
      <b-modal
        size="md"
        centered
        id="invite-user"
        :hide-footer="true"
      >
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <h2>Invite a user</h2>
          <div @click="close()">
            <IconCloseBig />
          </div>
        </template>
        <!-- Name And Last Name -->
        <form
          @submit.prevent="handleFormRequest"
          autocomplete="on"
        >
          <div class="row no-gutters mt-5">
            <div class="col-md-12">
              <div class="pr-md-2">
                <InputText
                  v-model.trim="$v.form.firstName.$model"
                  label="First Name"
                  name="firstName"
                  required="true"
                  :status="$v.form.firstName.$error"
                />
                <div class="error" v-if="$v.form.firstName.$error">
                  Field is required
                </div>
                <div class="error" v-if="!$v.form.firstName.minLength">
                  Name must have at least
                  {{ $v.form.firstName.$params.minLength.min }}
                  letters.
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters mt-5">
            <div class="col-md-12">
              <div class="pr-md-2">
                <InputText
                  v-model.trim="$v.form.lastName.$model"
                  name="lname"
                  label="Last Name"
                  required="true"
                  :status="$v.form.lastName.$error"
                />
                <div class="error" v-if="$v.form.lastName.$error">
                  Field is required
                </div>
                <div class="error" v-if="!$v.form.lastName.minLength">
                  Last Name must have at least
                  {{ $v.form.lastName.$params.minLength.min }}
                  letters.
                </div>
              </div>
            </div>
          </div>
          <!-- Email Address -->
          <div class="row no-gutters mt-5">
            <div class="col-md-12">
              <div class="pr-md-2">
                <InputText
                  autocomplete="on"
                  name="email"
                  type="email"
                  v-model.trim="$v.form.email.$model"
                  label="Email Address"
                  required="true"
                  :status="$v.form.email.$error"
                />
                <div class="error" v-if="$v.form.email.$error">
                  Field is required
                </div>
                <div class="error" v-if="!$v.form.email.email">
                  Please Enter Valid Email Address
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="pb-2" v-for="item in roles" :key="item.id">
              <div>
                <input
                  type="radio"
                  :value="item.name"
                  :id="item.name"
                  v-model="form.role"
                />
                <label class="ml-2" :for="item.name"><b>{{ item.title }}</b></label>
              </div>
              <div class="pl-4">{{ item.description }}</div>
            </div>
          </div>
          <input type="hidden" name="storeId" :value="this.storeId">
          <div class="mt-2">
            <ButtonPrimary class="w-auto" >
              Send invitation
            </ButtonPrimary>
            <ButtonSecondary class="ml-4 w-auto" >
              Cancel
            </ButtonSecondary>
          </div>
        </form>
      </b-modal>
    </div>
    <div class="row no-gutters p-3 pt-lg-5 pl-lg-5">
      <div class="col-lg-12">
        <!-- Field Requirements -->
        <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto">
          <b-row
            class="quoteInternal__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2"
            v-for="(item, index) in users"
            :key="index"
            align-v="center"
          >
            <b-col class="col-md-4 pr-md-4 pb-3 pb-md-0 px-3 px-md-0 " >
              <b-row class="pl-4">
                <div class="pr-3">
                  <b-avatar size="41px" :src="item.avatar"></b-avatar>
                </div>
                <div>
                  <div class="text-dark">
                    {{ item.firstName }} {{ item.lastName }}
                  </div>
                  <div class="text-secondary">
                    {{ item.email }}
                  </div>
                </div>
              </b-row>
            </b-col>
            <b-col class="col-md-2 px-md-4">
              <p class="text-secondary pb-3 pb-md-0 px-3 px-md-0">
                <b-badge class="role-badge" v-if="item.status != 'revoked'">{{ item.role }}</b-badge>
                <b-badge class="role-badge" v-if="item.status === 'revoked'">Revoked</b-badge>
              </p>
            </b-col>
            <b-col class="col-md-6 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
              <p class="text-secondary text-md-right">
                <ButtonPrimary class="w-auto" @click="editUserModal(item)">
                  Edit
                </ButtonPrimary>
                <ButtonSecondary class="ml-4 w-auto" @click="confirmRevoke(item)"
                   v-if="item.role != 'admin' && item.status != 'revoked'">
                  Revoke access
                </ButtonSecondary>
              </p>
            </b-col>
          </b-row>
        </div>

      </div>
    </div>

    <b-modal
      size="md"
      centered
      id="update-user"
      :hide-footer="true"
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h2>Edit user</h2>
        <ButtonSecondary v-if="form.role != 'admin' && form.status != 'revoked'" @click="confirmRevoke({id: editId})">
          Revoke Access
        </ButtonSecondary>
        <div @click="close()">
          <IconCloseBig />
        </div>
      </template>
      <!-- Name And Last Name -->
      <form
        @submit.prevent="editFormRequest"
        autocomplete="on"
      >
        <div class="mt-2">
          <div class="pb-2" v-for="item in roles" :key="item.id">
            <div>
              <input
                type="radio"
                :value="item.name"
                :id="item.name"
                v-model="form.role"
              />
              <label class="ml-2" :for="item.name"><b>{{ item.title }}</b></label>
            </div>
            <div class="pl-4">{{ item.description }}</div>
          </div>
        </div>
        <div class="mt-2">
          <ButtonPrimary class="w-auto">
            Save
          </ButtonPrimary>
          <ButtonSecondary class="ml-4 w-auto" @click="closeEditModal()">
            Cancel
          </ButtonSecondary>
        </div>
      </form>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios'
import InputText from '@/components/inputs/InputText'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import ButtonSecondary from '@/components/buttons/ButtonSecondary'
import IconCloseBig from '@/components/icons/menu/IconCloseBig'
import {
  required,
  minLength, email
} from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { BACKEND_API_URL, ROLES } from '../../constants/default'
import HeaderMenu from '../../components/flooring/HeaderMenu'
import { mapGetters } from 'vuex'
import { getHeaders } from '../../utils'

const initialForm = {
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  status: null,
  storeId: null
}

export default {
  components: {
    InputText,
    ButtonPrimary,
    ButtonSecondary,
    HeaderMenu,
    IconCloseBig
  },
  directives: { mask },
  data () {
    return {
      editId: null,
      roles: ROLES,
      form: {
        ...initialForm
      },
      submitStatus: false
    }
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2)
      },
      lastName: {
        required,
        minLength: minLength(2)
      },
      email: {
        email,
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getSettings',
      users: 'getUsers',
      storeId: 'getStoreId'
    })
  },
  created () {
    this.$store.dispatch('fetchSettings')
    this.$store.dispatch('fetchUsers')
  },
  methods: {
    handleFormRequest () {
      // console.log('validation', this.$v)
      // console.log('submit!', JSON.parse(JSON.stringify(allFormFields)))
      this.$root.$emit('bv::hide::modal', 'invite-user', '#focusThisOnClose')
      const payload = {
        ...this.form
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        // setTimeout(() => {
        //   this.submitStatus = 'OK'
        //   this.$bvToast.toast('This is toast number 3', {
        //     title: 'BootstrapVue Toast',
        //     autoHideDelay: 5000,
        //     appendToast: true
        //   })
        // }, 500)
        const url = `${BACKEND_API_URL}/invitations`
        axios.post(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            console.log('res', res)
            this.submitStatus = false
            this.form = { ...initialForm }
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    editUserModal (item) {
      this.editId = item.id
      this.form.role = item.role
      this.form.status = item.status

      this.$root.$emit('bv::show::modal', 'update-user', '#focusThisOnClose')
    },
    closeEditModal () {
      this.form = { ...initialForm }
      this.$root.$emit('bv::hide::modal', 'update-user', '#focusThisOnClose')
    },
    editFormRequest () {
      const payload = {
        role: this.form.role,
        id: this.editId
      }

      this.$store.dispatch('updateUser', payload).then(response => {
        this.submitStatus = false
        this.closeEditModal()
        this.$store.dispatch('fetchUsers')
      }, () => {
        this.submitStatus = 'ERROR'
      })
    },
    revokeRequest (id) {
      const payload = {
        status: 'revoked',
        id
      }

      this.$store.dispatch('updateUser', payload).then(response => {
        this.closeEditModal()
        this.$store.dispatch('fetchUsers')
      }, () => {
        this.submitStatus = 'ERROR'
      })
    },
    confirmRevoke (item) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to revoke access for user ${item.firstName} ${item.lastName}?`, {
        centered: true,
        okTitle: 'Revoke'
      })
        .then(value => {
          if (value) {
            this.revokeRequest(item.id)
          }
        })
        .catch(err => {
          // An error occurred
          console.log('Error', err)
        })
    }
  },
  updated () {},
  watch: {
    users (value) {
      console.log('value', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.customer {
  h1 {
    border-bottom: 1px solid #cedde7;
  }

  &__fieldRequirement {
    span {
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: $error;
    }
  }

  &__dropdown {
    position: relative;
    color: $green;
    cursor: pointer;

    span {
      position: absolute;
      font-size: 32px;
      font-weight: 300;
      top: 50%;
      transform: translate(13px, -50%);
      color: $orange;
    }
  }

  &__chartCounter {
    color: $gray;
  }

  /deep/ .v-select {
    filter: none;
  }

  .role-badge {
    color: $blue;
    background: $grayBg;
    padding: 7px 9px;
    text-transform: capitalize;
  }
  .text-dark {
    font-size: 16px;
    font-weight: bold;
    color: $green !important;
  }
  .link-dark {
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: $green !important;
  }
}

.inputText {
  position: relative;

  &__error {
    position: absolute;
    color: #c11b0f;
    font-size: 32px;
    line-height: 0.8;
  }

  &__label {
    color: $gray;
  }

  &__error + &__label {
    padding-left: 15px;
  }

  &__input {
    display: flex;
    width: 100%;
    background: $white;
    color: #a19f9f;
    border: 1px solid #00818f;
    border-radius: 10px;
    outline: none;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    padding: 13px 16px;
    margin: 8px 0px;
  }
}
</style>
