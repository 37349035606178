<template>
  <div class="container-fluid px-0">
    <!-- Order Detail: Header -->
    <div
      class="orderDetail__header px-3 py-5 px-md-5 py-md-6 border-bottom row no-gutters"
    >
      <div class="col-sm-6">
        <h1 v-if="mode == 'edit'">Edit Order</h1>
        <h1 v-else class="">Orders</h1>
        <h2 class="text-secondary mt-2">
          Order # {{ order.orderNumber }}
        </h2>
      </div>
      <div class="col-sm-3 mt-5 mt-md-0 ml-auto">
        <h4 class="text-secondary mb-2">
          Order status
        </h4>
        <v-select
          v-model="status"
          :options="orderStatuses"
          placeholder="Make a selection"
        ></v-select>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-lg-9">
        <div class="orderDetail__body p-3 px-md-5 pt-md-6">
          <!-- 1.Order Table -->
          <div class="container-fluid px-0 mb-4">
            <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto">
              <!-- 1.1 Header -->
              <!-- <div
                class="orderDetail__tableHeader col-5 col-sm-3 col-md-12 border-bottom pb-md-4 row no-gutters"
              >
                <div class="col-md-2 pr-md-4">
                  <h4 class="text-info">
                    Product
                  </h4>
                </div>
                <div class="col-md-4 px-md-4">
                  <h4 class="text-info">
                    Description
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-center">
                    Cost
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-right">
                    SF
                  </h4>
                </div>
                <div class="col-md-2 pl-md-4">
                  <h4 class="text-info text-md-right">
                    Sub Total
                  </h4>
                </div>
              </div> -->
              <!-- 1.2 Body | Rows -->
              <!-- <div
                class="orderDetail__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2"
                v-for="(item, index) in productList"
                :key="index"
              >
                <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary">
                    {{ item.manufacturer.title }}
                  </p>
                </div>
                <div class="col-md-4 px-md-4">
                  <p class="text-secondary pb-3 pb-md-0 px-3 px-md-0">
                    {{ item.type.flooring }},
                    {{ item.type.species }},
                    {{ item.type.lookGrade }},
                    {{ item.type.platform }},
                    {{ item.type.collection }},
                    {{ item.type.gloss }},
                    {{ item.type.width }},
                    {{ item.type.finish }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary text-md-center">
                    {{ formatter.format(item.price) }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary text-md-right">
                    {{ format(item.totalSquare) }}
                  </p>
                </div>
                <div class="col-md-2 pl-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <p class="text-secondary text-md-right">
                    {{ formatter.format(item.totalPrice) }}
                  </p>
                </div>
              </div> -->
            </div>
            <div class="row no-gutters flex-nowrap flex-md-wrap overflow-auto mt-4">
              <div
                class="quoteInternal__tableHeader col-5 col-sm-3 col-md-12 border-bottom pb-md-4 row no-gutters"
              >
                <div class="col-md-2 px-md-4" style="padding-left: 0 !important">
                  <h4 class="text-info">Brand</h4>
                </div>
                <div class="col-md-2 px-md-4" style="padding-left: 0 !important">
                  <h4 class="text-info">Type</h4>
                </div>
                <div class="col-md-3 px-md-4">
                  <h4 class="text-info">
                    Description
                  </h4>
                </div>
                <div class="col-md-1 px-md-4">
                  <h4 class="text-info">
                    SKU
                  </h4>
                </div>
                <div class="col-md-1">
                  <h4 class="text-info text-right">
                    Price $
                  </h4>
                </div>
                <div class="col-md-1 px-md-4 text-right" style="padding-left: 0 !important">
                  <h4 class="text-info  text-md-center">
                    Unit
                  </h4>
                </div>
                <div :class="{'col-md-1': mode == 'edit', 'col-md-2': mode != 'edit'}">
                  <h4 class="text-info text-md-right text-info ">
                    Total
                  </h4>
                </div>
                <!-- delete column placeholder -->
                <div v-if="mode == 'edit'" class="col-md-1"></div>
              </div>
              <div
                class="quoteInternal__tableRow col-8 col-sm-6 col-md-12 border-bottom row no-gutters pt-md-4 pb-md-2 align-items-center"
                v-for="(item, index) in charges"
                :key="item.id"
              >
                <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <InputText v-if="mode == 'edit'" :disabled="item.id > 0" placeholder="" v-model="item.brand" />
                  <p v-else class="text-secondary">
                    {{ item.brand }}
                  </p>
                </div>
                <div class="col-md-2 pr-md-4 pb-3 pb-md-0 px-3 px-md-0">
                  <InputText v-if="mode == 'edit'" :disabled="item.id > 0" placeholder="" v-model="item.type" />
                  <p v-else class="text-secondary">
                    {{ item.type }}
                  </p>
                </div>
                <div class="col-md-3 px-md-4">
                  <InputText v-if="mode == 'edit'" :disabled="item.id > 0" placeholder="" v-model="item.description" />
                  <p v-else class="text-secondary">
                    {{ item.description }}
                  </p>
                </div>
                <div class="col-md-1 px-md-4">
                  <InputText v-if="mode == 'edit'" :disabled="item.id > 0" placeholder="" v-model="item.sku" />
                  <p v-else class="text-secondary">
                    {{ item.sku }}
                  </p>
                </div>
                <div class="col-md-1 text-right">
                  <InputText v-if="mode == 'edit'" placeholder="$" v-model="item.price" />
                  <p v-else class="text-secondary">
                    {{ formatter.format(parseFloat(item.price)) }}
                  </p>
                </div>
                <div class="col-md-1 pr-md-4 pb-3 pb-md-0 px-3 px-md-0 text-md-center text-right">
                  <InputText v-if="mode == 'edit'" placeholder="" v-model="item.quantity" class="ml-2"/>
                  <p v-else class="text-secondary">
                    {{ item.quantity }}
                  </p>
                </div>
                <div class="px-3 px-md-0" :class="{'col-md-1': mode == 'edit', 'col-md-2': mode != 'edit'}">
                  <h4 class="text-secondary text-md-right" role="button" :id="'lineItemPopover-' + index" :class="{ 'tax-partial' : item.taxes && (item.taxes.length != settings.taxes.length)}">
                    {{ item.price && item.quantity ? formatter.format(item.quantity * parseFloat(item.price)) : formatter.format(0) }}
                  </h4>
                </div>
                <b-popover v-if="mode == 'edit'" :target="'lineItemPopover-' + index" triggers="hover" placement="left">
                  <template #title>Taxes</template>
                  <b-form-group label="" >
                    <b-form-checkbox-group
                      v-model="item.taxes"
                      :options="order.taxTemplate"
                      name="line-item-taxes"
                      text-field="name"
                      value-field="name"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </b-popover>
                <div v-if="mode == 'edit'" class="col-md-1 px-2 pl-md-2">
                  <h4 class="text-md-right">
                    <b-button variant="link"  @click="handleRemoveLineItem(index)" >
                      <b-icon icon="trash" class="text-danger"></b-icon>
                    </b-button>
                  </h4>
                </div>
              </div>
              <div v-if="mode == 'edit'"
                class="quoteCharge__tableRow row col-8 col-sm-4 col-md-12 no-gutters align-items-md-center px-0 pt-md-4 mb-1"
              >
                <div class="flex">
                  <b-button variant="info" @click="addAdditionalProduct" class="mx-2">
                    <b-icon icon="plus-circle"></b-icon> Add Line Item
                  </b-button>
                  <b-button variant="info" @click="addItemFromCatalog" class="mx-2">
                    <b-icon icon="plus-circle"></b-icon> Choose From Catalog
                  </b-button>
                  <!-- <h4 class="quoteCharge--link text-primary cursor-pointer" @click="addAdditionalProduct">+ </h4> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 2.Total Cost -->
          <div
            class="d-flex justify-content-between pb-2 mb-3 pb-md-4 mb-md-4"
          >
            <h4 class="text-info">
              Sub Total:
            </h4>
            <h4 class="text-info">
              {{ formatter.format(subTotal) }}
            </h4>
          </div>
          <div v-if="mode != 'edit'">
            <div
              class="d-flex justify-content-between mb-6"
            v-for="(taxAmount, taxName) in order.taxBreakdown" :key="taxName">
            <!-- tax breakdown -->
                <h4 class="text-info">
                  {{ taxName }} ({{ taxMap[taxName] }}%)
                </h4>
                <h4 class="text-info">
                  {{ formatter.format(taxAmount) }}
                </h4>
            </div>
          </div>
          <div v-if="mode != 'edit'"
            class="d-flex justify-content-between pb-3 mb-3 pb-md-6 mb-md-6 border-bottom"
          >
            <h4 class="text-info">
              Total:
            </h4>
            <h4 class="text-info">
              {{ formatter.format(order.total) }}
            </h4>
          </div>
          <!-- 3.Payments -->
          <div class="pb-6 mb-6 border-bottom">
            <h4 class="text-secondary mb-6">
              Payments
            </h4>
            <div class="my-2 d-flex justify-content-between align-items-center text-secondary" v-for="(item, index) in order.payments" :key="index">
              <div class="d-flex align-items-baseline">
                <h4 class="pr-1">{{item.type}}</h4>
                <p>{{formatDate(item.createdAt, 'MMMM DD, YYYY')}}</p>
              </div>
              <p style="margin-left: auto">{{ formatter.format(item.amount) }}</p>
              <b-button variant="link"  @click="handleRemovePayment(index)" >
                <b-icon icon="trash" class="text-danger"></b-icon>
              </b-button>
            </div>
            <div class="mb-3 mb-md-6" v-if="order.status !== 'full'">
              <!-- Payment Toggle -->
              <span
                v-b-toggle="'orderDetailCollapse'"
                class="d-flex align-items-center mb-6"
                @click="paymentVisible = !paymentVisible"
              >
                <h4 class="align-self-center green mr-4">
                  Add payment
                </h4>
                <span class="orderDetail__icon">
                  <IconPlus v-if="!paymentVisible" class="d-flex" />
                  <IconMinus v-else class="d-flex" />
                </span>
              </span>
              <!-- Payment Collapse -->
              <b-collapse
                id="orderDetailCollapse"
                class="orderDetail__collapse"
                v-if="order.status !== 'full'"
              >
                <div class="row">
                  <div class="col-md-4 pr-md-4 pb-4 pb-md-0">
                    <h4 class="text-secondary">
                      Payment type
                    </h4>
                    <v-select
                      v-model="payment.type"
                      :options="['VISA','MC','AMEX','Credit Card','Interac','Loan','Check','Cash','etransfer','Other']"
                      placeholder="Make a selection"
                    ></v-select>
                  </div>
                  <div class="col-md-4 px-md-3 pb-2 pb-md-0">
                    <h4 class="text-secondary">
                      Enter date MM-DD-YYYY
                    </h4>
                    <InputDate v-model="payment.createdAt" />
                  </div>
                  <div class="col-md-4 pl-md-4 pb-2 pb-md-0">
                    <h4 class="text-secondary">
                      Amount
                    </h4>
                    <InputText type="number" v-model="payment.amount" />
                  </div>
                </div>
              </b-collapse>
            </div>
            <div class="d-flex justify-content-between">
              <h4 class="text-info">
                Balance Due
              </h4>
              <h4 class="text-info">
                {{ formatter.format(order.due) }}
              </h4>
            </div>
          </div>
          <!-- 4.Installation Date -->
          <div class="row mt-6 mb-6">
            <div class="col-md-6">
              <h4 class="text-secondary">
                Installation date MM-DD-YYYY
              </h4>
              <InputDate v-model="installedAt" />
            </div>
            <div class="col-md-6">
              <h4 class="text-secondary">
                P.O. #
              </h4>
                <InputText type="text" v-model="order.poNumber" />
            </div>
          </div>
          <!-- 5.Customer Notes -->
          <div class="mb-4">
            <InputTextArea
              placeholder=""
              v-model="customerNotes"
              label="Customer notes (This will appear in quotes and invoices)"
              rows="4"
            />
            <p class="text-secondary small text-right">
              {{ textAreaChartCount }} / 1000
            </p>
          </div>
          <!-- 6.Internal Notes -->
          <div class="mb-4">
            <InputTextArea
              placeholder=""
              v-model="internalNotes"
              label="Internal notes"
              rows="4"
            />
            <p class="text-secondary small text-right">
              {{ textAreaChartCountSecond }} / 1000
            </p>
          </div>
          <!-- 7.Save Order -->
          <div class="mb-4">
            <b-button variant="primary" class="w-200 mr-2"  size="lg" @click="saveOrder">Save</b-button>
            <b-button v-if="mode != 'edit'" variant="outline-primary" class="w-200 mr-2"  size="lg" @click="edit">Edit</b-button>
            <b-button v-if="mode != 'edit'" variant="outline-danger" class="w-200 mr-2"  size="lg" @click="handleDeleteBtnClick">Delete Order</b-button>

          </div>
          <div class="orderDetail__icon d-flex mb-6">
            <!--            <span class="d-flex align-items-center mr-6">-->
            <!--              <div class="link">Email</div>-->
            <!--              <IconMail class="ml-1" />-->
            <!--            </span>-->
            <span class="d-flex align-items-center mr-6" @click="printPdf">
              <p class="link">Print PDF</p>
              <IconFilePdf class="ml-2" />
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 ml-auto">
        <BoxCustomerDetail />
        <BoxOrderPartnerTag
          :id="this.$route.params.orderId"
          @partnerChange="partnerChange"
        />
      </div>
    </div>
    <!-- Modal: Product Catalog -->
    <b-modal id="modal-product-catalog" size="xl" ok-title="Add items to order" style="max-height: 80vh" @ok="handleAddItemsToOrder">
      <ProductList :allow-edit="false" />
    </b-modal>

  </div>
</template>

<script>
import BoxCustomerDetail from '@/components/boxes/BoxCustomerDetail'
import InputTextArea from '@/components/inputs/InputTextArea'
import IconPlus from '@/components/icons/edit/IconPlus'
import IconMinus from '@/components/icons/edit/IconMinus'
import InputText from '../../components/inputs/InputText.vue'
import InputDate from '../../components/inputs/InputDate.vue'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { formatDate, formatter, getHeaders } from '../../utils'
import { ORDER_STATUSES } from '../../constants/order'
import IconFilePdf from '@/components/icons/file/IconFilePdf'
import ProductList from '@/views/product/ProductList'
import BoxOrderPartnerTag from '../../components/boxes/BoxOrderPartnerTag'

const initialPayment = {
  type: 'VISA',
  amount: '',
  createdAt: ''
}

const defaultAdditionalCharge = {
  brand: '',
  type: '',
  description: '',
  sku: '',
  price: null,
  quantity: 1
}

export default {
  name: 'LayoutDefaultQuote',
  components: {
    BoxOrderPartnerTag,
    BoxCustomerDetail,
    InputTextArea,
    IconPlus,
    IconMinus,
    InputText,
    InputDate,
    IconFilePdf,
    ProductList
  },
  props: {
    mode: String // view or edit
  },
  data () {
    return {
      orderStatuses: ORDER_STATUSES.map(item => ({ code: item.name, label: item.title })),
      paymentVisible: false,
      customerNotes: '',
      internalNotes: '',
      installedAt: '',
      status: '',
      payment: {
        ...initialPayment
      },
      formatter,
      formatDate,
      charges: [],
      listSavedPartner: []
    }
  },
  computed: {
    ...mapGetters({
      order: 'getOrder',
      // charges: 'getQuoteCharges',
      settings: 'getSettings',
      taxMap: 'getTaxMap',
      partners: 'getPartners'
    }),
    productList () {
      return this.order?.quote?.products ? this.order?.quote?.products?.map(item => ({
        ...item,
        price: this.settings.price,
        totalSquare: this.getTotal(item.measurements, 'square'),
        totalPrice: this.getTotal(item.measurements, 'square') * this.settings.price
      })) : []
    },
    textAreaChartCount () {
      if (!this.customerNotes) {
        return 0
      }
      return this.customerNotes ? this.customerNotes.length : 0
    },
    textAreaChartCountSecond () {
      if (!this.internalNotes) {
        return 0
      }
      return this.internalNotes ? this.internalNotes.length : 0
    },
    subTotal () {
      let subTotal = 0
      this.order.lineItems.forEach(item => {
        subTotal += parseFloat(item.price) * item.quantity
      })
      return subTotal
    }
  },
  created () {
    this.getOrder()
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    partnerChange (data) {
      this.listSavedPartner = data
    },
    getTotal (items = [], field) {
      let total = 0

      Array.isArray(items) && items.forEach(item => {
        total += item[field] ? parseFloat(item[field]) : 0
      })

      return total
    },
    format (value) {
      return value ? (Math.round(parseFloat(value) * 100) / 100).toFixed(2) : 0
    },
    getOrder () {
      const url = `${BACKEND_API_URL}/orders/${this.$route.params.orderId}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.customerNotes = res.data && res.data.quote && res.data.quote.notes
          this.internalNotes = res.data && res.data.internalNotes
          this.status = res.data && res.data.status
          this.installedAt = res.data && res.data.installedAt
          this.$store.dispatch('setOrder', res.data)
          this.$store.dispatch('setQuoteCustomer', res.data?.quote?.customer)
          this.$store.dispatch('setQuoteCharges', res.data?.quote?.charges)
          this.listSavedPartner = res.data.partners
          this.charges = res.data.lineItems
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    downloadPdf () {
      var elm = this.$refs.print
      var value = elm.innerHTML
      const url = `${BACKEND_API_URL}/orders/${this.order.id}/pdf`
      const FileDownload = require('js-file-download')

      const payload = {
        content: value
      }

      axios.post(url, payload, {
        headers: getHeaders(),
        responseType: 'blob'
      })
        .then(response => {
          FileDownload(response.data, `Order-${this.order.orderNumber}.pdf`)
          console.log('res order', response)
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    },
    printPdf () {
      const routeData = this.$router.resolve({ name: 'OrderPrint', query: { orderId: this.order.id } })
      window.open(routeData.href, '_blank')
    },
    saveOrder () {
      if (this.order && this.order.id) {
        const url = `${BACKEND_API_URL}/orders/${this.order.id}`
        const payments = this.order.payments ? this.order.payments : []

        if (this.payment && this.payment.type && this.payment.createdAt && this.payment.amount) {
          payments.push(this.payment)
        }
        const payload = {
          internalNotes: this.internalNotes,
          notes: this.customerNotes,
          installedAt: this.installedAt,
          status: this.status && this.status.code,
          payments,
          lineItems: this.charges,
          poNumber: this.order.poNumber,
          partners: this.listSavedPartner
        }

        axios.patch(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            console.log('res order', res.data && res.data.status, res)
            this.payment = { ...initialPayment }
            this.$store.dispatch('setOrder', res.data)
            this.status = res.data && res.data.status
            this.$router.push({ name: 'OrderDetail', params: { orderId: this.order.id } })
            window.scrollTo(0, 0)
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    addAdditionalProduct () {
      const newCharge = {
        ...defaultAdditionalCharge
      }
      newCharge.taxes = this.order.taxTemplate.map(item => item.name)
      this.charges.push(newCharge)
    },
    addItemFromCatalog () {
      this.$bvModal.show('modal-product-catalog')
    },
    handleRemoveLineItem (index) {
      this.charges.splice(index, 1)
    },
    edit () {
      this.$router.push({ name: 'OrderEdit', params: { orderId: this.order.id } })
        .then(() => {
          window.scrollTo(0, 0)
        })
    },
    handleDeleteBtnClick () {
      if (confirm('Are you sure you want to delete this order?')) {
        const url = `${BACKEND_API_URL}/orders/${this.order.id}`
        axios.delete(url, {
          headers: getHeaders()
        })
          .then(res => {
            this.$router.push({ name: 'OrderList' })
          })
          .catch(e => {
            this.submitStatus = 'ERROR'
          })
      }
    },
    handleAddItemsToOrder () {
      const selectedItems = this.$store.state.product.productTable.getSelectedData()
      selectedItems.forEach(item => {
        delete item.search_field
        const newCharge = {
          ...item,
          quantity: 1
        }
        newCharge.taxes = this.order.taxTemplate.map(item => item.name)
        this.charges.push(newCharge)
      })
    },
    handleRemovePayment (index) {
      this.order.payments.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.orderDetail {
  &--link {
    cursor: pointer;
  }

  &__icon {
    font-size: 24px;

    /deep/ svg * {
      fill: $orange;
    }
  }

  &__tableHeader {
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.17));
    position: sticky;
    background-color: $white;
    top: 0;
    left: 0;
    z-index: 10;

    border-bottom: none;
    border-right: 1px solid $secondaryBlue;

    @include mq {
      filter: none;
      border-right: none;
      border-bottom: 1px solid $secondaryBlue;
    }
  }

  &__tableRow {
    p {
      @media screen and (max-width: 667px) {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }

  &__icon {
    font-size: 24px;

    /deep/ svg * {
      fill: $orange;
    }
  }

  &__collapse {
    /deep/ .v-select {
      margin-top: 8px;
    }
  }
}
</style>
