const state = () => ({
  orders: [],
  pagination: {},
  order: {}
})

const getters = {
  getOrder (state) {
    return state.order
  },
  getOrders (state) {
    return state.orders
  },
  getOrderPagination (state) {
    return state.pagination
  },
  getTaxMap (state) {
    const taxMap = {}
    state.order.taxTemplate.map(tax => {
      taxMap[tax.name] = tax.rate
    })
    return taxMap
  }
}

const actions = {
  setOrder (context, payload) {
    context.commit('SET_ORDER', payload)
  },
  setOrders (context, payload) {
    context.commit('SET_ORDERS', payload)
  },
  appendOrders (context, payload) {
    context.commit('APPEND_ORDERS', payload)
  }
}

const mutations = {
  SET_ORDER (state, payload) {
    state.order = payload
  },
  SET_ORDERS (state, payload) {
    const { data, ...pagination } = payload
    state.orders = data || []
    state.pagination = pagination || {}
  },
  APPEND_ORDERS (state, payload) {
    const { data, ...pagination } = payload
    state.orders = data ? [...state.orders, ...data] : []
    state.pagination = pagination || {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
