jmdmjwe
<template>
  <div class="main">
    <b-container style="max-width:100vw;">
      <b-row class="header">
        <b-col class="header-column">
          <h1><b>Calendar</b></h1>
          <b-button @click="setToday" id="setTodayBtn">
            Today
          </b-button>
          <b-button @click="lastWeek" id="lastWeekBtn" class="imgbtn">
            <img src="@/assets/chevron_big_left.svg" alt="" />
          </b-button>
          <b-button @click="nextWeek" id="nextWeekBtn" class="imgbtn">
            <img src="@/assets/chevron_big_right.svg" alt="" />
          </b-button>
          <h2>
            {{ selectedMonthYear }}
          </h2>
          <b-form-select
            class="view-selector"
            v-model="viewSelected"
            :options="viewOptions"
            v-on:change="onSelectView"
            :style="{ backgroundImage: `url(${chevronBigDown})` }">
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="calendar-container" no-gutters>
        <b-col cols="3" id="left-calendar">
          <b-row>
            <b-button v-b-modal.create-event class="btn-primary-green" id="createEvent" @click="deleteClocks">Create an Event</b-button>
            <b-modal id="create-event" title="Create new event" :hide-footer=true :static=true>
              <b-form>
              <b-form-group id="input-group-title" label-for="input-title">
                <v-select v-model="newEventForm.category" label="category" id="categoryOptions" :options="options"></v-select>
              </b-form-group>
              <b-form-row class="categories"> <b-form-group id="input-group-time" label-for="input-time">
                <b-form-row id="input-time" class="createEvent">
                  <!-- <b-col class="icons"></b-col> -->
                  <v-icon><img src="@/assets/clock.svg" alt="clock" class="createEventIcon clock"></v-icon>
                  <b-col cols="7" id="clocks" class="clock-column">
                    <b-form-row><b-col><b-form-datepicker id="input-datepicker" class="createEvent timeSelector" v-model="newEventForm.date"></b-form-datepicker></b-col></b-form-row>
                    <b-form-row>
                      <b-col><b-form-timepicker id="input-start-time" class="createEvent timeSelector" v-model="newEventForm.startTime"></b-form-timepicker></b-col>
                      <div class="dash"> - </div>
                      <b-col><b-form-timepicker id="input-end-time" class="createEvent timeSelector" v-model="newEventForm.endTime"></b-form-timepicker></b-col>
                    </b-form-row>
                  </b-col>
                  <b-col id="checkboxes">
                    <b-col id="private"><input type="checkbox" id="private" name="private"><label for="private">Private</label></b-col>
                    <b-col id="all-day"><input type="checkbox" id="all-day" name="all-day"><label for="all-day">All Day</label></b-col>
                  </b-col>
                </b-form-row>
              </b-form-group></b-form-row>
             <b-form-row> <b-form-group id="input-add-customer" label-for="input-customer">
                <b-form-row id="input-customer" class="createEvent">
                  <v-icon><img src="@/assets/id_card.svg" alt="id_card" class="createEventIcon"></v-icon>
                  <b-col>
                    <b-form-row><b-col><b-form-group id="input-group-customer" label-for="input-customer" label="Add customer"></b-form-group></b-col></b-form-row>
                    <b-form-row><b-col><b-form-select id="input-customer-box" v-model="newEventForm.customer" placeholder="Helper Text"></b-form-select></b-col></b-form-row>
                  </b-col>
                </b-form-row>
              </b-form-group></b-form-row>
              <b-form-row><b-form-group id="input-group-staff" label-for="input-staff">
                 <b-form-row id="assign-staff" class="createEvent">
                  <v-icon><img src="@/assets/user.svg" alt="id_card" class="createEventIcon"></v-icon>
                    <b-col>
                      <b-form-row>
                        <b-form-group id="input-group-customer" label-for="input-staff" label="Assign to Staff Member"></b-form-group>
                      </b-form-row>
                      <b-form-row>
                        <b-form-group>
                            <b-form-checkbox
                            plain
                            v-model="newEventForm.staff"
                            :indeterminate="indeterminate"
                            aria-describedby="assignStaff"
                            aria-controls="assignStaff"
                            @change="toggleAll"
                            >
                            {{ allSelected ? 'Unselect All' : 'Select All' }}
                            </b-form-checkbox>
                            <b-form-checkbox-group
                              stacked
                              plain
                              v-model="newEventForm.staff"
                              :options="assignStaff"
                              aria-describedby="assignStaff"
                              aria-label="Individual Staff"
                              class="ml-4"
                            ></b-form-checkbox-group>
                        </b-form-group>
                      </b-form-row>
                      <!-- <b-form-row>
                        <b-form-group>
                          <template v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              stacked
                              plain
                              :options="assignStaff"
                              v-model="staffSelected"
                              value-field="value"
                              name="staff"
                              text-field="staff"
                              v-categoryCheckbox="assignStaff"
                              :aria-describedby="ariaDescribedby"
                              aria-label="Individual Staff"
                            ></b-form-checkbox-group>
                          </template>
                        </b-form-group>
                      </b-form-row> !-->
                    </b-col>
                  <b-form-radio-group
                  v-model="newEventForm.staff"
                  :options="newEventStaffOptions"
                ></b-form-radio-group>
                </b-form-row>
              </b-form-group></b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group id="input-group-notes" label="Notes">
                    <b-form-textarea
                    id="input-notes"
                    v-model="newEventForm.notes"
                    placeholder="Enter your comment..."
                    no-resize
                    rows="3">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group id="input-group-category">
                    <b-col><b-form-select v-model="newEventForm.category" :options="categoryOptions"></b-form-select></b-col>
                  </b-form-group>
                </b-col>
                <b-col><b-button @click="createNewEvent(newEventForm)" class="btn-primary-green">Save</b-button></b-col>
              </b-form-row>
              <b-card class="mt-3" header="Form Data Result">
                <pre class="m-0">{{ newEventForm }}</pre>
              </b-card>
            </b-form>
            </b-modal>
          </b-row>
          <b-row>
            <b-calendar
              width="23.8vw"
              v-model="calendarValue"
              @context="onContext"
              weekday-header-format="narrow"
              :hide-header="calendarHideHeader"
              start-weekday="1"
            ></b-calendar>
          </b-row>

          <!-- Calendar value  -->
          <!-- <b-row>
          <p>
            Value: <b>'{{ calendarValue }}'</b>
          </p>
          <p class="mb-0">Context:</p>
          <pre class="small">{{ context }}</pre>
        </b-row> -->

          <b-row>
            <b-button
              v-b-toggle.collapse-1
              variant="light"
              class="category category-collapse"
            >
              <h5>Categories</h5>
              <img src="@/assets/chevron_big_down.svg" alt="" />
            </b-button>
            <b-collapse visible id="collapse-1">
              <b-form-checkbox-group
                stacked
                v-model="selectedCat"
                :options="options"
                class="mb-4"
                value-field="item"
                text-field="category"
                disabled-field="notEnabled"
                v-categoryCheckbox="options"
              ></b-form-checkbox-group>
              <div class="mt-4">
                Selected: <strong>{{ selectedCat }}</strong>
              </div>
            </b-collapse>
          </b-row>
          <b-row>
            <b-button
              v-b-modal.new-category
              variant="light"
              class="category new-category"
            >
              <h5>Edit Category</h5>
              <img src="@/assets/edit.svg" alt="" />
            </b-button>
            <b-modal id="new-category" title="Create new category:">
               <form>
              <b-form-group
                class="form-label"
                label="Name"
                label-for="input-category-name">
                <b-form-input
                  class="blue-round-border"
                  id="input-category-name"
                  v-model="newCategory.name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="form-label"
                label="Select a colour"
                label-for="input-category-color">
                <div id="colourSelector">
                  <b-button class="colour-option yellow" id="yellow" @click="selectColour('yellow')"><img src="@/assets/ecbb3d.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option orange" id="orange" @click="selectColour"><img src="@/assets/f98341.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option lavendar" id="lavendar" @click="selectColour"><img src="@/assets/b1a8de.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option aqua" id="aqua" @click="selectColour"><img src="@/assets/80c7d0.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option blue" id="blue" @click="selectColour"><img src="@/assets/bce5fb.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option pink" id="pink" @click="selectColour"><img src="@/assets/e8a2c7.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option green" id="green" @click="selectColour"><img src="@/assets/b9e1b2.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option silver" id="silver" @click="selectColour"><img src="@/assets/e1e7ed.svg" alt="" class="colour-selection"></b-button>
                  <b-button class="colour-option grey" id="grey" @click="selectColour"><img src="@/assets/c0c4bf.svg" alt="" class="colour-selection"></b-button>
                </div>
              </b-form-group>
              </form>
              <b-button type="submit" @click="createNewCategory" class="btn-primary-green">Create category</b-button>
            </b-modal>
          </b-row>
          <b-row>
            <h5 class="staff-title">View by assigned staff:</h5>
            <br />
            <b-form-select
              v-model="staffSelected"
              :options="staffOptions"
            ></b-form-select>
          </b-row>
        </b-col>
        <b-col cols="8">
          <div id="big-calendar">
            <div v-if="viewSelected === 'day'">
              <b-table-simple fixed borderless>
                <b-thead class="rowheader">
                  <b-tr class='th-view'>
                    <b-th></b-th>
                    <b-th><div class="th-day">{{ dayView.day }}</div></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                  </b-tr>
                  <b-tr class='th-view'>
                    <b-th></b-th>
                    <b-th><div class='th-date selected'>{{ dayView.date }}</div></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody class='tbody-view'>
                  <b-tr v-for='row in dayEvents' :key='row.hour'>
                    <b-th class='timeRow'>{{ row.header }}</b-th>
                    <b-td class='dailyEventCell' colspan="7"><span v-if='(staffSelected === null || staffSelected === row.staff) && (CategoriesSelected.includes(row.category_id))' v-html='row.html'></span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <!-- This marks the end of the daily view.-->
            <!-- This is the week view. -->
            <div v-if="viewSelected === 'week'">
              <b-table-simple fixed borderless>
                <b-thead class="rowheader">
                  <b-tr class="th-week-view">
                    <b-th></b-th>
                    <b-th><div class="th-day">MON</div></b-th>
                    <b-th><div class="th-day">TUE</div></b-th>
                    <b-th><div class="th-day">WED</div></b-th>
                    <b-th><div class="th-day">THU</div></b-th>
                    <b-th><div class="th-day">FRI</div></b-th>
                    <b-th><div class="th-day">SAT</div></b-th>
                    <b-th><div class="th-day">SUN</div></b-th>
                  </b-tr>
                  <b-tr class="th-week-view">
                    <b-th></b-th>
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[0] }"
                      >
                        {{ day[0] }}
                      </div></b-th
                    >
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[1] }"
                      >
                        {{ day[1] }}
                      </div></b-th
                    >
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[2] }"
                      >
                        {{ day[2] }}
                      </div></b-th
                    >
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[3] }"
                      >
                        {{ day[3] }}
                      </div></b-th
                    >
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[4] }"
                      >
                        {{ day[4] }}
                      </div></b-th
                    >
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[5] }"
                      >
                        {{ day[5] }}
                      </div></b-th
                    >
                    <b-th
                      ><div
                        class="th-date"
                        v-bind:class="{ selected: isSelectedDay[6] }"
                      >
                        {{ day[6] }}
                      </div></b-th
                    >
                  </b-tr>
                </b-thead>
                <b-tbody class="tbody-week-view" fixed>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">8 AM</b-th>
                    <!-- testing for event cards -->
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="alternate-row timeColumn">9 AM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">10 AM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">11 AM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">12 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">1 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">2 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">3 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">4 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">5 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">6 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">7 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                  <b-tr class="timeRow">
                    <b-th class="timeColumn">8 PM</b-th>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <div v-if="viewSelected === 'month'">
                <b-table-simple fixed borderless>
                  <b-thead class="rowheader">
                    <b-tr class='th-view'>
                      <b-th><div class="th-day">MON</div></b-th>
                      <b-th><div class="th-day">TUE</div></b-th>
                      <b-th><div class="th-day">WED</div></b-th>
                      <b-th><div class="th-day">THU</div></b-th>
                      <b-th><div class="th-day">FRI</div></b-th>
                      <b-th><div class="th-day">SAT</div></b-th>
                      <b-th><div class="th-day">SUN</div></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody class="tbody-view tbody-view-month" id="monthView">
                    <b-tr v-for='(week, index) in monthEvents' :key=index>
                      <b-td id="monthCells" v-for='(day, index) in week' :key=index align-v="top !important">
                        {{ day.dateNumber }}
                        <div class="month-event" v-for='(event, index) in day.events' :key=index v-html='event'></div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="monthModal" :aria-hidden=true :hide-footer=true :hide-header=true :static=true>
    </b-modal>
    <b-modal id="weekModal" :aria-hidden=true :hide-footer=true :hide-header=true :static=true>
      <b-row id="bannerContainer">
        <b-col id="categoryBanner">
          {{ selectedWeekModal.category.category }}
        </b-col>
      </b-row>
      <b-row id="optionsContainer">
        <b-col id="eventOptions">
          <b-button @click="deleteEvent(selectedWeekModal.id)" id="deleteEventBtn" class="eventEdit">
            <img src="@/assets/trash_empty.svg" alt="" class="deleteEvent" />
          </b-button>
        </b-col>
      </b-row>
      <b-row id="eventInformation" class="customerTitle">{{ selectedWeekModal.customer }}</b-row>
      <b-row id="eventInformation" class="eventDate"></b-row>
      <b-row id="eventInformation" class="eventTime"></b-row>
      <b-row id="eventInformation" class="eventNotesTitle">Notes:</b-row>
      <b-row id="eventInformation" class="eventNotes">{{ selectedWeekModal.notes }}</b-row>
      <b-row id="eventInformation" class="assignedStaffTitle">Assigned Staff:</b-row>
      <b-row id="eventInformation" class="assignedStaff" v-for="staff in selectedWeekModal.staff" v-bind:key="staff">{{ staff }}</b-row>
    </b-modal>
  </div>
</template>

<script>
import vue from 'vue'
import axios from 'axios'
import chevronBigDown from '../assets/chevron_big_down.svg'
import 'vue-select/dist/vue-select.css'
import debounce from 'lodash/debounce'
const dayNamesShort = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const url = 'http://localhost:8080/'
// Change to Month Number
const dayofWeek = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT'
}
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const monthNumbers = [
  '01-',
  '02-',
  '03-',
  '04-',
  '05-',
  '06-',
  '07-',
  '08-',
  '09-',
  '10-',
  '11-',
  '12-'
]
const monthNametoNumber = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
}
// ('#myModal').on('shown.bs.modal', () => {
//   var startClock = document.getElementById('input-start-time')
//   startClock.remove('svg')
//   alert('bye')
// })

var categoryCheckbox = vue.directive('CategoryCheckbox', {
  inserted: function (el, binding) {
    binding.value.map((item, key) => {
      var label = el.getElementsByTagName('label')[key]
      label.classList.add(item.color + '-checkbox')
    })
  }
})

function getMonthYear (monday, sunday) {
  var firstMonth = monthNames[monday.getMonth()]
  var firstYear = monday.getFullYear()
  var lastMonth = monthNames[sunday.getMonth()]
  var lastYear = sunday.getFullYear()
  var selectedMonthYear = ''
  if (firstMonth === lastMonth) {
    selectedMonthYear = firstMonth + ' ' + firstYear
  } else if (firstYear === lastYear) {
    selectedMonthYear = firstMonth + '-' + lastMonth + ' ' + firstYear
  } else {
    selectedMonthYear =
      firstMonth + ' ' + firstYear + '-' + lastMonth + ' ' + lastYear
  }
  return selectedMonthYear
}

function dailyEventLoad (instance) {
  var monthEventList = instance.monthEventList
  var currentYear = document.getElementsByClassName('b-calendar-grid-caption text-center font-weight-bold')[0].innerText.slice(-4)
  var currentMonth = monthNametoNumber[document.getElementsByClassName('b-calendar-grid-caption text-center font-weight-bold')[0].innerText.slice(0, -5)]
  for (let event = 0; event < monthEventList.length; event++) {
    var selectedDate = document.getElementsByClassName('th-date')[0].innerText
    var eventStartTimeStr = monthEventList[event].startTime.slice(0, 2)
    var eventStartTimeInt = parseInt(eventStartTimeStr)
    var eventEndTimeStr = monthEventList[event].endTime.slice(0, 2)
    var eventEndTimeInt = parseInt(eventEndTimeStr)
    var startMinute = parseInt(monthEventList[event].startTime.slice(3, 5)) / 60
    var endMinute = parseInt(monthEventList[event].endTime.slice(3, 5)) / 60
    var minuteDifference = startMinute - endMinute
    if (minuteDifference < 0) {
      minuteDifference = minuteDifference * -1
    }
    var eventLength = eventEndTimeInt - eventStartTimeInt + minuteDifference
    // Condition to check if the date is correct
    if (selectedDate === monthEventList[event].date.slice(8)) {
      var timeRow = document.getElementsByClassName('timeRow')
      for (let x = 0; x < timeRow.length; x++) {
        // Cell that contains the time.
        var timeCell = timeRow[x]
        // Changing the string for easier comparison.
        var timeStr = timeCell.innerText.slice(0, -3)
        // Condition to check AM or PM
        var timeInt
        if (timeCell.innerText.slice(-2) === 'AM') {
          // Changing to integer here
          timeInt = parseInt(timeStr)
        } else if (timeCell.innerText.slice(-2) === 'PM' && timeStr !== '12') {
          // Changing to integer here
          timeInt = parseInt(timeStr) + 12
        } else if (timeCell.innerText === '12 PM') {
          timeInt = parseInt(timeStr)
        } else if (timeCell.innerText === '12 AM') {
          timeInt = parseInt(timeStr)
        }
        // Variable to check the year on the calendar.
        var eventYear = monthEventList[event].date.slice(0, 4)
        // Variable to check the month on the calendar.
        var eventMonth = monthEventList[event].date.slice(5, -3)
        // Condition to check the time, month and year
        if (eventStartTimeInt === timeInt && eventYear === currentYear && currentMonth === eventMonth) {
          var eventCell = document.getElementsByClassName('dailyEventCell')[x]
          // Condition to check if another event already exists in here.
          if (eventCell.childElementCount === 0) {
            // Every elements necessary for weekly event load.
            var overlay = document.createElement('div')
            overlay.classList.add('dailyEventWidget')
            var card = document.createElement('v-card')
            card.classList.add(monthEventList[event].category.color + 'EventCard')
            var cardTitle = document.createElement('v-card-title')
            var eventTitle = document.createTextNode(monthEventList[event].category.category + ':')
            var titleText = document.createElement('strong')
            titleText.appendChild(eventTitle.cloneNode(true))
            cardTitle.appendChild(titleText.cloneNode(true))
            var cardText = document.createElement('v-card-text')
            var cardStaff = document.createTextNode(monthEventList[event].customer)
            var linebreak = document.createElement('br')
            cardText.appendChild(linebreak)
            cardText.appendChild(cardStaff)
            card.appendChild(cardTitle)
            card.appendChild(cardText)
            overlay.appendChild(card)
            eventCell.appendChild(overlay)
            var newHeight = card.offsetHeight * eventLength
            card.style.height = newHeight + 'px'
            x = timeRow.length
          } else {
            overlay = eventCell.getElementsByClassName('dailyEventWidget')
            card = document.createElement('v-card')
            card.classList.add(monthEventList[event].category.color + 'EventCard')
            cardTitle = document.createElement('v-card-title')
            eventTitle = document.createTextNode(monthEventList[event].category.category + ':')
            titleText = document.createElement('strong')
            titleText.appendChild(eventTitle.cloneNode(true))
            cardTitle.appendChild(titleText.cloneNode(true))
            cardText = document.createElement('v-card-text')
            cardStaff = document.createTextNode(monthEventList[event].customer)
            linebreak = document.createElement('br')
            cardText.appendChild(linebreak)
            cardText.appendChild(cardStaff)
            card.appendChild(cardTitle)
            card.appendChild(cardText)
            overlay[0].appendChild(card)
            newHeight = card.offsetHeight * eventLength
            card.style.height = newHeight + 'px'
            x = timeRow.length
          }
        }
      }
    }
  }
}
function dayClear () {
  const dailyEvent = document.getElementsByClassName('dailyEventWidget')
  if (dailyEvent != null) {
    while (dailyEvent.length > 0) {
      dailyEvent[0].parentNode.removeChild(dailyEvent[0])
    }
  }
}

function weeklyEventLoad (instance) {
  var monthEventList = instance.monthEventList
  // Real stuff
  // Looping through all the events in a list.
  var currentYear = document.getElementsByClassName('b-calendar-grid-caption text-center font-weight-bold')[0].innerText.slice(-4)
  var currentMonth = monthNametoNumber[document.getElementsByClassName('b-calendar-grid-caption text-center font-weight-bold')[0].innerText.slice(0, -5)]
  for (let event = 0; event < monthEventList.length; event++) {
    var datesofWeek = document.getElementsByClassName('th-date')
    var eventStartTimeStr = monthEventList[event].startTime.slice(0, 2)
    var eventStartTimeInt = parseInt(eventStartTimeStr)
    var eventEndTimeStr = monthEventList[event].endTime.slice(0, 2)
    var eventEndTimeInt = parseInt(eventEndTimeStr)
    var startMinute = parseInt(monthEventList[event].startTime.slice(3, 5)) / 60
    var endMinute = parseInt(monthEventList[event].endTime.slice(3, 5)) / 60
    var minuteDifference = startMinute - endMinute
    if (minuteDifference < 0) {
      minuteDifference = minuteDifference * -1
    }
    var eventLength = eventEndTimeInt - eventStartTimeInt + minuteDifference
    // Looping through all the dates in a week.
    for (let i = 0; i < datesofWeek.length; i++) {
      // Condition to check if the date is correct
      if (datesofWeek[i].innerText === monthEventList[event].date.slice(8)) {
        var timeRow = document.getElementsByClassName('timeRow')
        // Looping through the time rows.
        for (let x = 0; x < timeRow.length; x++) {
          // Cell that contains the time.
          var timeCell = timeRow[x].cells[0]
          // Changing the string for easier comparison.
          var timeStr = timeCell.innerText.slice(0, -3)
          // Condition to check AM or PM
          var timeInt
          if (timeCell.innerText.slice(-2) === 'AM') {
            // Changing to integer here
            timeInt = parseInt(timeStr)
          } else if (timeCell.innerText.slice(-2) === 'PM' && timeStr !== '12') {
            // Changing to integer here
            timeInt = parseInt(timeStr) + 12
          } else if (timeCell.innerText === '12 PM') {
            timeInt = parseInt(timeStr)
          } else if (timeCell.innerText === '12 AM') {
            timeInt = parseInt(timeStr)
          }
          // Variable to check the year on the calendar.
          var eventYear = monthEventList[event].date.slice(0, 4)
          // Variable to check the month on the calendar.
          var eventMonth = monthEventList[event].date.slice(5, -3)
          // Condition to check the time, month and year
          if (eventStartTimeInt === timeInt && eventYear === currentYear && currentMonth === eventMonth) {
            var eventCell = timeRow[x].cells[i + 1]
            // Condition to check if another event already exists in here.
            if (eventCell.childElementCount === 0) {
              // Every elements necessary for weekly event load.
              var overlay = document.createElement('div')
              overlay.classList.add('weeklyEventWidget')
              var card = document.createElement('v-card')
              card.id = monthEventList[event].id
              card.classList.add(monthEventList[event].category.color + 'EventCard')
              var cardTitle = document.createElement('v-card-title')
              var eventTitle = document.createTextNode(monthEventList[event].category.category + ':')
              var titleText = document.createElement('strong')
              titleText.appendChild(eventTitle.cloneNode(true))
              cardTitle.appendChild(titleText.cloneNode(true))
              var cardText = document.createElement('v-card-text')
              var cardCustomer = document.createTextNode(monthEventList[event].customer)
              var linebreak = document.createElement('br')
              cardText.appendChild(linebreak)
              cardText.appendChild(cardCustomer)
              card.appendChild(cardTitle)
              card.appendChild(cardText)
              overlay.appendChild(card)
              eventCell.appendChild(overlay)
              card.addEventListener('click', function (e) {
                instance.selectedWeekModal = {}
                var monthEvents = instance.monthEventList
                var weekModal = document.getElementById('weekModal___BV_modal_body_')
                var banner = document.getElementById('categoryBanner')
                var eventDate = weekModal.getElementsByClassName('eventDate')
                var eventTime = weekModal.getElementsByClassName('eventTime')
                for (let i = 0; i < monthEvents.length; i++) {
                  if (this.id === monthEvents[i].id) {
                    instance.selectedWeekModal = monthEvents[i]
                    weekModalClear()
                    // Clears previous class
                    if (banner.classList.length > 1) {
                      banner.classList.remove(banner.classList[1])
                    }
                    banner.classList.add(monthEvents[i].category.color + 'Banner')
                    var intYear = parseInt(monthEvents[i].date.slice(0, 4))
                    var intMonth = parseInt(monthEvents[i].date.slice(5, 7)) - 1
                    var strDate = monthEvents[i].date.slice(8)
                    var intDate = parseInt(strDate)
                    var currentDate = new Date(intYear, intMonth, intDate)
                    currentDate = currentDate.toDateString()
                    var currentDateText = document.createTextNode(currentDate)
                    var startTime = convertTimeFormat(monthEvents[i].startTime)
                    var endTime = convertTimeFormat(monthEvents[i].endTime)
                    var eventTimeWindow = document.createTextNode(startTime + '-' + endTime)
                    eventDate[0].appendChild(currentDateText.cloneNode(true))
                    eventTime[0].appendChild(eventTimeWindow.cloneNode(true))
                    instance.$bvModal.show('weekModal')
                  }
                }
              }, false)
              var newHeight = card.offsetHeight * eventLength
              card.style.height = newHeight + 'px'
              i = datesofWeek.length
              x = timeRow.length
            } else {
              overlay = eventCell.getElementsByClassName('weeklyEventWidget')
              card = document.createElement('v-card')
              card.id = monthEventList[event].id
              card.classList.add(monthEventList[event].category.color + 'EventCard')
              cardTitle = document.createElement('v-card-title')
              eventTitle = document.createTextNode(monthEventList[event].category.category + ':')
              titleText = document.createElement('strong')
              titleText.appendChild(eventTitle.cloneNode(true))
              cardTitle.appendChild(titleText.cloneNode(true))
              cardText = document.createElement('v-card-text')
              cardCustomer = document.createTextNode(monthEventList[event].customer)
              linebreak = document.createElement('br')
              cardText.appendChild(linebreak)
              cardText.appendChild(cardCustomer)
              card.appendChild(cardTitle)
              card.appendChild(cardText)
              overlay[0].appendChild(card)
              // Weekly Modal Creation
              card.addEventListener('click', function (e) {
                instance.selectedWeekModal = {}
                var monthEvents = instance.monthEventList
                var weekModal = document.getElementById('weekModal___BV_modal_body_')
                var banner = document.getElementById('categoryBanner')
                var eventDate = weekModal.getElementsByClassName('eventDate')
                var eventTime = weekModal.getElementsByClassName('eventTime')
                for (let i = 0; i < monthEvents.length; i++) {
                  if (this.id === monthEvents[i].id) {
                    instance.selectedWeekModal = monthEvents[i]
                    weekModalClear()
                    // Clears previous class
                    if (banner.classList.length > 1) {
                      banner.classList.remove(banner.classList[1])
                    }
                    banner.classList.add(monthEvents[i].category.color + 'Banner')
                    var intYear = parseInt(monthEvents[i].date.slice(0, 4))
                    var intMonth = parseInt(monthEvents[i].date.slice(5, 7)) - 1
                    var strDate = monthEvents[i].date.slice(8)
                    var intDate = parseInt(strDate)
                    var currentDate = new Date(intYear, intMonth, intDate)
                    currentDate = currentDate.toDateString()
                    var currentDateText = document.createTextNode(currentDate)
                    var startTime = convertTimeFormat(monthEvents[i].startTime)
                    var endTime = convertTimeFormat(monthEvents[i].endTime)
                    var eventTimeWindow = document.createTextNode(startTime + '-' + endTime)
                    eventDate[0].appendChild(currentDateText.cloneNode(true))
                    eventTime[0].appendChild(eventTimeWindow.cloneNode(true))
                    instance.$bvModal.show('weekModal')
                  }
                }
              }, false)
              newHeight = card.offsetHeight * eventLength
              card.style.height = newHeight + 'px'
              i = datesofWeek.length
              x = timeRow.length
            }
          }
        }
      }
    }
  }
}

function weekModalClear () {
  var eventDate = document.getElementsByClassName('eventDate')
  var eventTime = document.getElementsByClassName('eventTime')
  if (eventDate[0] != null) {
    eventDate[0].innerText = ''
  }
  if (eventTime[0] != null) {
    eventTime[0].innerText = ''
  }
}

function handleMultipleDayEvents () {
  var dailyEventCell = document.getElementsByClassName('dailyEventWidget')
  for (let i = 0; i < dailyEventCell.length; i++) {
    var eventCards = dailyEventCell[i]
    var eventCardoffset = eventCards.offsetWidth / eventCards.childElementCount
    for (let event = 0; event < eventCards.childElementCount; event++) {
      var desiredOffSet = eventCardoffset * event
      eventCards.childNodes[event].style.left = desiredOffSet + 'px'
    }
  }
}

function handleMultipleEvents () {
  var datesofWeek = document.getElementsByClassName('th-date')
  // Looping through all the dates in a week.
  for (let i = 0; i < datesofWeek.length; i++) {
    var timeRow = document.getElementsByClassName('timeRow')
    // Looping through the time rows.
    for (let x = 0; x < timeRow.length; x++) {
      // Looping through the cell in each time row
      for (let z = 1; z < timeRow[x].childElementCount; z++) {
        var eventCell = timeRow[x].cells[z]
        if (eventCell.childElementCount === 1) {
          var eventCards = eventCell.getElementsByClassName('weeklyEventWidget')[0]
          var eventCardoffset = eventCards.offsetWidth / eventCards.childElementCount
          for (let event = 0; event < eventCards.childElementCount; event++) {
            var desiredOffSet = eventCardoffset * event
            eventCards.childNodes[event].style.left = desiredOffSet + 'px'
          }
        }
      }
    }
  }
}

function weekClear () {
  const weeklyEvent = document.getElementsByClassName('weeklyEventWidget')
  if (weeklyEvent != null) {
    while (weeklyEvent.length > 0) {
      weeklyEvent[0].parentNode.removeChild(weeklyEvent[0])
    }
  }
}

function monthClear () {
  const monthlyEvent = document.getElementsByClassName('monthlyEventWidget')
  const monthlyMarker = document.getElementsByClassName('marker')
  const monthlyMore = document.getElementsByClassName('moreEvent')
  const lineBreak = document.getElementsByClassName('monthlyLineBreak')
  const currMonth = document.getElementsByClassName('currMonth')
  if (monthlyEvent != null) {
    while (monthlyEvent.length > 0) {
      monthlyEvent[0].parentNode.removeChild(monthlyEvent[0])
    }
  }
  if (monthlyMarker != null) {
    while (monthlyMarker.length > 0) {
      monthlyMarker[0].parentNode.removeChild(monthlyMarker[0])
    }
  }
  if (monthlyMore != null) {
    while (monthlyMore.length > 0) {
      monthlyMore[0].parentNode.removeChild(monthlyMore[0])
    }
  }
  if (currMonth != null) {
    while (currMonth.length > 0) {
      currMonth[0].classList.remove('currMonth')
    }
  }
  while (lineBreak.length > 0) {
    lineBreak[0].parentNode.removeChild(lineBreak[0])
  }
}
function monthModalClear () {
  const monthlyEvent = document.getElementsByClassName('monthlyModalWidget')
  const monthlyModalWrapper = document.getElementsByClassName('monthlyModalWrapper')
  const lineBreak = document.getElementsByClassName('modalLineBreak')
  if (monthlyEvent != null) {
    while (monthlyEvent.length > 0) {
      monthlyEvent[0].parentNode.removeChild(monthlyEvent[0])
    }
  }
  if (monthlyModalWrapper != null) {
    while (monthlyModalWrapper.length > 0) {
      monthlyModalWrapper[0].parentNode.removeChild(monthlyModalWrapper[0])
    }
  }
  while (lineBreak.length > 0) {
    lineBreak[0].parentNode.removeChild(lineBreak[0])
  }
}

function convertTimeFormat (time) {
  var startTimeStr = time.slice(0, 2)
  var startTimeInt = parseInt(startTimeStr)
  if (startTimeInt >= 0 && startTimeInt < 12) {
    startTimeStr = startTimeInt + time.slice(2, 5) + 'am'
  } else if (startTimeInt === 12) {
    startTimeStr = startTimeInt + time.slice(2, 5) + 'pm'
  } else {
    startTimeInt = startTimeInt - 12
    startTimeStr = startTimeInt + time.slice(2, 5) + 'pm'
  }
  return startTimeStr
}

function monthEventLoad (instance) {
  var monthEvents = instance.monthEventList.sort(function (a, b) {
    return a.value - b.value
  })
  var currentYear = document.getElementsByClassName('b-calendar-grid-caption text-center font-weight-bold')[0].innerText.slice(-4)
  var calendar = document.getElementById('monthView')
  if (calendar != null) {
    for (let z = 0; z < monthEvents.length; z++) {
      var eventDate = monthEvents[z].date.slice(5)
      var eventYear = monthEvents[z].date.slice(0, 4)
      for (let i = 0; i < calendar.childElementCount; i++) {
        var calendarRow = calendar.children[i]
        for (let x = 0; x < calendarRow.childElementCount; x++) {
          var datedCell = calendarRow.children[x]
          var date = calendarRow.children[x].innerText.slice(0, 5)
          if (date === eventDate && eventYear === currentYear && datedCell.childElementCount < 9) {
            // Linebreak
            var lineBreak = document.createElement('br')
            lineBreak.classList.add('monthlyLineBreak')
            // lineBreak = linebreak.classList.add('linebreak')
            // Creation of span tag for the category classification.
            var icon = document.createElement('span')
            // Creation of class name to identify which color to set.
            var className = monthEvents[z].category.color + 'Dot'
            icon.classList.add(className)
            icon.classList.add('marker')
            // Creates the html element
            var event = document.createElement('a')
            event.classList.add('monthlyEventWidget')
            // Creation of the staff in charge.
            var eventStaff = document.createTextNode(monthEvents[z].staff)
            event.appendChild(eventStaff.cloneNode(true))
            datedCell.appendChild(lineBreak.cloneNode(true))
            datedCell.appendChild(icon.cloneNode(true))
            datedCell.appendChild(event.cloneNode(true))
            i = calendar.childElementCount
            x = calendarRow.childElementCount
          } else if (date === eventDate && eventYear === currentYear && datedCell.childElementCount === 9) {
            var moreEvent = document.createElement('a')
            moreEvent.id = 'moreEvent'
            moreEvent.classList.add(monthEvents[z].date)
            moreEvent.classList.add('moreEvent')
            var moreMessage = document.createTextNode('...')
            var copiedMoreEvent = moreEvent.cloneNode(true)
            copiedMoreEvent.addEventListener('click', function (e) {
              var monthEvents = instance.monthEventList
              // Finds the modal ID for monthly view.
              var monthModal = document.getElementById('monthModal___BV_modal_body_')
              var clearDay = document.getElementsByClassName('dayofWeek')
              var clearDate = document.getElementsByClassName('eventDateHeader')
              while (clearDay.length > 0 && clearDate.length > 0) {
                clearDay[0].parentNode.removeChild(clearDay[0])
                clearDate[0].parentNode.removeChild(clearDate[0])
              }
              monthModalClear()
              // this.classList shows the date of the cell in question
              var intYear = parseInt(this.classList[0].slice(0, 4))
              var intMonth = parseInt(this.classList[0].slice(5, 7)) - 1
              var strDate = this.classList[0].slice(8)
              var intDate = parseInt(strDate)
              var currentDate = new Date(intYear, intMonth, intDate)
              var weekDay = dayofWeek[currentDate.getDay()]
              // Creation of the element to populate the day of the week string.
              var weekDayHeader = document.createElement('h3')
              weekDayHeader.classList.add('dayofWeek')
              // Creating the text of the day of the week.
              var monthDay = document.createTextNode(weekDay)
              // Creation of the element to populate the date.
              var dateHeader = document.createElement('h2')
              dateHeader.classList.add('eventDateHeader')
              var dateDay = document.createTextNode(strDate)
              weekDayHeader.appendChild(monthDay.cloneNode(true))
              dateHeader.appendChild(dateDay.cloneNode(true))
              monthModal.appendChild(weekDayHeader.cloneNode(true))
              monthModal.appendChild(dateHeader.cloneNode(true))
              for (let index = 0; index < instance.monthEventList.length; index++) {
                if (monthEvents[index].date === this.classList[0]) {
                  // Linebreak
                  var lineBreak = document.createElement('br')
                  lineBreak.classList.add('modalLineBreak')
                  // lineBreak = linebreak.classList.add('linebreak')
                  // Creation of span tag for the category classification.
                  var icon = document.createElement('span')
                  // Creation of class name to identify which color to set.
                  var className = monthEvents[index].category.color + 'Dot'
                  icon.classList.add(className)
                  icon.classList.add('modalMarker')
                  // Creates the html element for non bold
                  var event = document.createElement('a')
                  var eventWrapper = event.cloneNode(true)
                  eventWrapper.classList.add('monthlyModalWrapper')
                  event.classList.add('monthlyModalWidget')
                  var eventTime = event.cloneNode(true)
                  eventTime.classList.add('monthlyModalTime')
                  var eventCategory = event.cloneNode(true)
                  eventCategory.classList.add('monthlyModalCategory')
                  var eventStaff = event.cloneNode(true)
                  eventStaff.classList.add('monthlyModalStaff')
                  var eventTimeText = document.createTextNode(convertTimeFormat(monthEvents[index].startTime) + ' ')
                  var eventCategoryText = document.createTextNode(monthEvents[index].category.category + ' ')
                  var eventCustomerText = document.createTextNode('- ' + monthEvents[index].customer)
                  // Creation of the staff in charge.
                  eventTime.appendChild(eventTimeText)
                  eventCategory.appendChild(eventCategoryText)
                  eventStaff.appendChild(eventCustomerText)
                  eventWrapper.appendChild(icon.cloneNode(true))
                  eventWrapper.appendChild(eventTime.cloneNode(true))
                  eventWrapper.appendChild(eventCategory.cloneNode(true))
                  eventWrapper.appendChild(eventStaff.cloneNode(true))
                  monthModal.appendChild(lineBreak.cloneNode(true))
                  monthModal.appendChild(eventWrapper.cloneNode(true))
                }
              }
              instance.$bvModal.show('monthModal')
            }, false)
            copiedMoreEvent.appendChild(moreMessage.cloneNode(true))
            datedCell.appendChild(lineBreak.cloneNode(true))
            datedCell.appendChild(copiedMoreEvent)
          }
        }
      }
    }
  }
}
function currMonth () {
  var currentMonth = monthNametoNumber[document.getElementsByClassName('b-calendar-grid-caption text-center font-weight-bold')[0].innerText.slice(0, -5)]
  var calendar = document.getElementById('monthView')
  if (calendar != null) {
    for (let i = 0; i < calendar.childElementCount; i++) {
      var calendarRow = calendar.children[i]
      for (let x = 0; x < calendarRow.childElementCount; x++) {
        var datedCell = calendarRow.children[x]
        var month = calendarRow.children[x].innerText.slice(0, 2)
        if (currentMonth === month) {
          datedCell.classList.add('currMonth')
        }
      }
    }
  }
}
// Helper function for onContextMonth
function getMonday (d) {
  d = new Date(d)
  var day = d.getDay()
  var diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  return new Date(d.setDate(diff))
}

export default {
  data () {
    // const month = new Date().getMonth()
    // const year = new Date().getFullYear()
    return {
      // Test for calendar event.
      chevronBigDown,
      calendarHideHeader: true,
      context: null,
      weekViewDay: [],
      isSelectedDay: [],
      dayView: { day: '', date: '', full: '' },
      dayEvents: [],
      selectedMonthYear: '',
      viewSelected: 'week',
      viewOptions: [
        { value: 'day', text: 'Day' },
        { value: 'week', text: 'Week' },
        { value: 'month', text: 'Month' }
      ],
      // End of new variables.
      calendarValue: '',
      day: [],
      selectedCat: [],
      weekEvents: [],
      newEventForm: {},
      newEventStaffOptions: [],
      categoryOptions: [],
      newCategory: { name: '', description: '', color: '' },
      el: '#categoryOptions',
      defaultCategory: {
        item: 'general',
        category: 'General',
        color: 'white'
      },
      options: [
        {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        {
          item: 'appointments',
          category: 'Appointments',
          color: 'orange'
        },
        {
          item: 'internal',
          category: 'Internal',
          color: 'lavender'
        },
        {
          item: 'deliveries',
          category: 'Deliveries',
          color: 'aqua'
        },
        {
          item: 'general',
          category: 'General',
          color: 'white'
        }
      ],
      assignStaff: ['Aftab Khan', 'Nicole Cotes'],
      staffSelected: [],
      allSelected: false,
      indeterminate: false,
      staffOptions: [
        { value: 'all', text: 'All staff' },
        { value: 'a', text: 'a' },
        { value: 'b', text: 'b' },
        { value: 'c', text: 'c' }
      ],
      // Data goes here.
      monthEvents: [],
      selectedWeekModal: {
        id: 'placeholder',
        category: {
          item: 'placeholder',
          category: 'placeholder',
          color: 'placeholder'
        },
        date: 'placeholder',
        customer: 'placeholder',
        staff: 'placeholder',
        color: 'placeholder',
        startTime: 'placeholder',
        endTime: 'placeholder',
        value: 11
      },
      monthEventList: [{
        id: '1111',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-19',
        customer: 'Alan McMillan',
        staff: ['Aftab Khan'],
        color: 'yellow',
        startTime: '11:00:00',
        endTime: '12:00:00',
        value: 11
      },
      {
        id: '1112',
        category: {
          item: 'appointment',
          category: 'Appointment',
          color: 'orange'
        },
        date: '2021-08-16',
        customer: 'Alan',
        staff: ['Nicole Cotes'],
        color: 'orange',
        startTime: '08:00:00',
        endTime: '12:00:00',
        value: 8
      },
      {
        id: '1113',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-17',
        customer: 'Alan',
        staff: ['Peter Sierra'],
        startTime: '09:00:00',
        endTime: '12:00:00',
        value: 9
      },
      {
        id: '1114',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-18',
        customer: 'Alan',
        staff: ['Ravi Ragbeer'],
        color: 'aqua',
        startTime: '12:00:00',
        endTime: '13:00:00',
        value: 12
      },
      {
        id: '1115',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-12',
        customer: 'Alan',
        staff: ['Ravi Ragbeer'],
        color: 'aqua',
        startTime: '09:00:00',
        endTime: '10:00:00',
        value: 9
      },
      {
        id: '1116',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-20',
        customer: 'Alan',
        staff: ['Nicole Cotes'],
        color: 'orange',
        startTime: '12:00:00',
        endTime: '15:00:00',
        value: 12
      },
      {
        id: '1117',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-20',
        customer: 'Alan',
        staff: ['Peter Sierra'],
        color: 'purple',
        startTime: '09:00:00',
        endTime: '10:00:00',
        value: 9
      },
      {
        id: '1118',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-20',
        customer: 'Alan',
        staff: ['Ravi Ragbeer'],
        color: 'aqua',
        startTime: '09:00:00',
        endTime: '10:00:00',
        value: 9
      },
      {
        id: '1119',
        category: {
          item: 'installation',
          category: 'Installation',
          color: 'yellow'
        },
        date: '2021-08-20',
        customer: 'Alan',
        staff: ['Ravi Ragbeer'],
        color: 'yellow',
        startTime: '10:00:00',
        endTime: '12:00:00',
        value: 10
      }
      ],
      events: {
        mon: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        tue: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        wed: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        thu: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        fri: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        sat: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        sun: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        }
      }
    }
  },
  methods: {
    // Jun Start
    createNewEvent (newEvent) {
      newEvent.id = '2222'
      newEvent.value = parseInt(newEvent.startTime.slice(0, 2))
      this.monthEventList.push(newEvent)
      if (this.viewSelected === 'week') {
        weekClear()
        weeklyEventLoad(this)
        handleMultipleEvents()
      } else if (this.viewSelected === 'day') {
        dayClear()
        dailyEventLoad(this)
        handleMultipleDayEvents()
      } else {
        currMonth()
        monthEventLoad(this)
      }
      // debugger
      this.$bvModal.hide('create-event')
    },
    toggleAll (checked) {
      this.staffSelected = checked ? this.assignStaff.slice() : []
    },
    deleteEvent: function (id) {
      for (let i = 0; i < this.monthEventList.length; i++) {
        if (id === this.monthEventList[i].id) {
          this.monthEventList.splice(i, 1)
        }
      }
      weekClear()
      weeklyEventLoad(this)
      handleMultipleEvents()
      this.$bvModal.hide('weekModal')
      console.log(this.monthEventList)
    },
    createNewCategory () {
      this.options.push({ item: 'test', name: 'test', color: 'black' })
    },
    deleteClocks () {
      var clock = document.getElementsByClassName('bi-clock b-icon bi')
      clock[0].parentNode.removeChild(clock[0])
      clock[0].parentNode.removeChild(clock[0])
    },
    initWeekEventsArray () {
      this.weekEvents = []
      var i
      for (i = 8; i <= 20; i++) {
        var row = {}
        row.hour = i
        if (i <= 12) {
          row.header = i.toString() + '  AM'
        } else {
          row.header = (i - 12).toString() + '  PM'
        }
        for (const day of dayNamesShort) {
          row[day] = { html: '', staff: 0 }
        }
        this.weekEvents.push(row)
      }
    },
    initDayEventsArray () {
      this.dayEvents = []
      var i
      for (i = 8; i <= 20; i++) {
        var row = {}
        row.hour = i
        if (i < 12) {
          row.header = i.toString() + '  AM'
        } else if (i === 12) {
          row.header = i.toString() + '  PM'
        } else {
          row.header = (i - 12).toString() + '  PM'
        }
        this.dayEvents.push(row)
      }
    },
    initMonthEventsArray () {
      this.monthEvents = []
      for (var week = 0; week < 5; week++) {
        var curWeek = []
        for (var day = 0; day < 7; day++) {
          var curDay = {}
          curDay.date = week * 7 + day + 1
          curWeek.push(curDay)
        }
        this.monthEvents.push(curWeek)
      }
    },
    getStaff () {
      axios.get(url + 'staff')
        .then(res => {
          var data
          for (data of res.data) {
            var staff = { value: data.staff_id, text: data.staff_first_name + ' ' + data.staff_last_name }
            this.staffOptions.push(staff)
            this.newEventStaffOptions.push(staff)
          }
        })
        .catch(e => {})
    },
    getCategories () {
      this.categoryOptions = []
      this.CategoriesSelected = []
      axios.get(url + 'categories')
        .then(res => {
          var data
          for (data of res.data) {
            var category = { value: data.category_id, text: data.category_name, color: data.color_code }
            this.categoryOptions.push(category)
            this.CategoriesSelected.push(data.category_id)
          }
        })
        .catch(e => {})
    },
    // Jun End
    clearEvents () {
      this.events = {
        mon: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        tue: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        wed: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        thu: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        fri: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        sat: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        },
        sun: {
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
          13: [],
          14: [],
          15: [],
          16: [],
          17: [],
          18: [],
          19: [],
          20: []
        }
      }
    },
    showEvents (date) {
      // this.clearEvents()
      var dayLookUp = {
        1: 'mon',
        2: 'tue',
        3: 'wed',
        4: 'thu',
        5: 'fri',
        6: 'sat',
        7: 'sun'
      }
      var url = 'http://localhost:8080/events/' + date + '/'
      console.log(url)
      axios
        .get(url)
        .then(res => {
          var i
          for (i in res.data) {
            var dict = {}
            dict.day = new Date(res.data[i].date).getDay()
            dict.startTime = new Date(res.data[i].start_time).getHours()
            dict.notes = res.data[i].notes
            this.events[dayLookUp[dict.day]][dict.startTime].push(dict.notes)
          }
        })
        .catch(e => {})
    },
    setToday () {
      const now = new Date()
      this.calendarValue = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      )
    },
    // Next functions here.
    nextDay () {
      var tomorrow = new Date(this.dayView.full)
      tomorrow.setDate(tomorrow.getDate() + 1)
      this.calendarValue = tomorrow
    },
    nextMonth () {
      var activeYMD = this.calendarValue.split('-')
      var selectedMonth = new Date(activeYMD[0], activeYMD[1], 1)
      this.calendarValue = selectedMonth
    },
    nextWeek () {
      var lastMonday = this.day[7]
      var monday = new Date(lastMonday)
      monday.setDate(lastMonday.getDate() + 7)

      // set selected day
      var dayIndex = this.isSelectedDay.findIndex(e => e === true)
      var selectedDay = new Date(monday)
      selectedDay.setDate(monday.getDate() + dayIndex)
      this.calendarValue = selectedDay
    },
    lastDay () {
      var yesterday = new Date(this.dayView.full)
      yesterday.setDate(yesterday.getDate() - 1)
      this.calendarValue = yesterday
    },
    lastWeek () {
      var lastMonday = this.day[7]
      var monday = new Date(lastMonday)
      monday.setDate(lastMonday.getDate() - 7)

      // set selected day
      var dayIndex = this.isSelectedDay.findIndex(e => e === true)
      var selectedDay = new Date(monday)
      selectedDay.setDate(monday.getDate() + dayIndex)
      this.calendarValue = selectedDay
    },
    lastMonth () {
      var activeYMD = this.calendarValue.split('-')
      var selectedMonth = new Date(activeYMD[0], activeYMD[1] - 2, 1)
      this.calendarValue = selectedMonth
    },
    next () {
      if (this.viewSelected === 'week') {
        this.nextWeek()
      } else if (this.viewSelected === 'day') {
        this.nextDay()
      } else {
        this.nextMonth()
      }
    },
    last () {
      if (this.viewSelected === 'week') {
        this.lastWeek()
      } else if (this.viewSelected === 'day') {
        this.lastDay()
      } else {
        this.lastMonth()
      }
    },
    onSelectView (v) {
      this.onContext(this.context)
    },
    onContext (ctx) {
      function getMonday (d) {
        d = new Date(d)
        var day = d.getDay()
        var diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
        return new Date(d.setDate(diff))
      }

      var selectedYMD = ctx.selectedYMD.split('-')
      var selectedDate = new Date(
        selectedYMD[0],
        selectedYMD[1] - 1,
        selectedYMD[2]
      )
      var monday = getMonday(selectedDate)
      var tuesday = new Date(monday)
      tuesday.setDate(monday.getDate() + 1)
      var wednesday = new Date(monday)
      wednesday.setDate(monday.getDate() + 2)
      var thursday = new Date(monday)
      thursday.setDate(monday.getDate() + 3)
      var friday = new Date(monday)
      friday.setDate(monday.getDate() + 4)
      var saturday = new Date(monday)
      saturday.setDate(monday.getDate() + 5)
      var sunday = new Date(monday)
      sunday.setDate(monday.getDate() + 6)

      this.day = [
        monday.getDate(),
        tuesday.getDate(),
        wednesday.getDate(),
        thursday.getDate(),
        friday.getDate(),
        saturday.getDate(),
        sunday.getDate(),
        monday
      ]

      // get events for the week
      var dates = [
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
      ]
      var d
      for (d in dates) {
        this.showEvents(dates[d].toISOString().slice(0, 10))
      }

      // set selected day
      var i
      for (i = 0; i < this.day.length; i++) {
        if (this.day[i] === selectedDate.getDate()) {
          this.isSelectedDay[i] = true
          dayClear()
        } else {
          this.isSelectedDay[i] = false
        }
      }

      // set selected month / year
      this.selectedMonthYear = getMonthYear(monday, sunday)

      this.context = ctx
      if (this.viewSelected === 'week') {
        this.onContextWeek(ctx)
      } else if (this.viewSelected === 'day') {
        this.onContextDay(ctx)
      } else {
        this.onContextMonth(ctx)
      }
      this.firstRun = true
      this.weeklyFirstRun = true
    },
    onContextDay (ctx) {
      var activeYMD = ctx.activeYMD.split('-')
      var selectedDate = new Date(activeYMD[0], activeYMD[1] - 1, activeYMD[2])
      this.dayView.day = dayNamesShort[(selectedDate.getDay() === 0 ? 7 : selectedDate.getDay()) - 1].toUpperCase()
      this.dayView.date = selectedDate.getDate()
      this.dayView.full = selectedDate
      this.showEvents(selectedDate.toISOString().slice(0, 10))
      // set selected month / year
      this.selectedMonthYear = getMonthYear(selectedDate, selectedDate)
      this.context = ctx
    },
    // New code
    setEventsMonth (date, week, day) {
      axios.get(url + 'events/' + date + '/')
        .then(res => {
          var events = []
          for (var data of res.data) {
            var eventHtml = '<div class="month-circle" style="background-color:' + data.color_code + '"></div>'
            eventHtml = eventHtml + '<div class="month-text">' + data.notes + '</div><br>'
            events.push(eventHtml)
          }
          vue.set(this.monthEvents[week][day], 'events', events)
        })
        .catch(e => {})
    },
    onContextMonth (ctx) {
      var activeYMD = ctx.activeYMD.split('-')
      var firstDayOfMonth = new Date(activeYMD[0], activeYMD[1] - 1, 1)
      var curDay = getMonday(firstDayOfMonth)
      for (var week = 0; week < 5; week++) {
        for (var day = 0; day < 7; day++) {
          var curDayCopy = new Date(curDay)
          curDay.setDate(curDay.getDate() + 1)
          // indicate new month if first day of month
          var dateNumber
          // if (curDayCopy.getDate() === 1) {
          //   dateNumber = monthNumbers[curDayCopy.getMonth()] + ' 1'
          // } else {
          //   dateNumber = curDayCopy.getDate()
          // }
          var dateDay = curDayCopy.getDate()
          var dateDayString = dateDay.toString()
          dateNumber = monthNumbers[curDayCopy.getMonth()] + curDayCopy.getDate()
          if (dateDayString.length === 1) {
            dateNumber = monthNumbers[curDayCopy.getMonth()] + ' 0' + curDayCopy.getDate()
          } else {
            dateNumber = monthNumbers[curDayCopy.getMonth()] + curDayCopy.getDate()
          }
          vue.set(this.monthEvents[week][day], 'date', curDayCopy)
          vue.set(this.monthEvents[week][day], 'dateNumber', dateNumber)
          // set events
          this.setEventsMonth(curDayCopy.toISOString().slice(0, 10), week, day)
        }
      }
    }
  // Closing curly for methods
  },
  watch: {
    staffSelected (newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.assignStaff.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  // Setting up monthly calendar view.
  directives: {
    categoryCheckbox
  },
  mounted () {
    // Calls for the creation of day view calendar with one line below.
    this.getStaff()
    this.getCategories()
    this.setToday()
    this.initWeekEventsArray()
    this.initDayEventsArray()
    this.initMonthEventsArray()
    const now = new Date()
    this.calendarValue = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    )
    this.firstRun = true
    this.weeklyFirstRun = true
    this.dailyFirstRun = true
  },
  updated () {
    this.$nextTick(debounce(() => {
      if (this.dailyFirstRun) {
        dailyEventLoad(this)
        handleMultipleDayEvents()
        this.dailyFirstRun = false
      }
      if (this.weeklyFirstRun) {
        weekClear()
        weeklyEventLoad(this)
        handleMultipleEvents()
        this.weeklyFirstRun = false
      }
      if (this.firstRun) {
        monthClear()
        currMonth()
        monthEventLoad(this)
      }
    }, 100)) // increase to ur needs
    this.$nextTick(debounce(() => {
      if (this.firstRun) {
        monthClear()
        currMonth()
        monthEventLoad(this)
      }
    }, 100)) // increase to ur needs
    this.weeklyFirstRun = true
    this.dailyFirstRun = true
    this.firstRun = true
  }
}
</script>

<style lang="scss">
.header {
  padding: 1rem;
  border-bottom: 1px solid #cedde7;
  text-align: left;
}

.header h1 {
  float: left;
  // vertical align to middle
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header h2 {
  float: left;
  // vertical align to middle
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#setTodayBtn {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 0.5rem 1.5rem;
  background: white;
  border: 1px solid #00818f;
  box-sizing: border-box;
  border-radius: 10px;
  color: #00818f;

  // vertical align to middle
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.imgbtn {
  float: left;
  margin-left: 5px;
  vertical-align: middle;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
}

.category {
  background-color: white;
  display: block;
  width: 100%;
  color: #00818f;
  margin: 0rem 1rem;
  padding: 1rem 0rem;
}

.category:hover {
  background-color: white;
  color: #00818f;
  box-shadow: none;
  border-right: none;
  border-left: none;
}

.category:focus {
  background-color: white;
  color: #00818f;
  box-shadow: none;
  border-right: none;
  border-left: none;
}

.category h5 {
  color: #00818f;
  float: left;
  font-weight: bold;
  margin-left: 0.5rem;
}

.category img {
  float: right;
  vertical-align: middle;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
}

.category-collapse {
  border-top: 1px solid #707070;
}

.category-collapse:hover {
  border-top: 1px solid #707070;
  border-bottom: none;
}

.category-collapse:focus {
  border-top: 1px solid #707070;
  border-bottom: none;
}

.custom-control-label::after {
  border: 1px solid black;
}

.yellow-checkbox::after {
  background-color: #ecbb3d;
}

.orange-checkbox::after {
  background-color: #f98341;
}

.lavender-checkbox::after {
  background-color: #b1a8de;
}

.aqua-checkbox::after {
  background-color: #80c7d0;
}

.new-category {
  border-bottom: 1px solid #707070;
}

.new-category:hover {
  border-bottom: 1px solid #707070;
  border-top: none;
}

.new-category:focus {
  border-bottom: 1px solid #707070;
  border-top: none;
}

#collapse-1 {
  width: 100%;
  text-align: left;
  padding: 0.2rem 1rem;
}

.custom-checkbox {
  margin-bottom: 0.5rem;
  color: #707070;
}

.staff-title {
  margin: 1rem;
  font-style: normal;
  font-weight: bold;
  display: block;
}

.b-calendar {
  margin: 1rem;
}

.b-calendar-grid-caption {
  font-size: 24px;
  color: #707070;
  margin: 0.5rem 0rem;
  text-align: left !important;
}

// Weekdays
small.col.text-truncate {
  color: #c6500e;
  font-weight: bold;
}

// selected date color
.btn.border-0.rounded-circle.text-nowrap.active {
  color: black;
  background-color: #ecbb3d;
}

.b-calendar-grid {
  border: none;
}

.b-calendar-grid-weekdays {
  border: none !important;
}

footer.b-calendar-grid-help {
  display: none;
}

.th-day {
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: black;
}

.th-week-view th {
  padding: 0px;
  padding-top: 6px;
}

// Check this in regards to borders.
.tbody-week-view td {
  border-right: 1px solid #cedde7;
}

.th-date {
  font-size: 24px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  color: #000;
  text-align: center;
  background: white;
  display: inline-block;
}

.selected {
  background: #ecbb3d;
}
// Starting from here is Jun CSS:
// input-title css
#input-title {
  outline: none;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #707070;
  color: #707070;
  font-size: 24px;
}
//Changing the font-family to be Raleway for the whole app:
body {
  font-family: Raleway;
}
.createEventIcon {
  display:flex;
  height: 19px;
  width: 18px;
  left: 3px;
  top: 2px;
  border-radius: 0px;
  order: 1;
  flex-grow: 1;
  margin: 0px 4px;
}
#input-datepicker {
  border-radius: 0px;
  text-align: center;
}
#input-add-customer {
  display:inline-flex;
}
#input-group-time {
  text-align: left;
  display: inline-flex;
}
#input-group-customer {
  position: relative;
  height: 18px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  font-family: Raleway;
  letter-spacing: 0px;
  text-align: left;
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 4px;
}
.createEvent {
  order: 1;
  flex-grow: 1;
  margin: 0px 4px;
  border:none;
}
v-icon{
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 20px;
}
#input-customer-box {
  border: 1px solid #00818F;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 400px;
}
#input-group-notes {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
#input-notes {
  background: #FFFFFF;
  border: 1px solid #00818F;
  box-sizing: border-box;
  border-radius: 10px;
}
.btn-primary-green {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  background: #00818F;
  border-radius: 10px;
  margin: 0px !important;
}
#input-group-category {
  padding-left: 0px;
}
.dash {
  position: relative;
}
#checkboxes {
  display: inline-flex;
  text-align: right;
}
#big-calendar {
  margin-left: 10%;
  margin-right: -53px;
}
.form-row {
  width:100%;
  display: inline-flex;
}
// .clock-column {
//   width: 33%;
// }
.modal-title {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}
#left-calendar {
  margin-left: 2.5%;
}
#input-category-description {
  height: 128px;
  text-align: start;
  appearance: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  column-count: initial !important;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-flex;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(73, 80, 87);
  background-clip: padding-box;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  background-color: rgb(255, 255, 255);
  outline: 0px;
  resize: none;
}
#input-cateogry-description {
  color: #495057;
  background-color: #FFFFFF;
  border-color: #10e8ff;
  outline: 0;
}
#colourSelector {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.colour {
  left: 0px;
  top: 0px;
}
.colour-option {
  padding: 0;
  margin: 1px;
  flex-grow: 0;
  background: transparent;
  border: transparent;
}
.colour-selection {
  padding:0;
}
.checkbox {
  position: absolute; bottom: 0px; right: 0px;
}
.blue-round-border {
  width: 100%;
}
// To get rid of green
.rowheader {
  background-color: white !important;
}
.calendar-container {
  margin-top: 30px;
}
#create-event___BV_modal_content_ {
  width: 640px;
}
// Gets rid of the border of selecting time and date.
.timeSelector {
  border:none;
}
.view-selector {
  float: right;
  width: 16.67%;
  // vertical align to middle
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
// Setting up the daily calendar view with the css below.
.th-view th{
  padding: 6px 0px;
}
.tbody-view th {
  font-weight: bold;
  font-size: 14px;
}
.tbody-view td {
  border: 1px solid #CEDDE7;
  text-align: left;
  padding: 0px;
  height: 60px;
}
// Setting up monthly calendar view.
.month-event {
  width: 100%;
  height: 24px;
}
.tbody-view-month tr td {
  padding: 6px;
}
.tbody-view-month td {
  border: 1px solid #CEDDE7;
  text-align: left;
  vertical-align: top !important;
  height: 150px;
}
.month-circle {
  width: 10px;
  height: 10px;
  position: relative;
  bottom: 6px;
  border-radius: 50%;
  color: #000;
  display: inline-block;
}
.month-text {
  height: 100%;
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table.th.td {
  vertical-align: top !important;
  text-align: left;
  position: fixed;
}
// Removes the outer borders of the calendar
.table.b-table.table-borderless.b-table-fixed {
  border: none;
}
.th-date.selected {
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-left: 20%;
  margin-right: 10%;
}
// .header-column {
//   padding-right: 35px;
// }
// This gets the selected circle date on the big calendar to look normal.
.selected {
    width: 48px !important;
    height: 48px;
}
.b-calendar {
  width: 100%;
}
// To provide more space between the date number and the big calendar.
.th-date {
  margin-bottom: 10px;
}
// Categories button to stretch out to align to the container above.
.btn.category.category-collapse.btn-light.not-collapsed {
  margin-right: 0px;
}
// Edit categories button to stretch out to align to the container above.
.btn.category.new-category.btn-light {
  margin-right: 0px;
}
// Provides more space between two checkboxes
#private {
  text-align: left;
  padding-left: 0;
  padding-right: 20px !important;
}
#all-day {
  text-align: right;
  padding-right: 0px;
}
// Makes Today button bold.
#setTodayBtn {
  font-weight: bold;
}
// Changes the colour of the calendar viewer border to be different colour.
.view-selector.custom-select {
  border-color: #00818F;
}
.th-day {
  margin-bottom: 36px;
}
v-overlay {
  position: relative;
  display: grid;
  overflow-x: clip;
}
v-card {
  padding: 1px;
  display: inline-grid;
  margin-right: -3px;
  position: absolute;
  width: 100%;
}
v-card-title {
  margin: 3%;
  margin-right: -3px;
  font-size: 12px;
}
v-card-text {
  margin: 3%;
  margin-top: -15px;
  margin-right: -3px;
  font-size: 12px;
}
.yellowDot {
  height: 10px;
  width: 10px;
  background-color: #ecbb3d;
  border-radius: 50%;
  display: inline-block;
}
.orangeDot {
  height: 10px;
  width: 10px;
  background-color: #f98341;
  border-radius: 50%;
  display: inline-block;
}
.purpleDot {
  height: 10px;
  width: 10px;
  background-color: #b1a8de;
  border-radius: 50%;
  display: inline-block;
}
.aquaDot {
  height: 10px;
  width: 10px;
  background-color: #80c7d0;
  border-radius: 50%;
  display: inline-block;
}
.blueDot {
  height: 10px;
  width: 10px;
  background-color: #bce5fb;
  border-radius: 50%;
  display: inline-block;
}
.pinkDot {
  height: 10px;
  width: 10px;
  background-color: #e8a2c7;
  border-radius: 50%;
  display: inline-block;
}
.greenDot {
  height: 10px;
  width: 10px;
  background-color: #b9e1b2;
  border-radius: 50%;
  display: inline-block;
}
.silverDot {
  height: 10px;
  width: 10px;
  background-color: #e1e7ed;
  border-radius: 50%;
  display: inline-block;
}
.greyDot {
  height: 10px;
  width: 10px;
  background-color: #b1a8de;
  border-radius: 50%;
  display: inline-block;
}
.monthlyEventWidget {
  margin-left: 5%;
  color: black;
  font-size: 10px;
  pointer-events: none;
}
.monthlyModalWidget {
  margin-left: 5%;
  color: black;
  font-size: 12px;
  pointer-events: none;
}
#moreEvent {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 13px;
  flex: none;
  text-align: center;
  color: #00818F;
  margin-left: 40%;
}
.currMonth {
  font-weight: bold;
}
#monthModal___BV_modal_content_ {
  width: 307px;
}
.dayofWeek {
  text-align: center;
  padding-top: 5%;
  color: #626262;
  font-size: 20px;
}
.eventDateHeader {
  text-align: center;
  color: #626262;
  margin-top: 2%;
  font-size: 40px;
}
.monthlyModalTime {
  font-weight: normal;
}
.monthlyModalCategory {
  margin-left: 0%;
}
.monthlyModalStaff {
  margin-left: 0%;
  font-weight: normal;
}
.monthlyModalWrapper {
  pointer-events: none;
}
.timeColumn {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
// Styling for the weekly event cards.
.yellowEventCard {
  background-color: #ecbb3d;
  border-color:#ecbb3d;
}
.orangeEventCard {
  background-color: #f98341;
  border-color:#f98341;
}
.purpleEventCard {
  background-color: #b1a8de;
  border-color:#b1a8de;
}
.aquaEventCard {
  background-color: #80c7d0;
  border-color:#80c7d0;
}
.blueEventCard {
  background-color: #bce5fb;
  border-color:#bce5fb;
}
.pinkEventCard {
  background-color: #e8a2c7;
  border-color:#e8a2c7;
}
.greenEventCard {
  background-color: #b9e1b2;
  border-color:#b9e1b2;
}
.silverEventCard {
  background-color: #e1e7ed;
  border-color:#e1e7ed;
}
.greyEventCard {
  background-color: #b1a8de;
  border-color:#b1a8de;
}
.timeColumn {
  border-right: 1px solid #cedde7 !important;
}
.weeklyEventWidget {
  position: relative;
  display: grid;
  overflow-x: clip;
}
.dailyEventWidget {
  position: relative;
  display: grid;
  overflow-x: clip;
  height: 100%;
}
#monthCells {
  padding: 0.75rem;
}
.dailyEventCell {
  border: 0px !important;
}
.timeRow {
  padding: 0.75rem !important;
}
#bannerContainer {
  display: initial;
  width: 35%;
}
#categoryBanner {
  width: 35%;
  border-radius: 0px 10px 10px 0px;
  font-weight: bold;
}
#weekModal___BV_modal_body_ {
  padding-left: 0;
}
#optionsContainer {
  float: right
}
#deleteEventBtn {
  background-color: white !important;
  border-color: white !important;
}
.deleteEvent {
  height: 20px;
  width: 18px;
  background-color: white !important;
  border-color: white !important;
}
#eventOptions {
  text-align: right;
}
#eventInformation {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 0px;
}
.customerTitle {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #626262;
  padding-left: 12px;
}
.eventNotesTitle {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #626262;
  padding-left: 12px;
}
.assignedStaffTitle {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #626262;
  padding-left: 12px;
}
.eventDate {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #626262;
  padding-left: 12px;
}
.eventTime {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #626262;
  padding-left: 12px;
}
.eventNotes {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #626262;
  padding-left: 12px;
}
.assignedStaff {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #626262;
  padding-left: 12px;
}
.yellowBanner {
  background-color: #ecbb3d;
}
.orangeBanner {
  background-color: #f98341;
}
.purpleBanner {
  background-color: #b1a8de;
}
.aquaBanner {
  background-color: #80c7d0;
}
.blueBanner {
  background-color: #bce5fb;
}
.pinkBanner {
  background-color: #e8a2c7;
}
.greenBanner {
  background-color: #b9e1b2;
}
.silverBanner {
  background-color: #e1e7ed;
}
.greyBanner {
  background-color: #b1a8de;
}
</style>
