<template>
  <div class="reportList__content py-8">
    <div class="row px-3 align-items-center">
      <div class="col-4 pr-6">
        <h2 class="mb-2">{{ report.title }}</h2>
        <p>{{ report.description }}</p>
      </div>
      <div class="col-3">
        <div class="reportList__dateRange">
          <v-select
            v-model="dateRangeOptionSelected"
            @input="handleDateRangeChange"
            :options="dateRangeOptions"
            :key="dateRangeOptionSelected.label"
            placeholder="Select a date range"
          ></v-select>
        </div>
      </div>
      <div class="col-3 ml-auto">
        <b-button
          variant="primary"
          class="py-3 px-5 bold"
          @click="handleExport(report.type)"
        >
          Export
        </b-button>
      </div>
    </div>
    <!--Report Date Collapse -->
    <div v-if="isOpenCalendar" class="reportList__dateCollapse">
      <div class="row ml-auto">
        <div class="reportList__dateRange col-md-12">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4 pr-3">
              <h4 class="text-secondary mb-2">
                From
              </h4>
              <b-form-datepicker
                size="sm"
                v-model="customDateRange.start"
                @input="handleDateChange"
                dropright
              ></b-form-datepicker>
            </div>
            <div class="col-4 pl-3">
              <h4 class="text-secondary mb-2">
                To
              </h4>
              <b-form-datepicker
                size="sm"
                v-model="customDateRange.end"
                @input="handleDateChange"
                dropright
              ></b-form-datepicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formatter, getHeaders, formatDate } from '../../utils'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
dayjs.extend(utc)

export default {
  components: {
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      customDateRange: {
        start: dayjs().startOf('month').format(),
        end: dayjs().format()
      },
      dateRangeOptionSelected: null,
      dateRangeOptions: [
        {
          label: 'Custom',
          start: null,
          end: null
        },
        {
          label: 'Today',
          start: dayjs().startOf('day'),
          end: dayjs().endOf('day')
        },
        {
          label: 'Yesterday',
          start: dayjs().startOf('day').subtract(1, 'day'),
          end: dayjs().endOf('day').subtract(1, 'day')
        },
        {
          label: 'Last Week',
          start: dayjs().subtract(1, 'week').startOf('week'),
          end: dayjs().subtract(1, 'week').endOf('week')
        },
        {
          label: 'Last Month',
          start: dayjs().subtract(1, 'month').startOf('month'),
          end: dayjs().subtract(1, 'month').endOf('month')
        },
        {
          label: 'Last 7 Days',
          start: dayjs().subtract(7, 'day'),
          end: dayjs()
        },
        {
          label: 'Last 30 Days',
          start: dayjs().subtract(30, 'day'),
          end: dayjs()
        },
        {
          label: 'Month to Date (MTD)',
          start: dayjs().startOf('month'),
          end: dayjs().endOf('day')
        },
        {
          label: 'Year to Date (YTD)',
          start: dayjs().startOf('year'),
          end: dayjs()
        }
      ],
      isOpenCalendar: false,
      formatter
    }
  },
  filters: {
    formatDate (date) {
      return dayjs(date).format('MMMM D, YYYY')
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  created () {
    this.dateRangeOptionSelected = this.dateRangeOptions[8]
    this.handleDateRangeChange(this.dateRangeOptionSelected)
  },
  methods: {
    formatDate (date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    handleDateRangeChange (newDateRangeOption) {
      if (newDateRangeOption.start == null) {
        this.customDateRange.start = dayjs().startOf('month').format()
      } else {
        this.customDateRange.start = newDateRangeOption.start.format()
      }
      if (newDateRangeOption.end == null) {
        this.customDateRange.end = dayjs().format()
      } else {
        this.customDateRange.end = newDateRangeOption.end.format()
      }
      this.isOpenCalendar = newDateRangeOption.label === 'Custom'
    },
    handleDateChange (date) {
      this.handleDateRangeChange({
        label: 'Custom',
        start: dayjs(this.customDateRange.start),
        end: dayjs(this.customDateRange.end)
      })
    },
    generateRow (type, item) {
      if (type === 'orders') {
        return {
          PrimaryCustomer: item.PrimaryCustomer,
          SecondaryCustomer: item.SecondaryCustomer,
          Company: item.Company,
          OrderTotal: item.OrderTotal,
          AR: item.AR,
          Order: item.order,
          OrderDate: item.OrderDate,
          PO: item.PO,
          BillingAddress1: item.BillingAddress1,
          BillingAddress2: item.BillingAddress2,
          BillingCity: item.BillingCity,
          BillingState: item.BillingState,
          BillingZip: item.BillingZip,
          Mobile1: item.Mobile1,
          Email1: item.Email1,
          Mobile2: item.Mobile2,
          Email2: item.Email2,
          Partner1: item.Partner1,
          Partner2: item.Partner2,
          Partner3: item.Partner3,
          SalesPerson: item.SalesPerson,
          AccountManager: item.AccountManager,
          Brand: item.Brand,
          Lead: item.Lead,
          AmountTax: item.AmountTax,
          Tax1: item.Tax1,
          Tax2: item.Tax2,
          Total: item.Total,
          FirstPaymentType: item.FirstPaymentType,
          FirstPaymentAmount: item.FirstPaymentAmount,
          FirstPaymentDate: item.FirstPaymentDate,
          SecondPaymentType: item.SecondPaymentType,
          SecondPaymentAmount: item.SecondPaymentAmount,
          SecondPaymentDate: item.SecondPaymentDate,
          ThirdPaymentType: item.ThirdPaymentType,
          ThirdPaymentAmount: item.ThirdPaymentAmount,
          ThirdPaymentDate: item.ThirdPaymentDate,
          FourthPaymentType: item.FourthPaymentType,
          FourthPaymentAmount: item.FourthPaymentAmount,
          FourthPaymentDate: item.FourthPaymentDate,
          TotalPaid: item.TotalPaid,
          AccountsReceivable: item.AccountsReceivable
        }
      } else if (type === 'partners') {
        return {
          Partner: item.partnerCompanyName
          // Contact: item.Contact,
          // Mobile: item.Mobile,
          // DirectLine: item.DirectLine,
          // MainLine: item.MainLine,
          // Email: item.Email,
          // ReceiveNewsletter: item.ReceiveNewsletter,
          // 'How many Primary Customer': item.partner.length,
          // Title: item.Title,
          // Account: item.Account,
          // Address1: item.Address1,
          // Address2: item.Address2,
          // City: item.city,
          // State: item.State,
          // PostalCode: item.PostalCode
        }
      } else if (type === 'products') {
        return {
          PrimaryCustomer: item.PrimaryCustomer,
          SecondaryCustomer: item.SecondaryCustomer,
          Company: item.Company,
          Order: item.Order,
          OrderDate: item.OrderDate,
          SalesPerson: item.SalesPerson,
          AccountManager: item.AccountManager,
          LeadReferral: item.LeadReferral,
          Brand: item.Brand,
          Type: item.Type,
          SKU: item.SKU,
          OrderTotal: item.OrderTotal,
          Unit: item.Unit,
          SubTotal: item.SubTotal,
          Tax1: item.Tax1,
          Tax2: item.Tax2,
          Total: item.Total,
          Partner1: item.Partner1,
          Partner2: item.Partner2,
          Partner3: item.Partner3
        }
      } else if (type === 'quotes') {
        return {
          PrimaryCustomer: item.PrimaryCustomer,
          SecondaryCustomer: item.SecondaryCustomer,
          Company: item.Company,
          QuoteTotal: item.QuoteTotal,
          Quote: item.Quote,
          QuoteDate: item.QuoteDate,
          Partner1: item.Partner1,
          Partner2: item.Partner2,
          Partner3: item.Partner3,
          SalesPerson: item.SalesPerson,
          AccountManager: item.AccountManager,
          Brand: item.Brand,
          Lead: item.Lead,
          AmountTax: item.AmountTax,
          Tax1: item.Tax1,
          Tax2: item.Tax2,
          Total: item.Total
        }
      } else if (type === 'customers') {
        return {
          PrimaryCustomer: item.PrimaryCustomer,
          Mobile1: item.Mobile1,
          Email1: item.Email1,
          ReceivesNewsletter: item.ReceivesNewsletter,
          SecondaryCustomer: item.SecondaryCustomer,
          Mobile2: item.Mobile2,
          Emil2: item.Email2,
          CompanyName: item.CompanyName,
          BillingAddress1: item.BillingAddress1,
          BillingAddress2: item.BillingAddress2,
          BillingCity: item.BillingCity,
          BillingState: item.BillingState,
          BillingZip: item.BillingZip,
          AccountManager: item.AccountManager
        }
      }
    },
    handleExport (type) {
      const url = `${BACKEND_API_URL}/${type}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(res => {
          const data = res.data
          console.log('@@@@@@@@@', type, data)
          const json = []
          data.forEach(item => {
            const row = this.generateRow(type, item)
            console.log('11111111', row)
            json.push(row)
          })
          const worksheet = XLSX.utils.json_to_sheet(json)
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
          const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })
          saveAs(blob, `${type.toUpperCase()}_${formatDate(new Date(), 'YYYY-M-d', 'en')}.xlsx`)
        })
        .catch(e => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.reportList {
  &__content {
    border-bottom: 1px solid $blue;

    &:last-child {
      border-bottom: none;
    }
  }

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #707070;
  }

  &__dateToggle {
    span {
      font-size: 24px;
      cursor: pointer;

      /deep/ svg * {
        fill: $orange;
      }
    }
  }

  .v-select {
    /deep/ .vs__dropdown-toggle {
      background: none !important;
    }

    /deep/ .vs__dropdown-menu {
    }
  }

  &__dateCollapse {
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;

      /deep/ svg * {
        fill: $orange;
      }

      &.left {
        left: 10px;
      }
      &.right {
        right: 10px;
      }
    }
  }

  &__calendar {
    /deep/ .b-calendar {
      &-grid {
        background-color: $grayBg;
        border-radius: 20px;
        padding: 0 19px 29px;
      }

      &-header {
        display: none;
      }

      // &-nav {
      //   display: none !important;
      // }
    }
  }

  &__dateRange {
    position: relative;
    /deep/ .b-form-datepicker {
      height: 50px;
      border: 1px solid $green;
      border-radius: 10px;

      .b-calendar-header {
        display: none;
      }
      // button {
      //   visibility: hidden;
      //   width: 1px;
      // }

      label {
        white-space: nowrap;
        overflow: hidden;
        line-height: 42px;
      }
    }
  }

  .search-block {
    position: relative;

    @include mq {
      padding-left: 30px;
    }
    // margin-top: 36px;

    input {
      border-radius: 10px;
      padding: 13px 8px 13px 16px;
      border: 1px solid #00818f;
      height: 50px;
    }

    span {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 15px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
      /deep/ svg * {
        fill: $red;
      }
    }
  }

  h2 {
    color: $green !important;;
  }

  .bold {
    font-weight: 600;
  }
}
</style>
