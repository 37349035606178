<template class="container-fluid">
  <div class="row no-gutters">
    <div class="col-lg-12">
      <QuoteHeader :title="title"/>
    </div>
    <div class="col-lg-9">
      <slot />
    </div>
    <div class="col-lg-3 ml-auto">
      <BoxCustomerDetail />
      <BoxPartnerTag
        :id="this.$route.params.quoteId"
      />
    </div>
  </div>
</template>

<script>
import QuoteHeader from '@/components/partials/quote/QuoteHeader'
import BoxCustomerDetail from '@/components/boxes/BoxCustomerDetail'
import BoxPartnerTag from '@/components/boxes/BoxPartnerTag'

export default {
  name: 'LayoutDefaultQoute',
  components: {
    QuoteHeader,
    BoxCustomerDetail,
    BoxPartnerTag
  },
  data () {
    return {
      title: 'Build a Quote'
    }
  },
  watch: {
    $route (to, from) {
      this.setTitle(to.name)
    }
  },
  created () {
    this.setTitle(this.$router.currentRoute.name)
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    setTitle (routeName) {
      if (routeName === 'QuoteDetail') {
        this.title = 'Quote'
      } else if (routeName === 'QuoteDetailEdit') {
        this.title = 'Edit Quote'
      }
    }
  }

}
</script>

<style>

#product-table .tabulator-header .tabulator-col {
  background-color: #00818f;
  color: white;
}

#product-table .tabulator-arrow {
  border-top-color: #ECBB3D;
  border-bottom-color: #ECBB3D;
}

</style>
