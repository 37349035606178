<template>
  <tbody class="partner-table-item">
    <tr>
      <td class="partner-name">
        <router-link
          :to="{ name: 'PartnerEdit', params: { partnerId: partner.id }}"
        >
          {{ partner.partnerCompanyName }}
        </router-link>
      </td>
      <td>{{ (partner.firstName || partner.lastName) ? `${partner.firstName || ''} ${partner.lastName || ''}` : '' }}</td>
      <td>{{ partner.title || partner.type }}</td>
      <td>{{ partner.mobilePhone || partner.mainPhone }}</td>
      <td>
        <router-link
          :to="{ name: 'PartnerEdit', params: { partnerId: partner.id }}"
        >
          {{ partner.email || '' }}
        </router-link>
      </td>
      <td>
        <div class="d-flex align-items-center justify-content-between position-relative" >
          <div
            class="note-icon"
            @click="handleNoteModal"
          >
            <IconFolder />
          </div>
          <router-link
            :to="{ name: 'PartnerEdit', params: { partnerId: partner.id }}"
          >
            <IconEdit />
          </router-link>
          <div
            v-if="partner.firstName"
            @click="handleModal"
          >
            <IconTrashEmpty />
          </div>
        </div>
      </td>
    </tr>
    <b-modal
      v-model="modalShow"
      size="md"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <template #default="{ hide }">
        <div class="p-3 text-secondary">
          <div class="modal__content row mb-4">
            <div class="col-12">
              <p class="text-secondary text-center">
                Are you sure you want to delete the Contact?
              </p>
            </div>
          </div>
          <div class="modal__footer d-flex align-items-center justify-content-around">
            <ButtonPrimary @click="handleDeletePartner">
              Yes
            </ButtonPrimary>
            <ButtonWarning @click="hide()">
              Cancel
            </ButtonWarning>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="noteModalShow"
      size="sm"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <template #default="{ hide }">
        <div class="p-3 text-secondary">
          <div class="modal__header d-flex align-items-center justify-content-between">
            <h5 class="text-primary my-1 font-weight-bold">Notes</h5>
            <span class="text-primary times-icon" @click="hide()">x</span>
          </div>
          <div class="modal__content my-4 pl-2">
            <p class="text-secondary note-content" style="white-space: pre-line">
              {{partner.notes}}
            </p>
          </div>
          <div class="modal__footer pl-2 d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-primary"
              size="lg"
              @click="hide()"
            >
              Ok
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </tbody>
</template>

<script>
import IconEdit from '@/components/icons/edit/IconEdit'
import IconTrashEmpty from '@/components/icons/basic/IconTrashEmpty'
import IconFolder from '@/components/icons/file/IconFolder'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import ButtonWarning from '@/components/buttons/ButtonWarning'
import { BACKEND_API_URL } from '@/constants/default'
import axios from 'axios'
import { getHeaders } from '@/utils'

export default {
  components: {
    IconEdit,
    IconTrashEmpty,
    IconFolder,
    ButtonPrimary,
    ButtonWarning
  },
  name: 'PartnerTableRow',
  props: [
    'partner',
    'getPartners'
  ],
  data () {
    return {
      modalShow: false,
      noteModalShow: false,
      deleteContactId: null
    }
  },
  methods: {
    handleModal () {
      this.modalShow = !this.modalShow
    },

    handleNoteModal () {
      this.noteModalShow = !this.noteModalShow
    },

    handleDeletePartner () {
      const url = `${BACKEND_API_URL}/contacts/${this.partner.idConctract}`
      axios
        .delete(url, {
          headers: getHeaders()
        })
        .then(res => {
          this.handleModal()
          this.getPartners()
        })
        .catch(e => {
          this.submitStatus = 'ERROR'
        })
    }
  }
}
</script>

<style lang="scss">
.partner-table-item {
  tr td {
    font-size: 13px;
    line-height: 15px;
    padding: 0 24px !important;
    min-height: 50px !important;
    height: 50px;
  }

  .partner-name {
    color: #00818F;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    outline: none;

    path {
      fill: #C6500E;
    }
  }
}

.note-content {
  word-break: break-word;
}

.times-icon {
  font-size: 1rem !important;
}
</style>
