<template>
  <div class="card">
    <div class="d-flex justify-content-center card__logo">
      <img src="@/static/logos/logoStacked.svg" class="mr-3" alt="" />
    </div>
    <div class="card__form">
      <form @submit.prevent="handleFormRequest">
        <h3 class="w-100 text-center card__title">Sign In</h3>
        <div class="w-100 text-center error mb-2">{{ error }}</div>
        <div class="form-group">
          <InputText
            v-model="email"
            label="Email address"
            required="true"
            :status="$v.email.$error"
          />
        </div>

        <div class="form-group">
          <InputText
            v-model="password"
            label="Password"
            type="password"
            required="true"
            :status="$v.password.$error"
          />
        </div>

        <div class="text-left">
          <InputCheckbox
            label="Remember me"
            v-model="rememberMe"
          />
        </div>

        <ButtonPrimary type="submit" class="btn btn-dark btn-lg btn-block">Sign In</ButtonPrimary>

        <p>
          By clicking Sign In, you agree to our <b><a href="https://buildcrm.ca/tos/">Terms</a></b> and have read and acknowledge our <b><a href="https://buildcrm.ca/privacy-policy/">Privacy Statement</a></b>.
        </p>
      </form>

      <div class="card__footer">
        <div class="forgot-password text-left mb-1">
          <router-link to="/forgot-password">I forgot my user ID or password</router-link>
        </div>
        <div>
          New to Build CRM? <a href="https://buildcrm.ca/subscribe/">Create an account</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import InputText from '@/components/inputs/InputText'
import InputCheckbox from '@/components/inputs/InputCheckbox'
import { BACKEND_API_URL } from '../../constants/default'
import axios from 'axios'
import { email, minLength, required } from 'vuelidate/lib/validators'
import { getHeaders } from '../../utils'

export default {
  components: {
    ButtonPrimary,
    InputText,
    InputCheckbox
  },
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
      error: null
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    email: {
      email,
      required
    }
  },
  mounted () {
    window.sessionStorage.storeId = ''
  },
  methods: {
    handleFormRequest () {
      this.error = ''
      const url = `${BACKEND_API_URL}/auth/login`
      // `${BACKEND_API_URL}/auth/login`
      const payload = {
        email: this.email,
        password: this.password
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'

        axios.post(url, payload, {
          headers: getHeaders()
        })
          .then(res => {
            if (res.data && res.data.user) {
              this.$store.dispatch('setToken', res.data.token)
              this.$store.dispatch('setProfile', res.data.user).then(() => {
                const id = res.data && res.data.user && res.data.user.id

                if (id) {
                  window.location.href = '/'
                }
              })
            }
          })
          .catch(error => {
            console.log('e', error)
            if (error.response && error.response.data) {
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            }
            this.error = error.response.data.error
            this.submitStatus = 'ERROR'
          })
      }
    }
  }
}
</script>

<style lang="scss">
  .card {
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 630px;
    padding: 24px;

    &__logo {
      padding-top: 10px;
      padding-bottom: 30px;
      border-bottom: 1px solid #CEDDE7;

      img {
        max-width: 100px;
      }
    }

    &__title {
      font-size: 24px;
      color: #707070;
      margin-bottom: 24px;
    }

    &__form {
      padding: 25px 5% 25px;
    }
    &__footer {
      border-top: 1px solid #CEDDE7;
      padding: 20px 0 0;
    }
  }
  .inputText {
    position: relative;

    &__error {
      position: absolute;
      color: #c11b0f;
      font-size: 32px;
      line-height: 0.8;
    }

    &__label {
      color: $secondaryGray;
    }

    &__error + &__label {
      padding-left: 15px;
    }

    &__input {
      display: flex;
      width: 100%;
      background: $white;
      color: #a19f9f;
      border: 1px solid #00818f;
      border-radius: 10px;
      outline: none;

      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;

      padding: 13px 16px;
      margin: 8px 0px;
    }
  }
</style>
