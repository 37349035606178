import PartnerNew from '../views/partner/PartnerNew'
import PartnerList from '../views/partner/PartnerList'

export default [
  {
    path: '/new-partner',
    name: 'PartnerNew',
    component: PartnerNew
  },
  {
    path: '/partners',
    name: 'PartnerList',
    component: PartnerList
  },
  {
    path: '/partner/:partnerId/edit',
    name: 'PartnerEdit',
    component: PartnerNew
  }
]
